import React from 'react'

export default function NavInnerContainer({ children }) {
  return (
    <div className="2xl:flex 2xl:justify-center">
      <div className="lg:pl-[3.125rem] flex p-4 mobile:py-[0.8125rem] mobile:px-0 justify-between items-center mobile:flex-wrap lg:grid lg:grid-cols-[minmax(0,21.4375rem),minmax(0,95.8125rem),minmax(0,15rem)]">
        {children}
      </div>
    </div>
  )
}
