import { Button } from 'components/Button'
import { useModal } from 'features/modal/useModal'
import { NotificationType, useNotification } from 'features/notification'
import { getAuth } from 'firebase/auth'
import React from 'react'
interface Props {
  item: string
  deleteFn: () => void
  loading?: boolean
}

const Confirmation: React.FC<Props> = ({ item, deleteFn, loading }) => {
  const { dismissModal } = useModal()
  const { notify } = useNotification()

  const handleDelete = async () => {
    try {
      await deleteFn()
      item === 'Account' && !loading && getAuth().signOut()
      notify(`${item} deleted successfully!`, NotificationType.SUCCESS)
    } catch (error: any) {
      notify(error?.data?.message, NotificationType.WARNING)
    } finally {
      dismissModal()
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <h2 className="text-white font-primary text-lg font-semibold">
        Are you sure you want to delete this {item}?
      </h2>
      <div className="flex gap-5 my-3">
        <Button onClick={handleDelete}> Confirm </Button>
        <Button
          onClick={dismissModal}
          className="bg-inactive hover:bg-inactive hover:opacity-80"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default Confirmation
