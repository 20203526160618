import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import classnames from 'classnames'
import { Fragment } from 'react'

interface SelectedTypes {
  _id?: string | boolean
  name?: string
}
interface SelectTypes {
  selected?: SelectedTypes
  setSelected?: any
  options?: any[]
  label?: string
  disabled?: boolean
}

export default function Select({
  selected = {},
  setSelected,
  options = [],
  label,
  disabled = false
}: SelectTypes) {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left border border-gray-700 pt-5 rounded-xl shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <Listbox.Label className="absolute text-textInput top-1 text-xs peer-placeholder-shown:text-sm peer-focus:text-xs ">
                {label}
              </Listbox.Label>
              <span className="block truncate text-white">
                {selected?.name || ''}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-bluedarkbg shadow-disabledMirror rounded-md shadow-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((opt) => (
                  <Listbox.Option
                    key={opt._id}
                    className={({ active }) =>
                      classnames(
                        active ? 'text-white bg-blue-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={opt}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classnames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate text-white'
                          )}
                        >
                          {opt.name}
                        </span>

                        {selected ? (
                          <span
                            className={classnames(
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
