import React from 'react'

interface Props {
  text?: string
  img?: string
}

const InfoModal = ({ text, img }: Props) => {
  return (
    <div>
      {text && <p className="font-primary text-white text-lg">{text}</p>}
      {img && <img src={img} alt="one-time" />}
    </div>
  )
}

export default InfoModal
