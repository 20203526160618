import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'UserSpaces'>({
  endpoints: {
    spaceControllerFindAllForMeWhereOwnerPaginatedV2: {
      providesTags: (result, error, arg) => [{ type: 'UserSpaces' }]
    },
    spaceControllerCreate: {
      invalidatesTags: (result, error, arg) => [{ type: 'UserSpaces' }]
    },
    spaceControllerCopyFromTemplate: {
      invalidatesTags: (result, error, arg) => [{ type: 'UserSpaces' }]
    },
    spaceControllerUpdate: {
      invalidatesTags: (result, error, arg) => [{ type: 'UserSpaces' }]
    },
    spaceControllerRemove: {
      invalidatesTags: (result, error, arg) => [{ type: 'UserSpaces' }]
    }
  }
})

export const selectMySpacesList = (options: any) =>
  apiMirrorSlice.endpoints.spaceControllerFindAllForMeWhereOwnerPaginatedV2.select(
    options as any
  )
