import { Form, Formik } from 'formik'
import { FC } from 'react'
import { Button } from '../../components/Button'
import FormHeaderSection from '../../components/FormHeaderSection'
import Heading from '../../components/Heading'
import InputField from '../../components/InputField'
import Paragraph from '../../components/Paragraph'
import TextareaField from '../../components/TextareaField'
import {
  UserFeedbackItemResponse,
  UpdateUserFeedbackDto,
  useUserFeedbackControllerUpdateMutation
} from '../api/apiMirrorSlice.generated'
import { useModal } from '../modal/useModal'
import { NotificationType, useNotification } from '../notification'
import { userFeedbackItemSchema } from './userFeedbackItem.validationSchema'

interface EditUserFeedbackValues {
  name: string
  description: string
}

type Props = {
  userFeedbackItem: UserFeedbackItemResponse
}

const EditUserFeedbackForm: FC<Props> = ({ userFeedbackItem }) => {
  const [updateUserFeedbackItem, { isLoading }] =
    useUserFeedbackControllerUpdateMutation()
  const { notify } = useNotification()
  const { dismissModal } = useModal()

  const onSubmit = async (values: EditUserFeedbackValues) => {
    const updateUserFeedbackDto: UpdateUserFeedbackDto = {
      name: values.name,
      description: values.description
    }

    try {
      await updateUserFeedbackItem({
        id: userFeedbackItem._id,
        updateUserFeedbackDto
      }).unwrap()
      notify('Feature request updated!', NotificationType.SUCCESS)
      dismissModal()
    } catch {
      notify('Feature request failed to update!', NotificationType.WARNING)
    }
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Edit feature request</Heading>
        <Paragraph> Update information about your feature request.</Paragraph>
      </FormHeaderSection>

      <Formik
        validateOnBlur={false}
        enableReinitialize
        initialValues={{
          name: userFeedbackItem?.name,
          description: userFeedbackItem?.description
          // userFeedbackType: userFeedbackItem.userFeedbackType // TODO Fix
        }}
        validationSchema={userFeedbackItemSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField
              type="text"
              label="Name"
              name="name"
              disabled={isLoading}
            />

            <TextareaField
              name="description"
              label="Description"
              disabled={isLoading}
            />
            {/* 
            <div className="w-full flex flex-col gap-1">
              <label className="font-semibold text-xs text-white">
                {AssetNames[userFeedbackItem.assetType]} File
              </label>
              <Image
                url={userFeedbackItem?.thumbnail}
                imageClassName="mx-auto w-full h-48 object-cover"
                fallbackImageClassName="rounded-md max-h-48 w-auto mx-auto"
                fallbackUrl="images/placeholder_image.png"
                alt="Asset Image"
              />
            </div> */}

            <Button type="submit" isLoading={isLoading} disabled={isLoading}>
              Update
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default EditUserFeedbackForm
