import { Form, Formik } from 'formik'
import InputField from '../../components/InputField'
import { Button } from '../../components/Button'
import React from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { changeEmailValidationSchema } from './changeEmail.validationSchema'
import { useChangeEmail } from './useChangeEmail'
import FormHeaderSection from '../../components/FormHeaderSection'

const ChangeEmailForm = (formik) => {
  const { updateEmail } = useChangeEmail()

  return (
    <div className="max-w-sm">
      <FormHeaderSection>
        <Heading size="2">Change Email</Heading>
        <Paragraph>
          Please enter your new email and verify your password below.
        </Paragraph>
      </FormHeaderSection>

      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={changeEmailValidationSchema}
        validateOnBlur={false}
        onSubmit={updateEmail}
      >
        <Form>
          <div className="flex flex-col gap-3">
            <InputField
              name="email"
              type="text"
              label="New Email"
              placeholder={formik.values.email}
            />

            <InputField name="password" type="password" label="Password" />

            <Button type="submit" className="w-full">
              Update
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ChangeEmailForm
