import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { Button } from '../../components/Button'

export const DownloadButton: FC = () => {
  const router = useRouter()

  return (
    <Link href="https://docs.themirror.space/docs/get-started" passHref>
      <a target="_blank" rel="noopener noreferrer">
        <Button
          className={
            'mobile:hidden md:hidden bg-blueMirror w-[9rem] text-white text-sm font-semibold h-full'
          }
        >
          Download
        </Button>
      </a>
    </Link>
  )
}
