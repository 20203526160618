import { Button } from 'components/Button'
import { useModal } from 'features/modal/useModal'
import { NotificationType, useNotification } from 'features/notification'
import useSubscription from 'features/subscription/useSubscription'
import React from 'react'
import { plans, subscriptions } from './plans'
interface Props {
  planValue: string
  deleteFn: () => void
  loading?: boolean
}

const SubConfirmation: React.FC<Props> = ({ planValue, deleteFn, loading }) => {
  const { dismissModal } = useModal()
  const { notify } = useNotification()
  const { handleSubscribe } = useSubscription()

  const handleDelete = async () => {
    try {
      if (planValue === subscriptions.FREE) {
        await deleteFn()
        notify(
          'Your subscription has been cancelled successfully!',
          NotificationType.SUCCESS
        )
      } else {
        await deleteFn()
        await handleSubscribe(planValue)
      }
    } catch (error: any) {
      notify(error?.data?.message, NotificationType.WARNING)
    } finally {
      dismissModal()
    }
  }

  const planObj = plans.find((item) => item.planValue === planValue)

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      {planValue === subscriptions.FREE && (
        <h2 className="text-white text-center font-primary text-3xl font-bold mb-2">
          {' '}
          We’re sorry to see you go.{' '}
        </h2>
      )}
      <h3 className="text-white text-center font-primary font-medium">
        {planValue === subscriptions.FREE
          ? 'The Mirror is still always free for you to use via our free tier and the open-source code. We hope you’ll stick around on our Discord. Keep on building!'
          : `You're updating your current subscription to ${planObj?.plan}. Are you sure you want to continue?`}
      </h3>
      <h2></h2>
      <div className="flex gap-5 my-3 w-3/4 mx-auto">
        <Button className="w-full" onClick={handleDelete}>
          {' '}
          Confirm{' '}
        </Button>
      </div>
    </div>
  )
}

export default SubConfirmation
