import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Image } from './Image'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Menu, SubMenu } from 'interfaces/Sidebar'
import classNames from 'classnames'
import Loader from './Loader'
import { useAppSelector } from 'store/store'

type OptionsProps = {
  menu: Menu
  newTab?: boolean
  handleAction: (menu: Menu) => void
  loading: boolean
}
const OptionsMenu: FC<OptionsProps> = ({
  menu,
  newTab,
  handleAction,
  loading
}) => {
  const router = useRouter()
  const { sideView } = useAppSelector((state) => state.sidebarView)

  return (
    <Link passHref={true} key={menu.name} href={menu.href}>
      <>
        <Disclosure>
          <Disclosure.Button
            className={classNames(
              router.pathname === menu.href
                ? ' text-blueMirror cursor-pointer border-l-4 border-blueMirror bg-activeWhite'
                : 'text-white hover:text-blueMirror',
              'w-full text-cursor-pointer uppercase font-primary font-semibold tracking-[3px]'
            )}
          >
            <div
              className={classNames(
                'group flex flex-row w-full h-full py-4 px-10 hover:bg-activeWhite my-1 overflow-hidden',
                {
                  'cursor-not-allowed pointer-events-none': menu.disabled
                }
              )}
              aria-current={router.pathname === menu.href ? 'page' : undefined}
              onClick={() => {
                handleAction(menu)
              }}
            >
              <Image
                url={
                  router.pathname === menu.href
                    ? `/Icons/navigation/icon/active/${menu.icon}`
                    : `/Icons/navigation/icon/inactive/${menu.icon}`
                }
                className={classNames(
                  router.pathname === menu.href
                    ? 'text-blueMirror drop-shadow-activeText'
                    : 'text-white group-hover:text-blueMirror',
                  'flex-shrink-0 h-6 w-6 bg-transparent'
                )}
                alt={`${menu.name} Icon`}
              />

              <div
                className={`relative flex-col px-3 ${
                  sideView === 'open' ? 'flex' : ' lg:hidden'
                } `}
              >
                <span
                  className={classNames('truncate text-base', {
                    'text-disabledMirror': menu.disabled
                  })}
                >
                  {menu.name}
                </span>
                {loading && (
                  <div className="absolute -right-3 py-1">
                    <Loader />
                  </div>
                )}
                {menu.disabled && (
                  <span className="flex left-0 text-[0.563rem]">
                    {menu?.disabledText}
                  </span>
                )}
              </div>

              {!!menu.subMenu.length && !menu.disabled && (
                <span className="flex items-center pl-4">
                  <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                </span>
              )}
            </div>
          </Disclosure.Button>

          <Disclosure.Panel>
            {!menu.disabled &&
              menu.subMenu?.map((option: SubMenu) => (
                <span
                  key={option.name}
                  className={classNames(
                    router.pathname === option.href
                      ? ' text-blueMirror cursor-pointer border-blueMirror bg-activeWhite'
                      : 'text-white hover:text-blueMirror',
                    'flex flex-row px-20 font-primary font-semibold py-4 cursor-pointer border-l-4 border-transparent'
                  )}
                  onClick={() => {
                    router.push(option.href)
                  }}
                >
                  {option.name}
                </span>
              ))}
          </Disclosure.Panel>
        </Disclosure>
      </>
    </Link>
  )
}

export default OptionsMenu
