import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import FedUserState from '../interfaces/FedUser'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from './store'

export enum FederatedAuthenticationServices {
  DISCORD = 'discord'
}

export type FedAuthStatePayload = PayloadAction<{
  fedOAuthAccessToken: string
  federatedAuthService: FederatedAuthenticationServices
}>

export type FedAuthState = {
  token: string | null
  fedOAuthAcessToken: string | null
  federatedAuthService: FederatedAuthenticationServices | null
  loggedIn: boolean
  user: FedUserState | null
  pending: boolean
  rejected: boolean
  rejectionMessage: string | null
  success: boolean
  error: boolean
  errorMessage: string | null
  data: any
}

const initialState: FedAuthState = {
  token: null,
  fedOAuthAcessToken: null,
  federatedAuthService: null,
  loggedIn: false,
  user: null,
  pending: false,
  rejected: false,
  rejectionMessage: null,
  success: false,
  error: false,
  errorMessage: null,
  data: null
}

export const fedAuthSlice = createSlice({
  name: 'fedAuth',
  initialState,
  reducers: {
    setFedOAuthAccessToken: (
      state,
      {
        payload: { fedOAuthAccessToken, federatedAuthService }
      }: FedAuthStatePayload
    ) => {
      state.fedOAuthAcessToken = fedOAuthAccessToken
      state.federatedAuthService = federatedAuthService
      return state
    },

    loginInitiated(state, action): void {
      state.pending = true
      state.loggedIn = false
      state.success = false
      state.token = null
      state.federatedAuthService = action.payload.federatedAuthService
      state.rejected = false
      state.rejectionMessage = null
      state.error = false
      state.errorMessage = null
      state.data = null
      state.user = null
    },
    loginSuccess(state, action): void {
      const auth = getAuth()
      /** TODO
       * this fails with TypeError: Cannot perform 'set' on a proxy that has been revoked at eval
       * */
      signInWithCustomToken(
        auth,
        action.payload.data.customMintedFirebaseAuthToken
      )
        .then((userCredential) => {
          state.user = userCredential.user as any // temp fix till shape is correct
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
        })

      state.pending = false
      state.loggedIn = true
      state.success = true
      state.token = action.payload.data.customMintedFirebaseAuthToken
      state.federatedAuthService = action.payload.federatedAuthService
      state.rejected = false
      state.rejectionMessage = null
      state.error = false
      state.errorMessage = null
      state.data = action.payload
    },
    loginRejected(state, action): void {
      state.pending = false
      state.loggedIn = false
      state.success = false
      state.token = null
      state.rejected = true
      state.rejectionMessage = action.payload.message
      state.error = false
      state.errorMessage = null
      state.data = action.payload
      state.user = null
    },
    loginError(state, action): void {
      state.pending = false
      state.loggedIn = false
      state.success = false
      state.token = null
      state.rejected = false
      state.rejectionMessage = null
      state.error = true
      state.errorMessage = action.payload.message
      state.data = action.payload
      state.user = null
    },
    logout(state, action): void {
      state.pending = false
      state.loggedIn = false
      state.success = false
      state.token = null
      state.rejected = false
      state.rejectionMessage = null
      state.error = false
      state.errorMessage = null
      state.data = action.payload
      state.user = null
    },
    isLoggedIn(state): void {
      state.user = getAuth().currentUser as any // temp fix till shape is correct
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.fedAuth
      }
    }
  }
})

export const { setFedOAuthAccessToken } = fedAuthSlice.actions
export const selectFedAuthUser = (state: AppState) => state.fedAuth.user
