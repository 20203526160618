import React, { FC } from 'react'
import Heading from '../../components/Heading'
import FormHeaderSection from '../../components/FormHeaderSection'

const DownloadForm: FC = () => {
  /**
   * Needs Links or logic to download app
   */
  return (
    <>
      <FormHeaderSection>
        <Heading size="2">Download The Mirror</Heading>
      </FormHeaderSection>

      <p className="text-white text-center font-primary text-xl leading-relaxed my-5">
        {' '}
        <span className="font-semibold">Recommended</span>: Download via the
        itch.io app for auto-updating.{' '}
      </p>

      <p className="text-white font-primary text-xl leading-relaxed my-5 text-center">
        {' '}
        We regularly push new features and bug fixes, which require the latest
        version.{' '}
      </p>

      <div className="flex justify-center items-center gap-5 my-6">
        <p className="text-white font-primary text-xl font-semibold">
          Download:
        </p>
        <a href="https://itch.io/app/download">
          {' '}
          <img className="w-[10rem]" src="/images/itch.svg" alt="itch" />
        </a>
      </div>

      <div className="flex justify-center items-center gap-5 my-6">
        <p className="text-white font-primary text-xl text-center">
          {' '}
          In the Itch app,{' '}
          <span className="font-semibold block">
            search for &quot;The mirror&quot; and download:
          </span>{' '}
        </p>
        <a href="https://themirrorgdp.itch.io/the-mirror">
          <img src="/images/mirror-itch-cover.jpg" alt="mirror" />
        </a>
      </div>
    </>
  )
}

export default DownloadForm
