import { FC } from 'react'

type Props = {
  children: string
  className?: string
}

const Paragraph: FC<Props> = ({ children, className = 'text-center' }) => {
  return (
    // The ${!className.includes('max-w') && 'max-w-[27.375rem]'} is to allow for width overriding with a default
    <p
      className={`font-primary text-white text-sm font-medium leading-[1.375rem] ${
        !className.includes('max-w') && 'max-w-[27.375rem]'
      } ${className} `}
    >
      {children}
    </p>
  )
}

export default Paragraph
