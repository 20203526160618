import {
  AssetApiResponse,
  CreateAssetDto,
  useAssetControllerCreateMutation,
  useAssetControllerUploadMutation,
  useAssetControllerUploadPublicMutation,
  useAssetControllerUploadThumbnailMutation
} from '../api/apiMirrorSlice.generated'
import { NotificationType, useNotification } from '../notification'
import { useFile } from '../files/useFile'

export type FormValues = {
  name: string
  description: string
  public: boolean
  file: File
  mirrorPublicLibrary?: boolean
}

type SubmitAsset = {
  addAsset: (values: FormValues) => Promise<void>
  isLoading: boolean
}

export const useSubmitAsset = (): SubmitAsset => {
  const { notify } = useNotification()
  const { getAssetTypeFromFile } = useFile()

  const [createAsset, { isLoading: isCreateAssetLoading }] =
    useAssetControllerCreateMutation()

  /**
   * TODO
   *  Public and Private needs more discussion.
   *  Upload is now using codegen but we should split public and private
   *   uploads to be handled separately.
   *  This process would be best handled on the backend. If upload fails but createAsset
   *   succeeds, what should happen?
   *  How should private assets be handled?
   *  How do we handle a User editing an asset from Public to Private?
   */
  const [uploadAssetPublic, { isLoading: isUploadAssetPublicLoading }] =
    useAssetControllerUploadPublicMutation()
  const [uploadAssetThumbnail, { isLoading: isUploadAssetThumbLoading }] =
    useAssetControllerUploadThumbnailMutation()
  const [uploadAsset, { isLoading: isUploadAssetLoading }] =
    useAssetControllerUploadMutation()

  const getUrlFromUpload = async (
    assetResponse: AssetApiResponse,
    values: FormValues
  ) => {
    const formData = new FormData()
    formData.append('file', values.file)

    if (values.file.type.includes('image')) {
      await uploadAssetThumbnail({
        id: assetResponse._id,
        body: formData
      }).unwrap()
    } else {
      /** File is a Mesh and requires 3d view implementation */
    }

    if (values.public) {
      await uploadAssetPublic({
        id: assetResponse._id,
        body: formData
      }).unwrap()
    } else {
      await uploadAsset({
        assetId: assetResponse._id,
        body: formData
      }).unwrap()
    }
  }

  const addAsset = async (values: FormValues) => {
    try {
      const createAssetDto: CreateAssetDto = {
        name: values.name,
        description: values.description,
        assetType: getAssetTypeFromFile(values.file),
        public: values.public
      }
      const assetResponse = await createAsset({ createAssetDto }).unwrap()
      await getUrlFromUpload(assetResponse, values)
      notify('Asset created!', NotificationType.SUCCESS)
    } catch (error: any) {
      const message = error?.data?.message || 'Asset failed to create!'
      notify(message, NotificationType.WARNING)
    }
  }

  return {
    addAsset,
    isLoading:
      isCreateAssetLoading ||
      isUploadAssetPublicLoading ||
      isUploadAssetThumbLoading ||
      isUploadAssetLoading
  }
}
