import { FC, useEffect, useState } from 'react'
import { useAppSelector } from 'store/store'
import { selectMyUser } from 'features/users/usersSlice'
import OptionsMenu from './OptionsMenu'
import { Menu } from 'interfaces/Sidebar'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import {
  doesNotHaveAccessToAlpha,
  hasAccessToAlpha
} from '../features/account/accessTier'

type sideProps = {
  classMobile?: string
}

const Sidebar: FC<sideProps> = ({ classMobile = '' }) => {
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean
  }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { data: user } = useAppSelector(selectMyUser)
  const router = useRouter()

  const handleActions = async (item: Menu) => {
    const { subMenu, href, newTab }: Menu = item
    if (newTab) {
      window.open(href, '_blank', 'noopener,noreferrer')
      return
    }
    if (!subMenu.length && href) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [item.name]: true
      }))

      try {
        await router.push(href)
      } finally {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [item.name]: false
        }))
      }
    }
  }

  useEffect(() => {
    const hasLoading = Object.values(loadingStates).some((value) => value)
    setLoading(hasLoading)
  }, [loadingStates])

  /** Temporarily remove menu options for closed alpha */
  const navigation: Menu[] = [
    {
      name: 'Home',
      href: '/',
      icon: 'home.svg',
      subMenu: [],
      auth: false,
      disabled: loading
    },
    {
      name: 'Discover',
      href: '/discover',
      icon: 'discover.svg',
      subMenu: [],
      auth: false,
      disabled: loading
    },
    {
      name: 'My Spaces',
      href: '/spaces',
      icon: 'spaces.svg',
      subMenu: [],
      auth: true,
      disabled: loading
    },
    {
      name: 'My Assets',
      href: '/assets',
      icon: 'assets.svg',
      subMenu: [],
      auth: true,
      disabled: loading
    },
    // Disable groups until additional features, such as invites and deep linking.
    // {
    //   name: 'Groups',
    //   href: '',
    //   icon: 'groups.svg',
    //   subMenu: [
    //     {
    //       name: 'Explore',
    //       href: '/explore-groups'
    //     },
    //     {
    //       name: 'My Groups',
    //       href: '/my-groups'
    //     }
    //   ],
    //   auth: true,
    //   disabled: false
    // },

    {
      name: 'My Friends',
      href: '/friends',
      icon: 'friends.svg',
      subMenu: [],
      auth: true,
      disabled: loading
    },
    {
      name: 'Chat',
      href: 'https://discord.com/invite/CK6fH3Cynk',
      newTab: true,
      icon: 'discord.svg',
      subMenu: [],
      auth: false,
      disabled: loading
    },
    {
      name: 'Roadmap',
      href: 'https://github.com/orgs/the-mirror-gdp/projects/7',
      newTab: true,
      icon: 'github.svg',
      subMenu: [],
      auth: false,
      disabled: loading
    },
    {
      name: 'Docs',
      href: 'https://docs.themirror.space',
      icon: 'support.svg', // TODO add a docs svg
      subMenu: [],
      auth: false,
      disabled: loading,
      newTab: true
    }
  ]

  if (process.env.NEXT_PUBLIC_MARKETPLACE_ENABLED == 'true') {
    // add marketplace temp feature flag
    navigation.slice()
    navigation.splice(2, 0, {
      name: 'Marketplace',
      href: '/marketplace',
      icon: 'market.svg',
      subMenu: [],
      auth: false,
      disabled: loading
    })
  }

  let filteredOptions = user
    ? navigation
    : navigation.filter((menu) => !menu.auth)

  // Filter out auth if user is not authed
  filteredOptions = user?.isInternalAdmin
    ? filteredOptions
    : filteredOptions.filter((menu) => !menu.adminOnly)
  return (
    <div
      className={classNames(
        'sticky top-24 hidden lg:block lg:w-fit w-full bg-bluenav lg:rounded-xl h-full lg:h-fit py-8 ml-0 mr-auto',
        classMobile
      )}
    >
      <nav
        aria-label="Sidebar"
        className={`flex flex-col justify-start w-full h-fit mobile:pb-0 mobile:pt-0 md:pb-0 md:pt-0`}
      >
        {filteredOptions.map((menu: Menu) => (
          <OptionsMenu
            key={menu.name}
            menu={menu}
            newTab={menu?.newTab}
            handleAction={handleActions}
            loading={loadingStates[menu.name] || false}
          />
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
