import { Button } from '../../components/Button'
import FormHeaderSection from '../../components/FormHeaderSection'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { useUserFeedbackControllerRemoveCommentMutation } from '../api/apiMirrorSlice.generated'
import { useModal } from '../modal/useModal'

const DeleteUserFeedbackCommentForm = ({ id }) => {
  const [removeUserFeedbackComment, isLoading] =
    useUserFeedbackControllerRemoveCommentMutation()
  const { dismissModal } = useModal()

  const onDelete = async () => {
    await removeUserFeedbackComment({
      id: id
    })

    dismissModal()
  }

  return (
    <div>
      <FormHeaderSection>
        <Heading size="3">Delete Comment</Heading>
        <Paragraph>Are you sure?</Paragraph>
      </FormHeaderSection>

      <div className={'flex justify-center mt-10 gap-5'}>
        <Button type="button" onClick={() => onDelete()}>
          Confirm
        </Button>
        <Button
          className="bg-grayMirror hover:bg-grayMirror"
          type="button"
          onClick={() => dismissModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default DeleteUserFeedbackCommentForm
