import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useNotification, NotificationType } from '../notification'
import { useEffect, useState } from 'react'
import { useModal } from '../modal/useModal'
import { SignInValues } from './SignInForm'
import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import { useAppDispatch } from '../../store/store'
import * as Sentry from '@sentry/nextjs'

export const useSignInUser = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { notify } = useNotification()
  const { dismissModal } = useModal()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      setIsLoading(false)
    }
  }, [])

  /** Handle Firebase sign-in request */
  const signInUser = async ({ email, password }: SignInValues) => {
    setIsLoading(true)
    try {
      const { user: firebaseUser } = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      )
      notify(`Hello ${firebaseUser.displayName}`, NotificationType.WELCOME)
      dismissModal()
    } catch (error: any) {
      setIsLoading(false)
      const errorMessage = error?.data?.message || error?.message
      if (errorMessage.includes('Firebase: Error (auth/user-not-found)')) {
        Sentry.captureMessage('User not found ' + email)
        notify("We couldn't find that user.", NotificationType.INFO)
      } else {
        notify(errorMessage, NotificationType.WARNING)
      }
    }
  }

  return { signInUser, isLoading }
}
