import { FC, Fragment } from 'react'
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/outline'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { selectNotification, unShowNotification } from 'store/notification'
import { NotificationType } from './notificationType.enum'

const Notification: FC = () => {
  const dispatch = useAppDispatch()
  const { message, show, type } = useAppSelector(selectNotification)

  let text = ''
  let icon: React.ComponentProps<'svg'>

  switch (type) {
    case NotificationType.INFO:
      // text = 'FYI'
      icon = (
        <InformationCircleIcon
          className="w-6 h-6 text-blueMirror"
          aria-hidden="true"
        />
      )
      break
    case NotificationType.WARNING:
      text = 'Warning'
      icon = (
        <ExclamationCircleIcon
          className="w-6 h-6 text-yellowMirror"
          aria-hidden="true"
        />
      )
      break
    case NotificationType.ERROR:
      text = 'Error'
      icon = (
        <ExclamationCircleIcon
          className="w-6 h-6 text-orangeMirror"
          aria-hidden="true"
        />
      )
      break
    case NotificationType.WELCOME:
      text = 'Welcome'
      icon = (
        <CheckCircleIcon
          className="w-6 h-6 text-greenMirror"
          aria-hidden="true"
        />
      )
      break
    default:
    case NotificationType.SUCCESS:
      text = 'Success'
      icon = (
        <CheckCircleIcon
          className="w-6 h-6 text-greenMirror"
          aria-hidden="true"
        />
      )
      break
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[100]"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-100 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{text}</p>
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => dispatch(unShowNotification())}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

export default Notification
