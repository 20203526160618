export function parseSearchInput(input) {
  const tags: string[] = []
  let name: string = ''

  if (typeof input === 'string') {
    const words: string[] = input.split(' ')

    words.forEach((word: string) => {
      if (word.startsWith('#')) {
        // word starts with '#', it's tag
        tags.push(word.substring(1))
      } else {
        //  doesn't start with '#',it's name
        name += `${word} `
      }
    })

    name = name.trim()
  }

  return { name, tags }
}
