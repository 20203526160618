import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Friend'>({
  endpoints: {
    userControllerGetMyFriends: {
      providesTags: (result, error, arg) => [{ type: 'Friend' }]
    },
    userControllerAcceptFriendRequest: {
      invalidatesTags: (result, error, arg) => [{ type: 'Friend' }]
    },
    userControllerRemoveFriend: {
      invalidatesTags: (result, error, arg) => [{ type: 'Friend' }]
    }
  }
})

export const selectMyFriend =
  apiMirrorSlice.endpoints.userControllerGetMyFriends.select()
