import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@fortawesome/fontawesome-svg-core'

const AudioAsset = ({ src, className, position, id }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const audioElement = document.getElementById(id) as HTMLAudioElement

    if (audioElement) {
      const handleAudioStateChange = () => {
        setIsPlaying(!audioElement.paused)
      }

      audioElement.addEventListener('play', handleAudioStateChange)
      audioElement.addEventListener('pause', handleAudioStateChange)
      audioElement.addEventListener('ended', handleAudioStateChange)

      return () => {
        audioElement.removeEventListener('play', handleAudioStateChange)
        audioElement.removeEventListener('pause', handleAudioStateChange)
        audioElement.removeEventListener('ended', handleAudioStateChange)
      }
    }
  }, [id])

  const togglePlay = () => {
    const audioElement = document.getElementById(id) as HTMLAudioElement
    const allAudioElements = document.querySelectorAll('audio')

    if (isPlaying) {
      audioElement.pause()
    } else {
      // Pause all other audio elements
      allAudioElements.forEach((element) => {
        if (element.id !== id) {
          element.pause()
        }
      })
      audioElement.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div className={`audio-asset ${className}`}>
      <div className="audio-player">
        <audio id={id}>
          <source src={src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <div
          className={`play-pause-button flex items-center justify-center border border-blueMirror rounded-full cursor-pointer ${
            position === 'card'
              ? 'h-[3.25rem] w-[3.25rem]'
              : position === 'lg-card'
              ? 'w-[6rem] h-[6rem] mt-6'
              : 'h-[20rem] w-[20rem]'
          } text-center mx-auto transition`}
          onClick={togglePlay}
        >
          <FontAwesomeIcon
            icon={isPlaying ? (faPause as Icon) : (faPlay as Icon)}
            className={`text-blueMirror ${isPlaying ? 'pl-0' : 'pl-2'} ${
              position === 'card' ? 'text-2xl' : 'text-4xl'
            } ${isPlaying ? 'animate-pulse' : ''}`}
          />
        </div>
      </div>
    </div>
  )
}

export default AudioAsset
