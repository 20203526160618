import { XIcon } from '@heroicons/react/solid'

export default function CloseModalIcon({ closeModal }) {
  return (
    <div
      onClick={closeModal}
      className="w-5 h-5 fill-white absolute top-3 right-3 cursor-pointer"
    >
      <XIcon className="fill-white" />
    </div>
  )
}
