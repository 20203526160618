import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FC } from 'react'
import classnames from 'classnames'

type LoaderProps = {
  className?: string
}
const Loader: FC<LoaderProps> = ({ className }) => (
  <div className={classnames('flex items-center justify-center', className)}>
    <FontAwesomeIcon
      icon={faCircleNotch as IconProp}
      spin={true}
      className="text-center w-4 h-4 text-blueMirror drop-shadow-activeText"
    ></FontAwesomeIcon>
  </div>
)
export default Loader
