import React, { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CloseModalIcon from '../../components/CloseModalIcon'
import { useAppSelector } from '../../store/store'
import { selectModal } from './modalSlice'
import { MODAL_MAP } from './modalMap'
import { useModal } from './useModal'
import { useRouter } from 'next/router'

const Modal = () => {
  const router = useRouter()
  const { show, component } = useAppSelector(selectModal)
  const { dismissModal } = useModal()

  /** Dynamically created from ModalSlice component using MODAL_MAP. */
  const ModalContent = (): JSX.Element => {
    if (!component?.name) {
      return <div>Something went wrong!</div>
    }

    const ModalComponent = MODAL_MAP[component.name]
    return <ModalComponent {...component.props} />
  }

  const handleClose = () => {
    if (component?.name === 'DownloadForm') {
      if (router.query.hasOwnProperty('download-popup')) {
        router.push('/')
      }
    }
  }

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => {
            handleClose()
            dismissModal()
          }}
        >
          <div className="flex items-center justify-center min-h-screen text-center p-2">
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacityModal bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-in duration-100"
              enterFrom="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
              enterTo="opacity-100 translate-y-0 mobile:scale-100"
              leave="ease-out duration-100"
              leaveFrom="opacity-100 translate-y-0 mobile:scale-100"
              leaveTo="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
            >
              <div className="text-left transition-all transform backdrop-filter backdrop-blur-lg rounded-lg shadow-xl bg-bgform p-[5rem] mobile:p-8 mobile:py-14 lg:min-w-[36rem] max-w-2xl">
                <CloseModalIcon
                  closeModal={() => {
                    handleClose(), dismissModal()
                  }}
                />
                <ModalContent />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Modal
