import { ERRORS_OPTIONS_GROUP } from 'enums/ErrorAssetForm'
import * as Yup from 'yup'

/**
 * TODO
 *  Temp remove file-upload validation until mirror-server upload endpoint is implemented
 */
export const groupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name too short!')
    .max(50, 'Name too long')
    .required(`${ERRORS_OPTIONS_GROUP.REQUEST_NAME}`)
  // 'file-upload': Yup.mixed().required(`${ERRORS_OPTIONS_GROUP.REQUEST_FILE}`)
})
