import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const sidebarViewSlice = createSlice({
  name: 'sidebarView',
  initialState: {
    sideView: 'open'
  },
  reducers: {
    setSideView: (state, action: PayloadAction<string>) => {
      state.sideView = action.payload
    }
  }
})

export const { setSideView } = sidebarViewSlice.actions
