import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FC } from 'react'
import { FileLoaded } from 'components/UploadFileWithPreview'

type ThumbnailProps = {
  fileLoaded: FileLoaded
}

const Thumbnail: FC<ThumbnailProps> = ({ fileLoaded }) => {
  const width = 400
  const height = 400
  const alt = 'The asset image'
  const src = fileLoaded.file ? URL.createObjectURL(fileLoaded.file) : ''

  return (
    <>
      {
        // file is loaded in your browser
        fileLoaded.loaded && !fileLoaded.working && (
          <img
            alt={alt}
            className="mx-auto my-3 max-h-48 w-auto"
            width={width}
            height={height}
            src={src}
          />
        )
      }

      {
        // file is loading in the browser from your computer
        !fileLoaded.loaded && fileLoaded.working && (
          <>
            <FontAwesomeIcon
              icon={faSpinner as IconProp}
              spin
              className="text-center"
            ></FontAwesomeIcon>
            <span>analyzing image ...</span>
          </>
        )
      }
    </>
  )
}

export default Thumbnail
