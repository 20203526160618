import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Assets'>({
  endpoints: {
    assetControllerGetPaginatedMyAssetsV2: {
      providesTags: (result, error, arg) => [{ type: 'Assets' }]
    },

    // assetControllerUpdate: {
    //   onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
    //     try {
    //       const { data: updatedAsset } = await queryFulfilled;
    //       dispatch(
    //         apiMirrorSlice.util.updateQueryData(
    //           'assetControllerGetPaginatedMyAssetsV2',
    //           {} as any,
    //           (assetData: any) => {
    //             const currentAssets = assetData?.data || [];
    //             const index = currentAssets.findIndex((existingAsset) => existingAsset._id === id);
    //             if (index !== -1) {
    //               currentAssets[index] = updatedAsset;
    //             }
    //           }
    //         )
    //       );
    //     } catch (error: any) {
    //       // TODO add error handling
    //       throw new Error(error);
    //     }
    //   },
    //   invalidatesTags: (result, error, arg) => [{type: 'Assets'}]
    // },

    assetControllerRemove: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'assetControllerGetPaginatedMyAssetsV2',
              {} as any,
              (assetData: any) => {
                const currentAssets = assetData?.data || []
                const index = currentAssets.findIndex(
                  (asset) => asset._id === id
                )

                if (index > -1) {
                  // Create a new array without the removed asset
                  assetData.data = [
                    ...currentAssets.slice(0, index),
                    ...currentAssets.slice(index + 1)
                  ]
                }
              }
            )
          )
        } catch (error: any) {
          Sentry.captureException(error)
          // TODO add error handling
          console.error(error)
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Assets' }]
    },

    assetControllerUploadPublic: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data: asset } = await queryFulfilled
          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'assetControllerGetPaginatedMyAssetsV2',
              {} as any,
              (assetData: any) => {
                const currentAssets = assetData?.data || []
                const index = currentAssets.findIndex(
                  (existingAsset) => existingAsset._id === id
                )

                if (index === -1) {
                  assetData.data = [...currentAssets, asset]
                } else {
                  currentAssets[index] = asset
                }
              }
            )
          )
        } catch (error: any) {
          Sentry.captureException(error)

          // You might need to handle the error here
          // Depending on your requirements, you can dispatch an action to handle the error state
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Assets' }]
    }
  }
})

export const selectMyAssets = (options: any) =>
  apiMirrorSlice.endpoints.assetControllerGetPaginatedMyAssetsV2.select(
    options as any
  )

// export const selectMyAssetsPreview = (state) =>
//   selectMyAssets(state).data?.slice(0, 5)
