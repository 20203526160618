import React, { FC } from 'react'
import { Button } from 'components/Button'
import { useModal } from '../modal/useModal'
import { ModalContent } from '../modal/modalContent.enum'
interface LoginButtonsProps {
  position: string
}

const LoginButtons: FC<LoginButtonsProps> = ({ position }) => {
  const { showModalWith } = useModal()
  return (
    <>
      <div className="flex gap-2 justify-end w-full h-10">
        <Button
          icon
          onClick={() =>
            showModalWith({ name: ModalContent.SignInModalContent })
          }
          className="bg-blueMirror text-sm shadow-none hover:bg-transparent"
        >
          {position === 'navbar' ? 'Sign In' : 'Sign In to Submit'}
        </Button>
      </div>
    </>
  )
}

export default LoginButtons
