import React, { FC } from 'react'
import {
  faDiscord,
  faFacebook,
  faGoogle
} from '@fortawesome/free-brands-svg-icons'
import { ButtonTransparent } from '../../components/ButtonTransparent'
import SignUpButton from '../../components/SignUpButton'
import SignUpForm, { SignUpValues } from './SignUpForm'
import { useModal } from '../modal/useModal'
import { ModalContent } from '../modal/modalContent.enum'
import FormHeaderSection from '../../components/FormHeaderSection'
import Heading from '../../components/Heading'

type Props = {
  formValues?: SignUpValues
}

const SignUpModalContent: FC<Props> = ({ formValues }) => {
  const { showModalWith } = useModal()

  const switchToSignIn = () => {
    showModalWith({ name: ModalContent.SignInModalContent })
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Create Account</Heading>

        <p className="text-white text-sm font-primary font-medium my-2">
          Already have an account?
          <ButtonTransparent onClick={switchToSignIn} className="ml-[3px]">
            Sign in here
          </ButtonTransparent>
          .
        </p>
      </FormHeaderSection>

      {/* 2023-02-19 02:27:07 hiding until we implement */}
      {/* <div className="grid grid-cols-3 justify-between space-x-[0.609rem]">
        <SignUpButton disabled={true} text="Google" label="Sign up with" icon={faGoogle} />
        <SignUpButton disabled={true} text="Discord" label="Sign up with" icon={faDiscord} />
        <SignUpButton disabled={true} text="Facebook" label="Sign up with" icon={faFacebook} />
      </div>

      <hr className="my-8 border-gray-500" /> */}

      <SignUpForm formValues={formValues} />
    </div>
  )
}

export default SignUpModalContent
