import React, { FC } from 'react'
import moment from 'moment'

const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' }
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' }
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' }
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' }
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/itsonthemirror',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    }
  ]
}

const Footer: FC = () => {
  return (
    <footer
      className="mt-[11.3125rem] h-16 flex justify-center items-center px-2 py-2 sm:px-10 "
      aria-labelledby="footer-heading"
    >
      <div className="flex justify-between items-center w-full flex-col lg:flex-row">
        <p className="text-xs text-grayMirror font-primary text-center order-2 lg:order-1">
          &copy; {moment().year()} The Mirror Megaverse Inc. All Rights
          Reserved. Megaverse and &quot;It&apos;s In The Mirror&quot; are
          pending trademarks with the USPTO.
        </p>

        <div className="order-1 lg:order-2">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://themirror.space/terms"
            className="text-blueMirror font-primary text-xs mx-5"
          >
            Terms of Service
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://themirror.space/privacy"
            className="text-blueMirror font-primary text-xs"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
