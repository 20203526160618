export enum LOGIN_ERROR {
  EMAIL = 'Please enter your email',
  EMAIL_VALID = 'Please enter a valid email',
  EMAIL_MAX_LENGTH = 'Please limit your email to 60 characters',
  PASSWORD = 'Please enter your password',
  PASSWORD_CONFIRM = 'Passwords must match',
  PASSWORD_MIN = 'Password must be at least 6 characters',
  DISPLAY_NAME = 'Please enter your display name',
  DISPLAY_NAME_MAX_LENGTH = 'Please limit your display name to 40 characters',
  DISPLAY_NAME_MIN_LENGTH = 'Your display Name requires minimum 3 characters',
  TERMS_OF_SERVICE = 'You must agree to the Terms of Service and Privacy Policy'
}
