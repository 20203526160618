import { FC } from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import TextArea from '../../components/TextArea'
import { Formik, Form, Field } from 'formik'
import { Button } from '../../components/Button'
import { NotificationType, useNotification } from '../notification'
import { groupSchema } from '../../components/groups/group.validationSchema'
import { useSubmitGroup } from '../../components/groups/useSubmitGroup'
import { UploadFileWithPreview } from '../../components/UploadFileWithPreview'
import InputField from '../../components/InputField'
import { useModal } from '../modal/useModal'
import { ButtonTransparent } from '../../components/ButtonTransparent'

const CreateGroupForm: FC = () => {
  const { addGroup, isLoading } = useSubmitGroup()
  const { notify } = useNotification()
  const { dismissModal } = useModal()

  const initialValues = {
    name: '',
    'file-upload': '',
    image: '',
    publicDescription: '',
    public: 'true'
  }

  const onSubmit = async (values) => {
    await addGroup({
      name: values.name,
      publicDescription: values.publicDescription,
      file: values['file-upload'],
      public: JSON.parse(values.public)
    })

    notify('Group created!', NotificationType.SUCCESS)
    dismissModal()
  }

  return (
    <>
      <div className="grid justify-items-center mb-[1.75rem]">
        <Heading size="2">Create group</Heading>
        <Paragraph>
          Please enter the information about your new group below!
        </Paragraph>
      </div>

      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={groupSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-4 ">
            <div className="col-start-1">
              <InputField
                type="text"
                label="Group Name"
                name="name"
                disabled={isLoading}
              />
            </div>
            <div className="col-start-1">
              <Field name="publicDescription">
                {({ field }) => {
                  return (
                    <>
                      <TextArea
                        label="Description"
                        disabled={isLoading}
                        {...field}
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="sm:col-span-6">
              <Field
                className="block w-full border-gray-300  focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded"
                id="fileUpload"
                name="file-upload"
                disabled={isLoading}
              >
                {(props) => <UploadFileWithPreview {...props} />}
              </Field>
            </div>

            <div className="col-start-1 mt-2 mb-2">
              <Field
                type="radio"
                name="public"
                className="h-4 w-4 border border-gray-300 rounded-full bg-transparent checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain  mr-2 cursor-pointer form-check-input bg-white  "
                value={'true'}
              />
              <label
                className="text-white font-primary text-sm"
                htmlFor="public"
              >
                Public
              </label>
              <Field
                type="radio"
                name="public"
                className="h-4 w-4 border border-gray-300 rounded-full bg-transparent checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain  mr-2 cursor-pointer ml-4 "
                value={'false'}
              />
              <label
                className="text-white font-primary text-sm"
                htmlFor="private"
              >
                Private
              </label>
            </div>
            <div className="sm:col-span-6">
              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                className="w-full"
              >
                Create Group
              </Button>
            </div>

            <div className=" grid justify-items-center">
              <ButtonTransparent
                onClick={dismissModal}
                className="underline underline-offset-1"
              >
                Cancel
              </ButtonTransparent>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default CreateGroupForm
