import React, { FC } from 'react'
import { ButtonTransparent } from '../../components/ButtonTransparent'
import FormHeaderSection from '../../components/FormHeaderSection'
import Heading from '../../components/Heading'
import { ModalContent } from '../modal/modalContent.enum'
import { useModal } from '../modal/useModal'
import SignInForm from './SignInForm'

const SignInModalContent: FC = () => {
  const { showModalWith } = useModal()

  const switchToSignUp = () => {
    showModalWith({ name: ModalContent.SignUpModalContent })
  }

  const switchToResetPassword = () => {
    showModalWith({ name: ModalContent.ForgotPasswordForm })
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Sign In</Heading>

        <p className="text-white text-sm font-primary font-medium my-2">
          If you do not already have an account,
          <ButtonTransparent onClick={switchToSignUp} className="ml-[5px]">
            sign up here
          </ButtonTransparent>
          .
        </p>
      </FormHeaderSection>

      {/* 2023-02-19 02:27:07 hiding until we implement */}
      {/* <div className="grid grid-cols-3 justify-between space-x-2">
        <SignInButton disabled={true} text="Google" label="Login with" icon={faGoogle} />
        <SignInButton disabled={true} text="Discord" label="Login with" icon={faDiscord} />
        <SignInButton disabled={true} text="Facebook" label="Login with" icon={faFacebook} />
      </div> 

      <hr className="my-8 border-gray-500" />*/}

      <SignInForm />

      <ButtonTransparent
        onClick={switchToResetPassword}
        className="underline underline-offset-1 w-full p-4"
      >
        Forgot Password?
      </ButtonTransparent>
    </div>
  )
}

export default SignInModalContent
