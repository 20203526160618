import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const initAuth = () => {
  // Configure Firebase.
  const config = {
    apiKey:
      process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PUBLIC_API_KEY ||
      'AIzaSyARDm49cUSJrN5h2kv8_SUD7ADZJM4yTuU',
    authDomain:
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ||
      'the-mirror-backend-dev.firebaseapp.com',
    databaseURL:
      process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ||
      'https://the-mirror-backend-dev-default-rtdb.firebaseio.com'
  }
  const app = firebase.initializeApp(config)

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.

  // uncomment on localhost for the debug token
  //global.FIREBASE_APPCHECK_DEBUG_TOKEN = true

  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(
  //     process.env.NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY as string
  //   ),

  //   // Optional argument. If true, the SDK automatically refreshes App Check
  //   // tokens as needed.
  //   isTokenAutoRefreshEnabled: true,
  // })
}

export default initAuth
