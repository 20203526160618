import React from 'react'
import { useAppSelector } from 'store/store'

export default function InnerContainer({ children }) {
  const { sideView } = useAppSelector((state) => state.sidebarView)

  return (
    <div className="2xl:flex 2xl:justify-center mt-8 lg:mt-0">
      <div
        className={`transition-all duration-75 px-[3.125rem] mobile:px-6 lt-lg:mt-4 mt-12 lg:grid min-h-screen ${
          sideView === 'open'
            ? 'lg:grid-cols-[minmax(0,21.4375rem),minmax(0,108.8125rem)]'
            : 'lg:grid-cols-[minmax(0,11.4375rem),minmax(0,118.8125rem)]'
        }`}
      >
        {children}
      </div>
    </div>
  )
}
