import { ERROR_COPY } from 'enums/ErrorListListing'
import * as Yup from 'yup'

export const listingSchema = Yup.object().shape({
  image: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      `${ERROR_COPY.INVALID_IMAGE_URL}`
    )
    .required('Please enter website'),
  titleLowerCase: Yup.string()
    .min(2, 'Name too short!')
    .max(50, 'Name too long')
    .required(`${ERROR_COPY.TITLE}`),
  priceEth: Yup.number().required(`${ERROR_COPY.PRICE_ETHEREUM}`),
  asset: Yup.string().required(`${ERROR_COPY.ASSET}`)
})
