import React, { FC, HTMLProps } from 'react'
import { ErrorMessage, useField } from 'formik'

interface Props extends HTMLProps<HTMLInputElement> {
  name: string
}

const InputField: FC<Props> = ({ label, className, disabled, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="flex relative w-full">
      <div className="flex-wrap mt-1 rounded-md shadow-sm w-full">
        <input
          id={props.name}
          autoComplete="none"
          {...field}
          {...props}
          className={`
          block w-full h-[3.125rem] rounded-xl focus:outline-none pt-6 pl-4 bg-bluenav text-white text-base font-semobold font-primary border-gray-700 ${
            disabled && 'text-disabledMirror'
          } ${
            meta.touched && meta.error
              ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
              : 'focus:border-ringBlue'
          } ${className}
          `}
        />

        <ErrorMessage
          component="div"
          name={props.name}
          className="text-left text-red-400"
        />

        {label && (
          <label
            htmlFor={props.name}
            className="absolute left-4 top-2 text-textInput text-xs font-semibold font-primary peer-placeholder-shown:text-sm peer-focus:text-xs"
          >
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export default InputField
