import { ErrorMessage, useField } from 'formik'
import { FC, HTMLProps } from 'react'
import classNames from 'classnames'

interface Props extends HTMLProps<HTMLTextAreaElement> {
  name: string
}

const TextareaField: FC<Props> = ({ label, disabled, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="relative snap-x">
      <textarea
        id={props.name}
        {...props}
        {...field}
        rows={3}
        className={classNames(
          'w-full pt-6 scroll-mx-0.5 resize-none bg-bluenav text-white text-md border rounded-2xl',
          'focus:border-blue-500 focus:outline-none text-white',
          { 'text-disabledMirror': disabled },
          `${
            meta.touched && meta.error
              ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
              : 'focus:border-ringBlue'
          }`
        )}
      />

      <label
        htmlFor={props.name}
        className="absolute left-4 top-2 text-textInput font-semibold font-primary text-xs peer-placeholder-shown:text-sm peer-focus:text-xs"
      >
        {label}
      </label>

      <ErrorMessage
        component="div"
        name={props.name}
        className="text-left text-red-400"
      />
    </div>
  )
}
export default TextareaField
