import InnerContainer from '../components/InnerContainer'
import Navbar from '../features/navbar/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from 'components/Footer'

export default function User({ children }) {
  return (
    <div className="bg-appBg bg-no-repeat bg-center bg-cover min-h-screen min-w-screen">
      <Navbar />
      <InnerContainer>
        <Sidebar />
        {children}
      </InnerContainer>
      <Footer />
    </div>
  )
}
