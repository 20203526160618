import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'AssetTags'>({
  endpoints: {
    tagControllerFindAllMirrorPublicLibraryTags: {
      providesTags: (result, error, arg) => [{ type: 'AssetTags' }]
    }
  }
})

export const selectMyTags =
  apiMirrorSlice.endpoints.tagControllerFindAllMirrorPublicLibraryTags.select()
