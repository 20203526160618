import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from './store'

export const assetListingsSlice = createSlice({
  name: 'assetListings',

  initialState: {
    pending: false,
    error: false,
    data: [],
    currentImage: '',
    currentlySelectedAssetListing: null,
    errorMessage: '',
    searchResults: []
  } as any,

  reducers: {
    setEnt(state, action) {
      return action.payload
    },
    setCreateImage(state, action) {
      state.currentImage = action.payload
    },
    hasUploadImage(state) {
      return typeof state.currentImage != 'undefined'
    },
    getUploadImage(state) {
      return state.currentImage
    },
    loadingAssetListings(state): void {
      state.pending = true
      state.error = false
    },
    assetListingsLoadComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = action.payload
    },
    loadingAssetListingsError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    creatingAssetListing(state): void {
      state.pending = true
      state.error = false
    },
    assetListingCreationCompleted(state, action): void {
      state.pending = true
      state.error = false
      state.data.push(action.payload)
    },
    creatingAssetListingError(state, action): void {
      state.pending = true
      state.error = false
    },

    loadingAssetListingById(state): void {
      state.pending = true
      state.error = false
    },
    assetListingLoadByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAssetListing = action.payload
    },
    loadingAssetListingByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    updateAssetListingById(state): void {
      state.pending = true
      state.error = false
    },
    assetListingUpdateByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAssetListing = null
      state.data = state.data.filter(
        (assetListing) => assetListing._id != action.payload._id
      )
      state.data.push(action.payload)
    },
    updateAssetListingByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    deleteAssetListingById(state): void {
      state.pending = true
      state.error = false
    },
    assetListingDeleteByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = state.data.filter(
        (assetListing) => assetListing._id != action.payload._id
      )
    },
    deleteAssetListingByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    searchAssetListings(state): void {
      state.pending = true
      state.error = false
    },
    searchAssetListingsComplete(state, action): void {
      state.pending = false
      state.error = false
      state.searchResults = action.payload
    },
    searchAssetListingsError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    addAssetListingToStore(state, action): void {
      state.data.push(action.payload)
    },
    getMyAssetListingsFromStore(state, action) {
      return { ...state, ...action }
    },
    setSelectedAssetListing(state, action): void {
      state.currentlySelectedAssetListing = action.payload
    },
    deleteAssetListing(state, action): void {
      state.data = state.data.filter(
        (assetListing) => assetListing._id != action.payload
      )
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.assetListings
      }
    }
  }
})

export const selectAssetsListing = (state: AppState) => state.assetListings
