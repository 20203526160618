import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import { useAppDispatch } from '../../store/store'
import {
  identifyUserUidEmailPostHog,
  identifyUserUidOnlyPostHog
} from '../analytics/posthog'

import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import useAssets from 'features/assets/useAssets'
import useSpaces from 'features/spaces/useSpace'
import useDiscover from 'features/discover/useDiscover'

/** Loads authorized user and their collections into state */
export function useInitUser() {
  const dispatch = useAppDispatch()
  const { queryOptions: assetOptions } = useAssets()
  const { queryOptions: spacesOptions } = useSpaces()
  const { queryOptions: discoverOptions } = useDiscover()

  useEffect(() => {
    dispatch(
      apiMirrorSlice.endpoints.tagControllerFindAllMirrorPublicLibraryTags.initiate()
    )
    dispatch(apiMirrorSlice.endpoints.tagControllerFindAllThemeTags.initiate())
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        // identify the user to analytics
        if (user?.email) {
          identifyUserUidEmailPostHog(user?.uid, user?.email)
        } else {
          identifyUserUidOnlyPostHog(user?.uid)
        }

        dispatch(
          apiMirrorSlice.endpoints.userControllerGetCurrentUser.initiate()
        )
        dispatch(
          apiMirrorSlice.endpoints.assetControllerGetPaginatedMyAssetsV2.initiate(
            assetOptions as any
          )
        )
        // Replaced with new paginated spaces V3
        // dispatch(
        //   apiMirrorSlice.endpoints.spaceControllerFindAllForUser.initiate()
        // )
        dispatch(apiMirrorSlice.endpoints.userControllerGetMyFriends.initiate())

        dispatch(
          apiMirrorSlice.endpoints.spaceControllerFindAllForMeWhereOwnerPaginatedV3.initiate(
            spacesOptions as any
          )
        )
        dispatch(
          apiMirrorSlice.endpoints.spaceControllerFindDiscoverSpacesForUserPaginatedV3.initiate(
            discoverOptions as any
          )
        )
        // Feature flag; not ready yet
        // dispatch(
        //   apiMirrorSlice.endpoints.userGroupControllerGetAllGroupsForMe.initiate()
        // )
        dispatch(apiMirrorSlice.endpoints.userControllerGetUserCart.initiate())
        dispatch(
          apiMirrorSlice.endpoints.spaceControllerFindSpaceTemplates.initiate()
        )
      } else {
        dispatch(apiMirrorSlice.util.resetApiState())
      }
    })
  }, [dispatch])
}
