/** Used in dynamic component mapping - Every ModalComponent name must be defined here */
export enum ModalContent {
  ChangeEmailForm = 'ChangeEmailForm',
  ChangePasswordForm = 'ChangePasswordForm',
  CreateAssetForm = 'CreateAssetForm',
  CreateGroupForm = 'CreateGroupForm',
  CreateListingForm = 'CreateListingForm',
  CreateSpaceForm = 'CreateSpaceForm',
  CreateUserFeedbackItemForm = 'CreateUserFeedbackItemForm',
  DeleteUserFeedbackItem = 'DeleteUserFeedbackItem',
  EditUserFeedbackForm = 'EditUserFeedbackForm',
  DeleteUserFeedbackCommentForm = 'DeleteUserFeedbackCommentForm',
  DownloadForm = 'DownloadForm',
  EditAssetForm = 'EditAssetForm',
  EditGroupForm = 'EditGroupForm',
  ForgotPasswordForm = 'ForgotPasswordForm',
  SignUpModalContent = 'SignUpModalContent',
  SignInModalContent = 'SignInModalContent',
  SubmitUserAccessKeyForm = 'SubmitUserAccessKeyForm',
  Terms = 'Terms',
  ViewAsset = 'ViewAsset',
  Confirmation = 'Confirmation',
  InfoModal = 'InfoModal',
  AssetPurchaseOption = 'AssetPurchaseOption',
  SubConfirmation = 'SubConfirmation'
}
