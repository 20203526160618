import { FC } from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { Button } from '../../components/Button'
import { Form, Formik } from 'formik'

import { useModal } from '../modal/useModal'
import InputField from '../../components/InputField'
import TextareaField from '../../components/TextareaField'
import CheckboxField from '../../components/CheckboxField'
import SelectField from '../../components/SelectField'
import FormHeaderSection from '../../components/FormHeaderSection'
import { useIsAdminOnAdminPage } from '../../hooks/admin'
import { userAccessKeySchema } from './userFeedbackItem.validationSchema'
import { useUserControllerSubmitUserAccessKeyMutation } from '../api/apiMirrorSlice.generated'

interface SubmitUserAccessKeyValues {
  key: string
}

const CreateUserFeedbackItemForm: FC = () => {
  const [submitUserAccessKey, { isLoading }] =
    useUserControllerSubmitUserAccessKeyMutation()

  const { dismissModal } = useModal()
  const isAdminOnAdminPage = useIsAdminOnAdminPage()

  const initialValues: SubmitUserAccessKeyValues = {
    key: ''
  }

  const onSubmit = async (values) => {
    await submitUserAccessKey({
      submitUserAccessKeyDto: values.key
    })

    dismissModal()
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Submit Alpha Key</Heading>
        <Paragraph>
          Interested in a key? Skip the line by joining our subreddit.
          {/* <Link href="https://www.reddit.com/r/themirrorspace/comments/zrcs9p/early_access_skiptheline_by_joining_our_subreddit/">here</Link>. */}
        </Paragraph>
      </FormHeaderSection>

      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={userAccessKeySchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField
              type="text"
              name="key"
              label="Key"
              disabled={isLoading}
            />

            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
              Submit Alpha Key
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default CreateUserFeedbackItemForm
