import { NotificationType, useNotification } from 'features/notification'
import {
  useStripeControllerCreateSubscriptionMutation,
  useStripeControllerDeleteSubscriptionMutation
} from 'features/api/apiMirrorSlice.generated'

export const useSubscription = () => {
  const { notify } = useNotification()

  const [
    createSubscription,
    { data: createSubscriptionData, isLoading: createSubscriptionLoading }
  ] = useStripeControllerCreateSubscriptionMutation()

  const [cancelSubscription, { isLoading: cancelSubscriptionLoading }] =
    useStripeControllerDeleteSubscriptionMutation()

  const handleSubscribe = async (subscriptionType: string) => {
    try {
      const createSubscriptionDto: any = {
        subscriptionType: subscriptionType
      }
      const res = await createSubscription({ createSubscriptionDto }).unwrap()
      window.location.href = res?.url
    } catch (error: any) {
      if (error?.data) {
        notify(error?.data?.message, NotificationType.ERROR)
      } else {
        notify(
          'Error creating subscription. Please try again or reach out to us on Discord',
          NotificationType.ERROR
        )
      }
    }
  }

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription().unwrap()
    } catch (error: any) {
      if (error?.data) {
        notify(error?.data?.message, NotificationType.ERROR)
      } else {
        notify(
          'Error switching the subscription. Please try again or reach out to us on Discord',
          NotificationType.ERROR
        )
      }
    }
  }

  return {
    handleSubscribe,
    createSubscriptionData,
    createSubscriptionLoading,
    handleCancelSubscription,
    cancelSubscriptionLoading
  }
}

export default useSubscription
