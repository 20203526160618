import * as Yup from 'yup'
import { ACCOUNT_ERROR } from './accountError.enum'

export const changeEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(ACCOUNT_ERROR.EMAIL)
    .required(ACCOUNT_ERROR.EMAIL)
    .max(60, ACCOUNT_ERROR.EMAIL_MAX_LENGTH),
  password: Yup.string()
    .min(6, ACCOUNT_ERROR.PASSWORD_MIN)
    .required(ACCOUNT_ERROR.PASSWORD)
})
