import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { NotificationType, useNotification } from '../notification'
import { useModal } from '../modal/useModal'
import * as Sentry from '@sentry/nextjs'
interface ChangePasswordFormData {
  currentPassword: string
  newPassword: string
}

export const useChangePassword = () => {
  const { notify } = useNotification()
  const { dismissModal } = useModal()

  /** Updates user's password through firebase */
  const updatePassword = async ({
    currentPassword,
    newPassword
  }: ChangePasswordFormData) => {
    const user = firebase.auth().currentUser

    if (!user) {
      return
    }

    try {
      /** Verify user password through firebase */
      const currentEmail = getAuth().currentUser?.email as string
      const credentials = firebase.auth.EmailAuthProvider.credential(
        currentEmail,
        currentPassword
      )
      await user.reauthenticateWithCredential(credentials)

      /** On successful verification, update user password in firebase */
      await user.updatePassword(newPassword)

      notify('Password updated!', NotificationType.SUCCESS)
      dismissModal()
    } catch (error: any) {
      Sentry.captureException(error)
      notify(error.message, NotificationType.WARNING)
    }
  }

  return { updatePassword }
}
