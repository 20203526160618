import { ChangeEventHandler } from 'react'
import { FormikProps } from 'formik'

interface TextAreaType {
  help?: string
  label?: string
  name?: string
  onInput?: React.FormEventHandler<HTMLTextAreaElement>
  onChage?: ChangeEventHandler<HTMLTextAreaElement>
  value?: string
  field?: FormikProps<any>
  form?: FormikProps<any>
}
export default function TextArea({
  help,
  label,
  name,
  onInput,
  onChage,
  value,
  field,
  form,
  ...props
}: TextAreaType) {
  return (
    <>
      <div className="relative snap-x">
        <textarea
          {...field}
          id={name}
          name={name}
          rows={4}
          className="w-full focus:outline-none pt-6 scroll-mx-0.5 resize-none bg-bluenav text-white text-sm border rounded-2xl focus:border-blue-500"
          onInput={onInput}
          value={value}
          onChange={onChage}
          {...props}
        />
        <label
          htmlFor={name}
          className="absolute left-4 top-2 text-textInput font-semibold font-primary text-xs peer-placeholder-shown:text-sm peer-focus:text-xs"
        >
          {label}
        </label>
        {help && <p className="mt-1 text-sm text-gray-500">{help}</p>}
      </div>
    </>
  )
}
