import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useField } from 'formik'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

export interface SelectOption<T, P> {
  value: T
  label: P
  image?: string
}

interface Props {
  name: string
  label: string
  options?: SelectOption<any, string>[]
  disabled?: boolean
}

const SelectField: FC<Props> = ({ label, name, disabled, options = [] }) => {
  const [field, meta] = useField(name)

  const onChange = (value: string) => {
    field.onChange({ target: { value, name } })
  }

  return (
    <Listbox value={field.value.value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={`relative w-full py-2 pl-3 pr-10 text-left bg-bluenav border border-borderGray pt-5 rounded-2xl shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
              field?.value?.image
                ? 'h-[5rem] flex items-center gap-2'
                : 'h-[3.125rem] block'
            }`}
          >
            {field.value?.image && (
              <img
                className="w-12 h-8 object-cover rounded-md mt-1"
                src={field.value?.image}
                alt={field.value.label}
              />
            )}
            <Listbox.Label className="absolute text-textInput top-1 text-xs peer-placeholder-shown:text-sm peer-focus:text-xs">
              {label}
            </Listbox.Label>
            <span
              className={`block truncate ${
                disabled ? 'text-disabledMirror' : 'text-white'
              }`}
            >
              {field.value.label || ''}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-bluenav rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((opt) => (
                <Listbox.Option
                  key={opt.label}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-blue-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={opt}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center cursor-pointer">
                        {opt?.image && (
                          <img
                            className="w-16 h-12 object-cover rounded-md mr-2"
                            src={opt?.image}
                            alt={opt.label}
                          />
                        )}
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate text-white'
                          )}
                        >
                          {opt.label}
                        </span>
                      </div>

                      {selected ?? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
export default SelectField
