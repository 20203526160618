import { FormikProps } from 'formik'
import { FC, useState } from 'react'
import Thumbnail from 'components/images/Thumbnail'
import UploadFile from './UploadFile'

export type FileLoaded = {
  loaded: boolean
  working: boolean
  file: File | null
}

export type UploadFileWithPreviewProps = {
  field: FormikProps<any>
  form: FormikProps<any>
}

export const UploadFileWithPreview: FC<UploadFileWithPreviewProps> = ({
  field,
  form: { touched, errors, setFieldValue }
}) => {
  const [fileLoaded, setFileLoaded] = useState<FileLoaded>({
    loaded: false,
    working: false,
    file: null
  })

  const selectFile = (file: File) => {
    if (file) {
      setFileLoaded({
        loaded: true,
        working: false,
        file
      })
    }
  }

  return (
    <>
      <UploadFile
        onSelected={(event) => {
          setFieldValue('file-upload', event.currentTarget.files[0])
          selectFile(event.currentTarget.files[0])
        }}
        field={field}
        value={undefined}
      />
      {touched['file-upload'] && errors['file-upload'] && (
        <div className="grid text-red-400 sm:col-span-6 justify-items-start">
          {errors['file-upload']}
        </div>
      )}
      <Thumbnail fileLoaded={fileLoaded} />
    </>
  )
}
