import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { useSignInUser } from './useSignInUser'
import { signInSchema } from './signIn.validationSchema'
import { Button } from '../../components/Button'
import InputField from '../../components/InputField'

export interface SignInValues {
  email: string
  password: string
}

const SignInForm: FC = () => {
  const { signInUser, isLoading } = useSignInUser()

  const initialValues: SignInValues = {
    email: '',
    password: ''
  }

  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={signInUser}
    >
      <Form>
        <div className="grid grid-cols-1 gap-y-3">
          <InputField
            type="email"
            label="Email"
            name="email"
            disabled={isLoading}
          />

          <InputField
            type="password"
            label="Password"
            name="password"
            disabled={isLoading}
          />

          <Button
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
            className="w-full mt-1"
          >
            Sign In
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export default SignInForm
