import React, { ReactNode } from 'react'
import Loader from './Loader'
import cn from 'classnames'

type Props = {
  children?: ReactNode
  className?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset' | undefined
  isLoading?: boolean
  icon?: boolean
}

export const Button: React.FC<Props> = ({
  disabled = false,
  children,
  onClick,
  type = 'button' as 'button',
  className = '',
  isLoading = false,
  icon = false
}) => {
  return (
    <button
      type={type}
      className={`
      ${cn(
        'flex justify-center max-h-[3.125rem] items-center whitespace-nowrap p-3 bg-blueMirror rounded-xl font-primary font-semibold border border-transparent text-white shadow-[0_2px_40px_0px_rgba(57,121,255,0.4)] ',
        `${icon ? 'min-w-fit' : 'min-w-[6rem]'}`,
        'mobile:text-xs',
        className
      )}
      ${
        disabled
          ? 'border border-transparent text-base font-medium rounded-xl text-indigo-700 bg-indigo-100 cursor-not-allowed'
          : 'hover:bg-blue-700 hover:ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 '
      }
    `}
      onClick={onClick}
      disabled={disabled}
    >
      {children} {isLoading && <Loader className="ml-2" />}
    </button>
  )
}
