import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Users'>({
  endpoints: {
    userControllerGetCurrentUser: {
      providesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    userControllerUpdateAvatar: {
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    authControllerDeleteAccount: {
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    userControllerUpdateTermsAgreedTo: {
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    userControllerSubmitUserAccessKey: {
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    userControllerUpdateProfile: {
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedUser } = await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'userControllerGetCurrentUser',
              undefined,
              (currentUser) => {
                Object.assign(currentUser, updatedUser)
              }
            )
          )
        } catch (error: any) {
          Sentry.captureException(error)
          // TODO add error handling
          console.error(error)
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    userControllerAddUserCartItemToUserCart: {
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedUser } = await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'userControllerGetCurrentUser',
              undefined,
              (currentUser) => {
                Object.assign(currentUser, updatedUser)
              }
            )
          )
        } catch (error: any) {
          Sentry.captureException(error)
          // TODO add error handling
          console.error(error)
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    },
    stripeControllerDeleteSubscription: {
      invalidatesTags: (result, error, arg) => [{ type: 'Users' }]
    }
  }
})

export const selectMyUser =
  apiMirrorSlice.endpoints.userControllerGetCurrentUser.select()
