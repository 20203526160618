export enum ACCOUNT_ERROR {
  EMAIL = 'Please enter a valid email',
  EMAIL_MAX_LENGTH = 'Please limit your email to 60 characters',
  PASSWORD = 'Please enter a password',
  CONFIRM_PASSWORD = 'Passwords must match',
  PASSWORD_MIN = 'Password must be at least 6 characters',
  DISPLAY_NAME = 'Please enter a display name',
  DISPLAY_NAME_MAX_LENGTH = 'Please limit your display name to 40 characters',
  DISPLAY_NAME_MIN_LENGTH = 'Your display Name requires minimum 3 characters',
  PUBLIC_BIO_MAX = 'Public bio cannot exceed 180 characters',
  NO_WHITESPACE = 'Whitespace is not allowed'
}
