import * as Yup from 'yup'
import { LOGIN_ERROR } from './loginError.enum'

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .max(60, LOGIN_ERROR.EMAIL_MAX_LENGTH)
    .email(LOGIN_ERROR.EMAIL_VALID)
    .required(LOGIN_ERROR.EMAIL),
  password: Yup.string()
    .min(6, LOGIN_ERROR.PASSWORD_MIN)
    .required(LOGIN_ERROR.PASSWORD),
  confirmPassword: Yup.string()
    .min(6, LOGIN_ERROR.PASSWORD_MIN)
    .required(LOGIN_ERROR.PASSWORD_CONFIRM)
    .oneOf([Yup.ref('password'), null], LOGIN_ERROR.PASSWORD_CONFIRM),
  displayName: Yup.string()
    .required(LOGIN_ERROR.DISPLAY_NAME)
    .max(40, LOGIN_ERROR.DISPLAY_NAME_MAX_LENGTH)
    .min(3, LOGIN_ERROR.DISPLAY_NAME_MIN_LENGTH),
  // Removing because we have alpha agreement instead for now
  termsAgreedtoGeneralTOSandPP: Yup.boolean().oneOf(
    [true],
    LOGIN_ERROR.TERMS_OF_SERVICE
  )
})
