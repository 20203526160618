import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'PlaySpaces'>({
  endpoints: {
    spaceControllerGetPublishedSpacesV2: {
      providesTags: (result, error, arg) => [{ type: 'PlaySpaces' }]
    }
  }
})

export const selectMyPlayList = (options: any) =>
  apiMirrorSlice.endpoints.spaceControllerGetPublishedSpacesV2.select(
    options as any
  )
