import React, { FC } from 'react'
import Heading from '../Heading'
import Paragraph from '../Paragraph'
import TextArea from '../TextArea'
import { Button } from '../Button'
import { Formik, Form, Field } from 'formik'
import { groupSchema } from './group.validationSchema'
import { NotificationType, useNotification } from 'features/notification'
import Loader from 'components/Loader'
import { Image } from 'components/Image'
import {
  UpdateUserGroupDto,
  UserGroupApiResponse,
  useUserGroupControllerUpdateMutation
} from '../../features/api/apiMirrorSlice.generated'
import InputField from '../InputField'

type EditGroupProps = {
  group: UserGroupApiResponse
}

/** TODO
 *  Not being used currently - need to re-enable editing of groups after groups layouts finished
 */
const EditGroupForm: FC<EditGroupProps> = ({ group }) => {
  const [updateGroupById, { isLoading }] =
    useUserGroupControllerUpdateMutation()
  const { notify } = useNotification()

  const onSubmit = async (values) => {
    const updateUserGroupDto = {
      name: values.name,
      publicDescription: values.publicDescription,
      file: values['file-upload'],
      public: values.public,
      websiteUrl: values.websiteUrl,
      discordUrl: values.discordUrl
    } as UpdateUserGroupDto // shape needs to be updated on mirror-server for codegen

    try {
      await updateGroupById({ id: group._id, updateUserGroupDto }).unwrap()
      notify('Group updated!', NotificationType.SUCCESS)
    } catch {
      notify('Group failed to update!', NotificationType.WARNING)
    }
  }

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        name: group?.name,
        publicDescription: group?.publicDescription,
        'file-upload': group?.image,
        public: group?.public,
        websiteUrl: group?.websiteUrl,
        discordUrl: group?.discordUrl
      }}
      validationSchema={groupSchema}
      onSubmit={onSubmit}
    >
      <div>
        <div>
          <div className="grid justify-items-center mb-[1.75rem]">
            <Heading size="2">Edit your group</Heading>
            <Paragraph>Update information about your group below!</Paragraph>
          </div>
          <Form>
            <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 ">
              <div className="sm:col-span-6">
                <InputField
                  type="text"
                  label="Name"
                  name="name"
                  disabled={isLoading}
                />
              </div>

              <div className="sm:col-span-6">
                <Field name="publicDescription">
                  {({ field }) => {
                    return (
                      <>
                        <TextArea
                          label="Description"
                          disabled={isLoading}
                          {...field}
                        />
                      </>
                    )
                  }}
                </Field>
              </div>

              <div className="relative sm:col-span-6">
                <label
                  htmlFor="Asset"
                  className="absolute left-3 top-1 text-textInput text-xs peer-placeholder-shown:text-sm peer-focus:text-xs"
                >
                  Public
                </label>
                <Field
                  className="block w-full bg-bluenav text-white sm:text-sm':'block pt-6 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded"
                  as="select"
                  id="asset"
                  disabled={isLoading}
                  name="public"
                >
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </Field>
              </div>

              <div className="sm:col-span-6">
                <InputField
                  type="text"
                  label="Website Url"
                  name="websiteUrl"
                  disabled={isLoading}
                />
              </div>

              <div className="sm:col-span-6">
                <InputField
                  type="text"
                  label="Discord Url"
                  name="discordUrl"
                  disabled={isLoading}
                />
              </div>

              <div className="sm:col-span-6">
                <Image
                  url={group?.image}
                  fallbackUrl={'images/placeholder_image.png'}
                  className="w-48 h-48 object-cover rounded-lg my-3 mx-auto"
                  alt="Asset Image"
                />
              </div>

              <div className="sm:col-span-6 flex  items-center justify-evenly">
                <Button type="submit" disabled={isLoading}>
                  Save {isLoading && <Loader className="ml-2" />}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  )
}

export default EditGroupForm
