import { FC, ButtonHTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
}

export const ButtonTransparent: FC<Props> = ({
  children,
  type = 'button' as 'button',
  className = '',
  ...props
}) => {
  return (
    <button
      type={type}
      className={classNames(
        className,
        'text-center font-primary font-semibold',
        'text-buttonLink hover:text-blueMirror hover:ease-in duration-100'
      )}
      {...props}
    >
      {children}
    </button>
  )
}
