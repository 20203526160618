import * as Yup from 'yup'
import { SPACE_ERROR } from './spaceError.enum'

export const spaceSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, SPACE_ERROR.NAME_MIN)
    .max(50, SPACE_ERROR.NAME_MAX)
    .required(`${SPACE_ERROR.NAME_REQUIRED}`)
  // not ready yet; needs TagsV2 implementation 2023-02-19 02:17:06
  // genres: Yup.object().shape({
  //   tags: Yup.array().max(10, SPACE_ERROR.TAGS_MAX)
  // })
})
