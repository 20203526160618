import { Button } from 'components/Button'
import Paragraph from 'components/Paragraph'
import { ModalContent } from 'features/modal/modalContent.enum'
import { useModal } from 'features/modal/useModal'
import { NotificationType, useNotification } from 'features/notification'
import usePayments from 'features/payments/usePayments'
import {
  DELETE,
  UPDATE,
  assetSaleType,
  licenseType,
  type
} from 'features/profile/constants'
import { selectMyUser } from 'features/users/usersSlice'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useAppSelector } from 'store/store'

const AssetPriceSelector = ({
  asset,
  setAsset,
  priceOptions,
  assetPrice,
  setAssetPrice,
  isAssetWithDonation,
  setIsAssetWithDonation,
  handleChange
}) => {
  const { data: user } = useAppSelector(selectMyUser)

  const { stripeInfo, stripeInfoLoading } = usePayments()

  const { charges_enabled, details_submitted, payouts_enabled } =
    (!stripeInfoLoading && (stripeInfo as any)) || {}

  const router = useRouter()

  const { showModalWith } = useModal()

  const { notify } = useNotification()

  const handleClick = async (actionType) => {
    try {
      let addAssetPurchaseOptionDto
      if (priceOptions.paid) {
        addAssetPurchaseOptionDto = {
          enabled: true,
          currency: 'usd',
          licenseType: licenseType.STANDARD_MIRROR_LICENSE,
          price: assetPrice?.price,
          type: type.ONE_TIME
        }
      } else if (priceOptions.donation && isAssetWithDonation.price > 0) {
        addAssetPurchaseOptionDto = {
          enabled: true,
          currency: 'usd',
          licenseType: licenseType.STANDARD_MIRROR_LICENSE,
          price: isAssetWithDonation.price,
          type: type.ONE_TIME_OPTIONAL_DONATION
        }
      }

      showModalWith({
        name: ModalContent.AssetPurchaseOption,
        props: {
          asset,
          setAsset,
          priceOptions,
          assetPrice,
          setAssetPrice,
          isAssetWithDonation,
          addAssetPurchaseOptionDto,
          actionType
        }
      })
    } catch (error) {
      notify('Something went wrong!', NotificationType.ERROR)
    }
  }

  const handleFocus = () => {
    if (assetPrice.enabled && assetPrice.price > 0) {
      notify(
        'Please remove the existing price before editing.',
        NotificationType.WARNING
      )
    }
    if (isAssetWithDonation.enabled && isAssetWithDonation.price > 0) {
      notify(
        'Please remove the existing donation amount before editing.',
        NotificationType.WARNING
      )
    }
  }

  return (
    <>
      <div>
        <div className="flex gap-3 items-start">
          <img
            className={`${
              priceOptions.paid ? 'opacity-100' : 'opacity-70'
            } px-2`}
            src="/images/dollarIcon.png"
            alt="Price"
          />
          <ul>
            <li className="flex gap-1 mb-2 items-center">
              <input
                name="price"
                id="paid"
                type="radio"
                checked={priceOptions.paid}
                onChange={() => handleChange(assetSaleType.PAID)}
                className={`w-5 h-5 mr-2 text-blue-600 bg-transparent rounded-full  
                         border-inactive focus:ring-blue-500 cursor-pointer`}
              />
              <label htmlFor="paid" className="cursor-pointer">
                <Paragraph>Paid</Paragraph>
              </label>
            </li>
            <li className="flex gap-1 mb-2 items-center">
              <input
                name="price"
                id="donation"
                type="radio"
                checked={priceOptions.donation}
                onChange={() => handleChange(assetSaleType.DONATION)}
                className={`w-5 h-5 mr-2 text-blue-600 bg-transparent rounded-full  
                         border-inactive focus:ring-blue-500 cursor-pointer`}
              />
              <label htmlFor="donation" className="cursor-pointer">
                <Paragraph>Free, Optional Donation</Paragraph>
              </label>
            </li>
            <li className="flex gap-1 mb-2 items-center">
              <input
                name="price"
                id="free"
                type="radio"
                checked={priceOptions.free}
                onChange={() => handleChange(assetSaleType.FREE)}
                className={`w-5 h-5 mr-2 text-blue-600 bg-transparent rounded-full  
                         border-inactive focus:ring-blue-500 cursor-pointer`}
              />
              <label htmlFor="free" className="cursor-pointer">
                <Paragraph> Free </Paragraph>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full">
        {priceOptions.paid && (
          <div className="flex flex-col gap-3">
            {!user?.stripeAccountId ||
            !charges_enabled ||
            !details_submitted ||
            !payouts_enabled ? (
              <p className="text-white font-primary italic">
                {' '}
                To accept payments, complete Stripe setup
              </p>
            ) : (
              <div className="border border-gray-700 h-[3.75rem] rounded-xl focus:outline-none px-4 bg-bluenav relative">
                <label
                  className="text-inactive text-xs inline-block mt-1 font-semibold font-primary"
                  htmlFor="name"
                >
                  Price
                </label>
                <span className="text-white absolute left-3 top-7 font-semibold font-primary">
                  $
                </span>
                <input
                  type="text"
                  value={assetPrice?.price}
                  pattern="[0-9]+([.,][0-9]+)?"
                  inputMode="numeric"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^\d.]/g, '')
                    !assetPrice.enabled && setAssetPrice({ price: value })
                  }}
                  onFocus={handleFocus}
                  min={1}
                  className={`py-1
                         block border-0 w-full px-3 text-white text-base font-semibold font-primary bg-transparent border-inactive focus:outline-none focus:ring-0 focus:border-transparent`}
                />
              </div>
            )}
            {assetPrice.enabled && assetPrice.price > 0 ? (
              <Button
                onClick={() => handleClick(DELETE)}
                className="bg-orangeMirror shadow-none hover:bg-orangeMirror"
              >
                Remove from Sale
              </Button>
            ) : !user?.stripeAccountId ||
              !charges_enabled ||
              !details_submitted ||
              !payouts_enabled ? (
              <Button className="w-full">
                <Link href="/account?tabs=payments">
                  <a> Complete Stripe Setup </a>
                </Link>
              </Button>
            ) : (
              <Button
                disabled={assetPrice?.price <= 0 || !assetPrice?.price}
                onClick={() => handleClick(UPDATE)}
              >
                Set for Sale
              </Button>
            )}
          </div>
        )}

        {priceOptions.free && asset?.purchaseOptions.length === 0 && (
          <div className="w-full">
            {asset?.mirrorPublicLibrary ? (
              <Button
                className="w-full bg-orangeMirror shadow-none hover:bg-orangeMirror"
                onClick={() => handleClick(DELETE)}
              >
                {' '}
                Remove{' '}
              </Button>
            ) : (
              <Button className="w-full" onClick={() => handleClick(UPDATE)}>
                Make Available for Download
              </Button>
            )}
          </div>
        )}

        {priceOptions.donation && (
          <div className="flex flex-col gap-3">
            {!user?.stripeAccountId ||
            !charges_enabled ||
            !details_submitted ||
            !payouts_enabled ? (
              <p className="text-white font-primary italic">
                {' '}
                To accept payments, complete Stripe setup
              </p>
            ) : (
              <div className="border border-gray-700 h-[3.75rem] rounded-xl focus:outline-none px-4 bg-bluenav relative">
                <label
                  className="text-inactive text-xs inline-block mt-1 font-semibold font-primary"
                  htmlFor="name"
                >
                  Suggested Donation
                </label>
                <span className="text-white absolute left-3 top-7 font-semibold font-primary">
                  $
                </span>
                <input
                  type="text"
                  value={isAssetWithDonation?.price}
                  pattern="[0-9]+([.,][0-9]+)?"
                  inputMode="numeric"
                  onFocus={handleFocus}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^\d.]/g, '')
                    setIsAssetWithDonation({ price: value })
                  }}
                  min={1}
                  className={`py-1
                     block border-0 w-full px-3 text-white text-base font-semibold font-primary bg-transparent border-inactive focus:outline-none focus:ring-0 focus:border-transparent`}
                />
              </div>
            )}
            {isAssetWithDonation.enabled && isAssetWithDonation.price > 0 ? (
              <Button
                onClick={() => handleClick(DELETE)}
                className="bg-orangeMirror shadow-none hover:bg-orangeMirror"
              >
                Remove
              </Button>
            ) : !user?.stripeAccountId ||
              !charges_enabled ||
              !details_submitted ||
              !payouts_enabled ? (
              <Button>
                <Link href="/account?tab=payments">Complete Stripe Setup</Link>
              </Button>
            ) : (
              <Button
                disabled={
                  isAssetWithDonation?.price <= 0 || !isAssetWithDonation?.price
                }
                onClick={() => handleClick(UPDATE)}
                className="w-full"
              >
                {' '}
                Make Available for Download
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default AssetPriceSelector
