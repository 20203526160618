import { FC } from 'react'

const FormHeaderSection: FC = ({ children }) => {
  return (
    <div className="flex flex-col gap-2 items-center text-center mb-6">
      {children}
    </div>
  )
}

export default FormHeaderSection
