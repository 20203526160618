import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

export const assetOffersSlice = createSlice({
  name: 'assetsOffers',

  initialState: {
    pending: false,
    error: false,
    data: [],
    currentImage: '',
    currentlySelectedAssetOffers: null,
    errorMessage: '',
    searchResults: []
  } as any,

  reducers: {
    setEnt(state, action) {
      return action.payload
    },
    setCreateImage(state, action) {
      state.currentImage = action.payload
    },
    hasUploadImage(state) {
      return typeof state.currentImage != 'undefined'
    },
    getUploadImage(state) {
      return state.currentImage
    },
    loadingAssetOffers(state): void {
      state.pending = true
      state.error = false
    },
    assetOffersLoadComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = action.payload
    },
    loadingAssetOffersError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    creatingAssetOffers(state): void {
      state.pending = true
      state.error = false
    },
    assetOffersCreationCompleted(state, action): void {
      state.pending = true
      state.error = false
      state.data.push(action.payload)
    },
    creatingAssetOffersError(state, action): void {
      state.pending = true
      state.error = false
    },

    loadingAssetOffersById(state): void {
      state.pending = true
      state.error = false
    },
    loadingAssetOffersAll(state): void {
      state.pending = true
      state.error = false
    },
    assetOffersLoadByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAssetOffers = action.payload
    },
    assetOffersAllLoadByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAssetOffers = action.payload
      state.data = action.payload
    },
    loadingAssetOffersByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    updateAssetOffersById(state): void {
      state.pending = true
      state.error = false
    },
    assetOffersUpdateByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAssetOffers = null
      state.data = state.data.filter(
        (assetOffers) => assetOffers._id != action.payload._id
      )
      state.data.push(action.payload)
    },
    updateAssetOffersByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    deleteAssetOffersById(state): void {
      state.pending = true
      state.error = false
    },
    assetOffersDeleteByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = state.data.filter(
        (assetOffers) => assetOffers._id != action.payload._id
      )
    },
    deleteAssetOffersByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    searchAssetOffers(state): void {
      state.pending = true
      state.error = false
    },
    searchAssetOffersComplete(state, action): void {
      state.pending = false
      state.error = false
      state.searchResults = action.payload
    },
    searchAssetOffersError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.message
    },

    addAssetOffersToStore(state, action): void {
      state.data.push(action.payload)
    },
    getMyAssetOffersFromStore(state, action) {
      return { ...state, ...action }
    },
    setSelectedAssetOffers(state, action): void {
      state.currentlySelectedAssetOffers = action.payload
    },
    deleteAssetOffers(state, action): void {
      state.data = state.data.filter(
        (assetOffers) => assetOffers._id != action.payload
      )
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.assetsOffers
      }
    }
  }
})
