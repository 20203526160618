import { FC } from 'react'
import { Form, Formik } from 'formik'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { Button } from '../../components/Button'
import { ButtonTransparent } from '../../components/ButtonTransparent'
import InputField from '../../components/InputField'
import {
  SpaceControllerCopyFromTemplateApiArg,
  SpaceCopyFromTemplateDto,
  useSpaceControllerCopyFromTemplateMutation,
  useSpaceControllerUpdateSpaceTagsByTypeWithRoleChecksMutation
} from '../api/apiMirrorSlice.generated'
import { NotificationType, useNotification } from '../notification'
import { useModal } from '../modal/useModal'
import { spaceSchema } from './spaces.validationSchema'
import SelectField from '../../components/SelectField'
import TextareaField from '../../components/TextareaField'
import { useAppSelector } from 'store/store'
import { selectSpaceTemplates } from './spaceTemplateSlice'

const CreateSpaceForm: FC = () => {
  const [copySpaceFromTemplate, { isLoading }] =
    useSpaceControllerCopyFromTemplateMutation()
  const [addTags] =
    useSpaceControllerUpdateSpaceTagsByTypeWithRoleChecksMutation()
  const { data: templatesData } = useAppSelector(selectSpaceTemplates) as any
  const { notify } = useNotification()
  const { dismissModal } = useModal()

  const spaceTemplates =
    templatesData &&
    templatesData?.map((template) => {
      return {
        value: template?.id,
        label: template?.name,
        image: template?.images[0]
      }
    })

  const addSpaceTags = async (desc: string, id: string) => {
    if (!isLoading) {
      const words: string[] = desc.replace('\n', ' ').split(' ')
      const spaceTags: string[] = []

      for (const word of words) {
        if (typeof word === 'string' && word.trim().startsWith('#')) {
          spaceTags.push(word.slice(1))
        }
      }

      const updateSpaceTagsDto: any = {
        spaceId: id,
        tagType: 'search',
        tags: spaceTags
      }

      await addTags({ updateSpaceTagsDto }).unwrap()
    }
  }

  const onSubmit = async (values) => {
    const spaceCopyFromTemplateDto: SpaceCopyFromTemplateDto = {
      name: values.name,
      description: values.description,
      publicBuildPermissions: 'observer'
    }

    const spaceControllerCopyFromTemplateApiArg: SpaceControllerCopyFromTemplateApiArg =
      {
        id: values?.template?.value,
        spaceCopyFromTemplateDto: spaceCopyFromTemplateDto
      }

    try {
      const res = await copySpaceFromTemplate(
        spaceControllerCopyFromTemplateApiArg
      ).unwrap()
      !isLoading && dismissModal()
      addSpaceTags(res?.description, res?._id)
      notify('Space created!', NotificationType.SUCCESS)
    } catch {
      notify('Space failed to create!', NotificationType.WARNING)
    }
  }

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        name: '',
        description: '',
        template: spaceTemplates[0]
      }}
      validationSchema={spaceSchema}
      onSubmit={onSubmit}
    >
      <div>
        <div>
          <div className="grid justify-items-center mb-[1.75rem]">
            <Heading size="2">Create Your Space</Heading>
            <Paragraph className="mt-3 text-center">
              Mirror your imagination: A space is a game, world, showcase,
              adventure, or anything you wish.
            </Paragraph>
          </div>
          <Form>
            <div className="grid grid-cols-1 max-w-[27.375rem] mt-6 gap-4">
              <InputField
                name="name"
                type="text"
                label="Name"
                disabled={isLoading}
              />

              <div className="h-[5.25rem]">
                {/* <SelectField
                  label="Public Build Permissions"
                  name="public"
                  disabled={isLoading}
                  options={publicBuildPermission}
                /> */}

                <SelectField
                  label="Template"
                  name="template"
                  disabled={isLoading}
                  options={spaceTemplates}
                />
              </div>

              <TextareaField name="description" label="Description" />

              <Paragraph className="my-3 text-center">
                Spaces are publicly viewable by default. You can change this
                after creating the Space.
              </Paragraph>

              <Paragraph className="mb-3 text-center">
                Add tags in the description to help people find your Space, such
                as #rpg, #shooter, #forest, #custom
              </Paragraph>

              <div>
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                  className="w-full"
                >
                  Create Space
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  )
}

export default CreateSpaceForm
