import { selectMyUser } from '../features/users/usersSlice'
import { useAppSelector } from '../store/store'

/**
 * Used to check 1. If the user is an admin
 */
export function useIsAdmin() {
  const { data: user } = useAppSelector(selectMyUser)
  return user?.isInternalAdmin
}
/**
 * Used to check 1. If the user is an admin and 2. If on a /admin page
 */
export function useIsAdminOnAdminPage() {
  const isAdmin = useIsAdmin()
  return isAdmin && window.location.pathname.startsWith('/admin')
}
