import { PayloadAction } from '@reduxjs/toolkit'
import { Middleware, MiddlewareAPI } from 'redux'
import { NotificationType } from '../features/notification'
import { showNotification } from './notification'
import * as Sentry from '@sentry/nextjs'

// https://redux-toolkit.js.org/api/matching-utilities#using-matchers-as-a-typescript-type-guard
function isServerFetchError(action: PayloadAction) {
  return (
    action.type === 'api-mirror/executeQuery/rejected' &&
    (action.payload as any)?.status === 'FETCH_ERROR'
  )
}
function isServer500Error(action: PayloadAction) {
  return (
    action.type === 'api-mirror/executeQuery/rejected' &&
    (action.payload as any)?.data?.statusCode >= 500
  )
}
function isServer404Error(action: PayloadAction) {
  return (
    action.type === 'api-mirror/executeQuery/rejected' &&
    (action.payload as any)?.data?.statusCode === 404
  )
}
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isServerFetchError(action)) {
      Sentry.captureException(action.payload)
      api.dispatch(
        showNotification({
          message:
            "We're sorry, the API couldn't be reached. Please try again later.",
          type: NotificationType.ERROR
        })
      )
    }
    if (isServer500Error(action)) {
      Sentry.captureException(action.payload)
      api.dispatch(
        showNotification({
          message:
            "We're sorry, our server appears to be having an issue. Please try again later.",
          type: NotificationType.ERROR
        })
      )
    }
    if (isServer404Error(action)) {
      Sentry.captureMessage(action.payload)
      api.dispatch(
        showNotification({
          message: getUserFriendlyMessageForActionError(action),
          type: NotificationType.WARNING
        })
      )
    }

    return next(action)
  }

// TODO should we lean on NestJS @ApiException for this?
function getUserFriendlyMessageForActionError(action): string {
  // Handle 404 errors
  if ((action.payload as any)?.data?.statusCode === 404) {
    // handle by entity type
    const path: string = (action.payload as any)?.data?.path
    if (path.startsWith('/user/me')) {
      return "We're sorry, we couldn't retrieve your user information"
    } else if (path.startsWith('/user-feedback')) {
      return "We couldn't retrieve that feature request"
    } else if (path.startsWith('/user')) {
      return "We couldn't retrieve that user"
    }
  }
  return ''
}
