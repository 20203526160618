import React, { FC, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import {
  AssetApiResponse,
  UpdateAssetDto,
  apiMirrorSlice,
  useAssetControllerUpdateAssetTagsByTypeWithRoleChecksMutation,
  useAssetControllerUpdateMutation
} from '../api/apiMirrorSlice.generated'
import { NotificationType, useNotification } from '../notification'
import { Image } from '../../components/Image'
import { ASSET_TYPE } from '../../enums/AssetsTypes'
import Select from 'components/Select'
import { QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline'
import { categoryOptions } from './constants'
import { useAppDispatch, useAppSelector } from 'store/store'
import ModelViewInList from 'components/ModelViewInList'
import AudioAsset from 'components/AudioAsset'
import { assetSaleType, licenseType, type } from 'features/profile/constants'
import AssetPriceSelector from 'components/AssetPriceOptions/assetPriceSelector'

type Props = {
  asset: AssetApiResponse
  closeEditForm: (id: string) => void
  isEditing: boolean
}

const EditAssetForm: FC<Props> = (props) => {
  const [asset, setAsset] = useState(props.asset)
  const { closeEditForm } = props
  const purchaseOptions: any = asset?.purchaseOptions || []

  const { viewMode } = useAppSelector((state) => state.assetsView)

  const initialState = props.asset

  const [name, setName] = useState(initialState.name)
  const [description, setDescription] = useState(initialState.description)
  const [categoryType, setCategoryType] = useState(
    categoryOptions.find((option) => option._id === initialState.assetType)
  )

  const [assetPrice, setAssetPrice] = useState(
    purchaseOptions.find((item) => item.type === type.ONE_TIME) || {}
  )

  const [isAssetWithDonation, setIsAssetWithDonation] = useState(
    (asset?.mirrorPublicLibrary &&
      purchaseOptions.find(
        (item) => item.type === type.ONE_TIME_OPTIONAL_DONATION
      )) ||
      {}
  )

  const [priceOptions, setPriceOptions] = useState({
    free:
      asset?.mirrorPublicLibrary && purchaseOptions.length === 0 ? true : false,
    donation: isAssetWithDonation.enabled ? true : false,
    paid: assetPrice.enabled && assetPrice.price > 0 ? true : false
  })

  const [
    updateAsset,
    { isLoading: updateAssetLoading, data: updatedAssetData }
  ] = useAssetControllerUpdateMutation()

  const [updateTags] =
    useAssetControllerUpdateAssetTagsByTypeWithRoleChecksMutation()

  const { notify } = useNotification()
  const dispatch = useAppDispatch()

  const addAssetTags = async (desc: string, id: string) => {
    if (desc) {
      const words: string[] = desc.replace('\n', ' ').split(' ')
      const assetTags: string[] = []

      for (const word of words) {
        if (typeof word === 'string' && word.trim().startsWith('#')) {
          assetTags.push(word.slice(1))
        }
      }

      const updateAssetTagsDto: any = {
        assetId: id,
        tagType: 'search',
        tags: assetTags
      }

      await updateTags({ updateAssetTagsDto }).unwrap()
    }
  }

  useEffect(() => {
    const updateAssetDebounced = debounce((values) => {
      const updateAssetDto: UpdateAssetDto = {
        name: values?.name,
        assetType: values?.categoryType?._id
      }
      if (values?.description !== '') {
        updateAssetDto.description = values?.description
      }
      addAssetTags(values?.description, asset?._id)
      updateAsset({ id: asset?._id, updateAssetDto })
        .unwrap()
        .catch(() => {
          notify('Asset failed to update!', NotificationType.WARNING)
        })
    }, 500)
    updateAssetDebounced({ name, categoryType, description })

    return () => {
      updateAssetDebounced.cancel()
    }
  }, [name, categoryType, description])

  let assetDisplay = <div></div>
  const assetType = asset?.assetType
  switch (assetType) {
    case ASSET_TYPE.IMAGE:
      assetDisplay = (
        <Image
          url={asset?.thumbnail}
          alt={asset?.name || ''}
          className="w-[10rem] h-[10rem] object-contain"
        />
      )
      break
    case ASSET_TYPE.MESH:
      assetDisplay = (
        <div className="w-[10rem] h-[10rem] aspect-video">
          {/^(.+\.)(gltf|glb)$/.test(asset?.currentFile) ? (
            <ModelViewInList
              key={`model_${asset?._id}`}
              url={asset?.currentFile}
            />
          ) : (
            <Image
              className="w-[10rem] h-[10rem] object-cover"
              url={asset?.thumbnail}
              alt={asset?.name}
            />
          )}
        </div>
      )
      break
    case ASSET_TYPE.MAP:
      assetDisplay = (
        <Image
          url={asset?.thumbnail}
          alt={asset?.name || ''}
          className="w-[10rem] h-[10rem] object-cover"
        />
      )
      break
    case ASSET_TYPE.AUDIO:
      assetDisplay = (
        <AudioAsset
          src={asset?.currentFile}
          className="w-[8rem] h-[8rem]"
          position="card"
          id={`audio_id_${asset?._id}`}
          key={`audio_${asset?._id}`}
        />
      )
      break
    case ASSET_TYPE.SCRIPT:
      assetDisplay = (
        <Image
          url={'/images/script.png'}
          alt={asset?.name || ''}
          className="w-[10rem] h-[10rem] object-contain mx-5"
        />
      )
      break
    case ASSET_TYPE.MATERIAL:
      assetDisplay = (
        <Image
          url={asset?.thumbnail}
          alt={asset?.name || ''}
          className="w-[5rem] h-[5rem] object-contain mx-auto"
        />
      )
      break
    default:
      assetDisplay = (
        <Image
          alt="asset not supported"
          url={process.env.NEXT_PUBLIC_IMAGE_URL_TEST}
          className="w-[10rem] h-[10rem] object-cover"
        />
      )
      break
  }

  const handleClose = () => {
    dispatch(apiMirrorSlice.util.invalidateTags(['Assets']))
    closeEditForm(asset?._id)
  }

  const handleChange = (type) => {
    const priceOptionExist: boolean =
      Object.keys(assetPrice).length > 0 ||
      Object.keys(isAssetWithDonation).length > 0 ||
      asset?.mirrorPublicLibrary
    if (priceOptionExist) {
      notify(
        `Click ${
          priceOptions.paid ? 'Remove from Sale' : 'Remove'
        } in order to make changes`,
        NotificationType.WARNING
      )
      return
    }
    if (type === assetSaleType.DONATION) {
      setPriceOptions({ free: false, paid: false, donation: true })
    } else if (type === assetSaleType.FREE) {
      setPriceOptions({ donation: false, paid: false, free: true })
    } else {
      setPriceOptions({ donation: false, free: false, paid: true })
    }
  }

  useEffect((): any => {
    return () => dispatch(apiMirrorSlice.util.invalidateTags(['Assets']))
  }, [props.isEditing])

  useEffect(() => {
    if (asset) {
      const purchaseOptions: any = asset?.purchaseOptions || []
      const updatedAssetPrice =
        purchaseOptions?.find((item) => item.type === type.ONE_TIME) || {}
      setAssetPrice(updatedAssetPrice)
      const updatedDonationPrice =
        purchaseOptions?.find(
          (item) => item.type === type.ONE_TIME_OPTIONAL_DONATION
        ) || {}
      setIsAssetWithDonation(updatedDonationPrice)
    }
  }, [asset])

  return (
    <div
      className={`${
        viewMode === 'grid' && 'grid-view'
      } w-full px-4 py-6 bg-bluedarkbg transition-all relative overflow-hidden`}
    >
      <button type="button" title="close" onClick={handleClose}>
        <XIcon className="w-4 h-4 text-inactive absolute right-5 top-5" />
      </button>
      {asset && (
        <form>
          <div className={`edit-asset-wrap my-2`}>
            <div
              className={`${
                viewMode === 'grid' ? 'mx-auto' : ''
              } sm:mx-2 my-3 sm:my-0`}
            >
              {assetDisplay}
            </div>
            <div>
              <div className="border border-gray-700 h-[4.85rem] rounded-xl focus:outline-none  px-4 bg-bluenav">
                <label
                  className="text-inactive text-xs inline-block mt-2 font-semibold"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  placeholder="Asset Name"
                  className={`
                    block border-0 w-full px-0 text-white text-base font-semibold font-primary bg-transparent
                     focus:outline-none focus:ring-0 focus:border-transparent`}
                />
              </div>
              <div className="my-3 edit-asset-type">
                <Select
                  selected={categoryType}
                  setSelected={setCategoryType}
                  label="Type"
                  disabled={false}
                  options={categoryOptions}
                />
              </div>
            </div>
            <div className="flex gap-1 flex-col">
              <div
                className={`p-2 text-left bg-bluenav border border-gray-700 rounded-xl shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              >
                <label className="text-inactive block"> Description </label>
                <textarea
                  className="text-white p-0 block bg-transparent w-full focus:outline-0 focus:border-0 border-transparent focus:border-transparent focus:ring-0"
                  rows={4}
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <span className="p-1 text-inactive">
                Add tags in the description to help people find your Asset, such
                as #rpg, #shooter, #custom
              </span>
            </div>
            <div className="flex flex-col items-start gap-3 mb-10">
              <AssetPriceSelector
                asset={asset}
                setAsset={setAsset}
                priceOptions={priceOptions}
                assetPrice={assetPrice}
                setAssetPrice={setAssetPrice}
                isAssetWithDonation={isAssetWithDonation}
                setIsAssetWithDonation={setIsAssetWithDonation}
                handleChange={handleChange}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default React.memo(EditAssetForm)
