import 'firebaseui/dist/firebaseui.css'
import Script from 'next/script'
import { NextComponentType } from 'next'
import { AppProps } from 'next/app'
import React, { FC, useEffect } from 'react'
import Empty from '../layouts/Empty'
import User from '../layouts/User'
import initAuth from '../services/initAuth'
import { useAppDispatch, useAppSelector, wrapper } from '../store/store'
import { NotificationContainer } from 'features/notification'
import '../styles/globals.scss'
import Head from 'next/head'
import { initVenlyWidget } from '../services/venly'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { useInitUser } from '../features/auth/useInitUser'
import { useRouteGuard } from '../features/auth/useRouteGuard'
import { initPostHog } from '../features/analytics/posthog'
import { enhanceEndpoints } from '../features/api/enhanceEndpoints'
import usePayments from 'features/payments/usePayments'
import { selectMyUser } from 'features/users/usersSlice'

// Initialize Firebase App
initAuth()
// Init analytics
initPostHog()
// rtk query enhancements
enhanceEndpoints()

const layouts = {
  Empty,
  User
}

type Component = NextComponentType & { Layout: string }
type AppPropsType = AppProps & { Component: Component }

const MirrorReactApp: FC<AppPropsType> = ({
  Component,
  pageProps,
  ...rest
}) => {
  initVenlyWidget()
  const dispatch = useAppDispatch()
  // Init analytics
  initPostHog()
  // Init user
  useInitUser()
  // Enforces protected routes
  useRouteGuard(pageProps)
  // Set up listeners for refetching functionality: https://redux-toolkit.js.org/rtk-query/api/setupListeners
  setupListeners(dispatch)

  const Layout = layouts[Component.Layout] || Empty

  const { createCustomerAccount } = usePayments()
  const { data: user } = useAppSelector(selectMyUser)

  useEffect(() => {
    if (user && !user?.stripeCustomerId) createCustomerAccount()
  }, [user])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {process.env.NEXT_PUBLIC_KLAVYIO_API_KEY !== undefined && (
        <Script
          src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVYIO_API_KEY}`}
        />
      )}

      <Layout>
        <NotificationContainer>
          <Component {...pageProps} />
        </NotificationContainer>
      </Layout>
    </>
  )
}

export default wrapper.withRedux(MirrorReactApp)
