import { Form, Formik } from 'formik'
import InputField from '../../components/InputField'
import { Button } from '../../components/Button'
import React from 'react'
import FormHeaderSection from '../../components/FormHeaderSection'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { useForgotPassword } from './useForgotPassword'
import { forgotPasswordSchema } from './forgotPassword.validationSchema'

export interface ForgotPasswordValues {
  email: string
}

const ForgotPasswordForm = () => {
  const { sendResetLink, isLoading } = useForgotPassword()
  const initialValues: ForgotPasswordValues = { email: '' }

  return (
    <>
      <FormHeaderSection>
        <Heading size="2">Reset Password</Heading>
        <Paragraph>
          Please enter your email and we&apos;ll send you a link to reset your
          password.
        </Paragraph>
      </FormHeaderSection>

      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={sendResetLink}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField type="text" name="email" label="Email" />
            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
              Send link
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default ForgotPasswordForm
