import { FC, HTMLProps, useState } from 'react'
import { ErrorMessage, useField } from 'formik'
import { CameraIcon } from '@heroicons/react/solid'
import { PhotographIcon } from '@heroicons/react/outline'
import { Image } from '../../components/Image'
import { useFile } from './useFile'
import classNames from 'classnames'

interface Props extends HTMLProps<HTMLInputElement> {
  name: string
}

const FileField: FC<Props> = ({ label, accept, ...props }) => {
  const [field, meta, { setValue }] = useField({ ...props, type: 'file' })
  const [preview, setPreview] = useState('')
  const { parseFileType, getFilePreview } = useFile()

  const onChange = (event) => {
    /** Necessary to extract the FileList object at the Form Values level */
    const parsedFile = parseFileType(event.target.files[0])
    setValue(parsedFile)

    if (parsedFile) {
      setPreview(getFilePreview(parsedFile))
    }
  }

  return (
    <div
      className={classNames(
        'relative rounded-xl h-[9.375rem] w-full text-white',
        {
          'border border-dashed border-2': !field.value,
          'border-disabledMirror': props.disabled
        }
      )}
    >
      <label
        htmlFor={`file-${field.name}`}
        className={classNames(
          'w-full h-full flex items-center font-primary font-semibold',
          'hover:text-blueMirror focus-within:outline-none',
          'mobile:text-sm',
          {
            'border-disabledMirror text-disabledMirror hover:text-disabledMirror cursor-not-allowed':
              props.disabled,
            'cursor-pointer': !props.disabled
          }
        )}
      >
        {field.value ? (
          <Image
            url={typeof field.value === 'string' ? field.value : preview}
            alt={field.name}
            className=" h-full w-full object-cover rounded-xl"
          />
        ) : (
          <div
            className={classNames(
              'items-center justify-center w-full h-full flex flex-col'
            )}
          >
            <PhotographIcon
              className={classNames('w-10 mx-auto text-inactive')}
            />

            {label || 'Upload a file'}

            {meta.error && (
              <ErrorMessage
                component="div"
                name={field.name}
                className="text-left text-red-400"
              />
            )}
          </div>
        )}
        <input
          id={`file-${field.name}`}
          type="file"
          {...props}
          {...field}
          onChange={onChange}
          className="sr-only"
          accept={`${accept},.jpeg,.jpg,.png,.svg`}
          /** Necessary to extract the FileList object at the Form Values level */
          value={''}
        />
        <CameraIcon
          className={classNames(
            'w-7 h-7 bg-gray-100 p-1 rounded-full absolute bottom-2 right-2 text-black',
            { ' bg-disabledMirror': props.disabled }
          )}
          aria-hidden="true"
        />
      </label>
    </div>
  )
}
export default FileField
