import React, { useMemo } from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Center, useGLTF } from '@react-three/drei'
import { Suspense, useEffect, useState } from 'react'
import { Vector3 } from 'three'
import Loader from './Loader'
import { Image } from './Image'

function ModelViewInList({ url }: { url: string }) {
  // Loading the gltf
  const { scene } = useGLTF(url)
  useGLTF.preload(url)
  const [camPos, setCamPos] = useState<Vector3>(new Vector3(0, 0, 5))
  const [canvasError, setCanvasError] = useState(false)
  const copiedScene = useMemo(() => scene.clone(), [scene])

  const boundingBox = useMemo(() => {
    const box = new THREE.Box3().setFromObject(scene)
    return box
  }, [scene])

  const modelSize = useMemo(
    () => boundingBox.getSize(new THREE.Vector3()),
    [boundingBox]
  )

  const handleCanvasError = () => {
    setCanvasError(true)
  }

  useEffect(() => {
    if (url) {
      const avg = (modelSize.x + modelSize.y + modelSize.z) / 3
      const camFov = 60
      const fov = (camFov * Math.PI) / 180
      const camDist = Math.abs((avg / 1.5) * Math.tan(fov * 2))
      const camPos = new THREE.Vector3(0, 2, camDist + 2)
      const desiredSize = 2
      let scaleFactor = desiredSize / avg
      const maxScaleFactor = 5
      scaleFactor = Math.min(scaleFactor, maxScaleFactor)
      scene.scale.set(scaleFactor, scaleFactor, scaleFactor)
      setCamPos(camPos)
    }
  }, [modelSize, url])

  if (!url) {
    return <Loader />
  }

  if (canvasError) {
    return (
      <Image
        alt="Asset can't be loaded"
        className="w-24 h-24 object-cover m-auto"
        url={process.env.NEXT_PUBLIC_IMAGE_URL_TEST}
      />
    )
  }

  function CopiedSceneRenderer({ scene }) {
    return <primitive object={scene} className="hidden" />
  }

  return (
    <div className="w-full h-full relative viewer">
      <Canvas
        onError={handleCanvasError}
        shadows // enabled shadows
        gl={{ antialias: false }} // disabled FXAA to use better anti-aliasing
        camera={{
          position: camPos,
          fov: 60,
          near: 0.75,
          far: 1000
        }}
      >
        {/* Overall brightness of the scene */}
        <ambientLight intensity={0.5} />
        {/* Emulates sunlight */}
        <directionalLight
          position={[-10, 10, 5]}
          shadow-mapSize={[256, 256]}
          shadow-bias={-0.0001}
          castShadow
        />
        {/* HDRI for realistic reflections */}
        {/* <Environment preset="city" /> commented for now, preset are not meant to be use on production due to their dependencies on CDNs and we don't have any custom files available for environment. */}
        {/* Standard Orbitcontrols of THREE.js */}
        <OrbitControls
          enableDamping
          dampingFactor={0.1}
          enablePan={false}
          enableZoom={true}
          autoRotate
          autoRotateSpeed={2}
          target={[0, 0, 0]}
        />
        <Suspense fallback={<Loader />}>
          <group>
            {/* Center positions the object at [0, 0, 0] */}
            <Center>{scene && <primitive object={scene} />}</Center>
            <Suspense fallback={<Loader />}>
              <Center>
                {scene && <CopiedSceneRenderer scene={copiedScene} />}
              </Center>
            </Suspense>
          </group>
        </Suspense>
      </Canvas>
    </div>
  )
}

export default React.memo(ModelViewInList)
