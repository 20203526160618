import { useModal } from '../modal/useModal'
import { NotificationType, useNotification } from '../notification'
import { sendPasswordResetEmail } from '@firebase/auth'
import { getAuth } from 'firebase/auth'
import { ModalContent } from '../modal/modalContent.enum'
import { ForgotPasswordValues } from './ForgotPasswordForm'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

export const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { showModalWith } = useModal()
  const { notify } = useNotification()

  useEffect(() => {
    return () => {
      setIsLoading(false)
    }
  }, [])

  const sendResetLink = async ({ email }: ForgotPasswordValues) => {
    setIsLoading(true)

    try {
      await sendPasswordResetEmail(getAuth(), email)
      notify(
        `A password reset link has been sent to ${email}`,
        NotificationType.SUCCESS
      )
      showModalWith({ name: ModalContent.SignInModalContent })
    } catch (error: any) {
      setIsLoading(false)
      notify(error.message, NotificationType.WARNING)
      Sentry.captureException(error)
    }
  }

  return { sendResetLink, isLoading }
}
