import classnames from 'classnames'
import { FormikProps } from 'formik'

interface IUploadFile {
  label?: string
  onSelected: any
  error?: Boolean
  field?: any
  form?: any
  value?: any
}

export default function UploadFile({
  label,
  onSelected,
  error,
  field,
  form,
  ...props
}: IUploadFile) {
  return (
    <>
      {label && (
        <label
          htmlFor={field?.name || 'cover-photo'}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}

      <div
        className={classnames(
          error
            ? 'flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-red-300 border-dashed rounded-md'
            : 'flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md'
        )}
      >
        <div className="space-y-1 text-center">
          <svg
            className="w-12 h-12 mx-auto text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative text-center font-primary font-semibold  text-buttonLink hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 cursor-pointer"
            >
              <p className="pl-1 text-white">
                Drop your image cover here or
                <span className="ml-1 text-blueMirror">browse</span>
              </p>

              <input
                {...field}
                onChange={onSelected}
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                {...props}
              />
            </label>
          </div>
        </div>
      </div>
    </>
  )
}
