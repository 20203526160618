import {
  CreateUserGroupDto,
  CreateUserGroupMembershipDto,
  useUserGroupControllerCreateMutation
} from '../../features/api/apiMirrorSlice.generated'

type FormValues = {
  name: string
  publicDescription: string
  file: File | null
  public: boolean
}

type SubmitGroup = {
  addGroup: (values: FormValues) => void
  isLoading: boolean
}

/**
 * TODO
 *  Implement uploadFile when mirror-server endpoint has been added
 */
export const useSubmitGroup = (): SubmitGroup => {
  const [createGroup, { isLoading: isCreateGroupLoading }] =
    useUserGroupControllerCreateMutation()
  // const [uploadFile, { isLoading: isUploadGroupLoading }] =
  //   useUploadFileMutation()
  //
  // const [updateGroupById, { isLoading: isUpdateGroupByIdLoading }] =
  //   useUpdateByIdMutation()

  const addGroup = async (values: FormValues) => {
    // CREATE THE GROUP
    //response==userLink Model
    const createUserGroupMembershipDto = {} as CreateUserGroupMembershipDto
    const createUserGroupDto = {
      name: values.name,
      publicDescription: values.publicDescription,
      public: values.public
    } as CreateUserGroupDto
    const response = await createGroup({
      createUserGroupMembershipDto,
      createUserGroupDto
    }).unwrap()

    /**
     * TODO
     *  Once endpoint for file upload is merged in, we can configure uploading
     *  the image and updating the group
     */
    // if (response && values.file) {
    //   const formData = new FormData()
    //   formData.append('file', values.file)

    // UPLOAD THE IMAGE
    // const uploadedImageResponse = await uploadFile({
    //   body: formData,
    //   groupId: response.group._id
    // }).unwrap()

    // UPDATE GROUP WITH IMAGE ID
    //   updateGroupById({
    //     id: response.group._id,
    //     body: {
    //       image: uploadedImageResponse.publicUrl
    //     }
    //   })
    // }
  }

  return {
    addGroup,
    isLoading: isCreateGroupLoading
  }
}
