import React, { FC, useState } from 'react'
import Link from 'next/link'
import { Popover } from '@headlessui/react'
import { MenuAlt4Icon, XIcon } from '@heroicons/react/outline'
import { useAppDispatch, useAppSelector } from 'store/store'
import { selectMyUser } from '../users/usersSlice'
import { Image } from '../../components/Image'
import LoginButtons from './LoginButtons'
import ProfileButtons from './ProfileButtons'
import Sidebar from 'components/Sidebar'
import DrawerSide from 'components/DrawerSide'
import Paragraph from 'components/Paragraph'
import { Button } from 'components/Button'
import SearchBar, { SearchOption } from './SearchBar'
import NavInnerContainer from '../../components/NavInnerContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setSideView } from 'store/sidebar'
import { StarIcon } from '@heroicons/react/solid'

const searchOptions: SearchOption[] = [
  // {
  //   id: 1,
  //   imageUrl: `/Icons/navigation/icon/inactive/groups.svg`,
  //   alt: 'groups icon',
  //   route: '',
  //   label: 'in Groups'
  // },
  {
    id: 2,
    term: '',
    imageUrl: `/Icons/navigation/icon/inactive/user.svg`,
    alt: 'users icon',
    route: '/u/search?',
    label: 'in Users'
  },
  {
    id: 3,
    term: '',
    imageUrl: `/Icons/navigation/icon/inactive/spaces.svg`,
    alt: 'spaces icon',
    route: '/s/search?',
    label: 'in Spaces'
  },
  {
    id: 4,
    term: '',
    imageUrl: `/Icons/navigation/icon/inactive/assets.svg`,
    alt: 'assets icon',
    route: '/a/search?',
    label: 'in Assets'
  }
]

const Navbar: FC = () => {
  const [mobileSearch, setMobileSearch] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const { data: user } = useAppSelector(selectMyUser)
  const { sideView } = useAppSelector((state) => state.sidebarView)
  const dispatch = useAppDispatch()

  const handleSidebarToggle = () => {
    if (sideView === 'open') {
      dispatch(setSideView('collapsed'))
    } else {
      dispatch(setSideView('open'))
    }
  }

  return (
    <>
      <div className="sticky top-0 z-40">
        <Popover
          as="header"
          className="bg-bluenav shadow-sm lg:static lg:overflow-y-visible"
        >
          {({ open, close }) => (
            <NavInnerContainer>
              <div className="flex h-[2.5rem] items-center lg:pl-3">
                {/* Mobile menu button */}
                <Button
                  icon
                  onClick={() => setIsOpenSidebar(!isOpenSidebar)}
                  className="bg-transparent shadow-none hover:bg-transparent focus:ring-0 focus:ring-offset-0 focus:ring-transparent lg:hidden ml-1"
                >
                  {isOpenSidebar ? (
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <MenuAlt4Icon className="h-5 w-5" aria-hidden="true" />
                  )}
                </Button>

                <div className="mobile:hidden block p-2">
                  <button title="Toggle Menu" onClick={handleSidebarToggle}>
                    <img
                      className={`${
                        sideView === 'open' ? 'rotate-180' : 'rotate-0'
                      } transition-all duration-100 scale-110`}
                      src="/Icons/navigation/icon/toggler.svg"
                      alt="toggler"
                    />
                  </button>
                </div>

                {/* LOGO */}
                <Link href="/" passHref={true}>
                  <a className="mobile:p-0">
                    <Image
                      className="object-cover h-10 px-3 w-54 hidden lg:block"
                      fallbackUrl="images/placeholder_image.png"
                      url="/images/in_the_mirror.png"
                      alt="The Mirror logo"
                    />
                    <Image
                      className="h-12 px-2 w-[4.40rem] object-cover block lg:hidden"
                      fallbackUrl="images/placeholder_image.png"
                      url="/images/white_logo_mobile.png"
                      alt="The Mirror logo"
                    />
                  </a>
                </Link>
              </div>

              {/* Search Bar */}
              <div
                className={`flex items-center w-full order-1 mobile:order-2 ${
                  mobileSearch ? 'block' : 'mobile:hidden'
                }`}
                aria-label="Global"
              >
                <SearchBar searchOptions={searchOptions} />
              </div>

              {/* SearchBar mobile icons */}
              <div className="flex items-center justify-end order-2 mobile:order-1">
                <Button
                  icon
                  onClick={() => {
                    setIsOpenSidebar(false)
                    setMobileSearch(!mobileSearch)
                  }}
                  className="hidden mobile:block bg-transparent shadow-none hover:bg-transparent focus:ring-0 focus:ring-offset-0 focus:ring-transparent w-4"
                >
                  <Image
                    url={`/Icons/navigation/icon/${
                      mobileSearch ? 'active' : 'inactive'
                    }/discover.svg`}
                    className="h-4 w-4 text-gray-400 item"
                    alt="Discover Icon"
                    fallbackUrl="images/placeholder_image.png"
                  />
                </Button>

                <Paragraph className="text-greenMirror mr-3 text-base hidden sm:block sm:text-xs mobile:mr-0 px-2">
                  Alpha:&nbsp;Infinity
                </Paragraph>

                <a
                  href="https://github.com/the-mirror-gdp/the-mirror"
                  rel="noreferrer"
                  target="_blank"
                  className="items-center pr-3 hidden sm:flex"
                >
                  <StarIcon className="w-6 h-6 text-yellowMirror" />
                  <Paragraph className="pl-1 w-[8rem]">
                    Star on Github
                  </Paragraph>
                </a>

                {/* Buttons for login or profile */}
                {user ? (
                  <ProfileButtons close={close} user={user} />
                ) : (
                  <LoginButtons position={'navbar'} />
                )}
              </div>
            </NavInnerContainer>
          )}
        </Popover>

        <DrawerSide isOpen={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}>
          <Sidebar classMobile="lg:hidden lt-lg:block lt-lg:ml-0 overflow-auto max-h-[98vh] pb-20 bg-[rgba(0,0,0,0.80)] backdrop-blur-lg" />
        </DrawerSide>
      </div>
    </>
  )
}

export default Navbar
