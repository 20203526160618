import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from '../../store/store'
import { ModalContent } from './modalContent.enum'

interface ModalSlice {
  show: boolean
  component: {
    name: ModalContent | null
    props: any
  }
}

const initialState: ModalSlice = {
  show: false,
  component: {
    name: null,
    props: null
  }
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action): void {
      state.show = true
      state.component = action.payload.component
    },
    hideModal(state): void {
      state.show = false
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.modal
      }
    }
  }
})

export const { showModal, hideModal } = modalSlice.actions

export const selectModal = (state: AppState) => state.modal
