import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'
import { assetListingsAPI } from 'services/assetListings'
import { assetsAPI } from 'services/assets'
import { federatedAuthAPI } from 'services/user/federatedAuth'
import { apiMirrorSlice } from '../features/api/apiMirrorSlice.generated'
import { modalSlice } from '../features/modal/modalSlice'
import { assetsOffersAPI } from '../services/assetsOffers'
import { assetListingsSlice } from './assetListings'
import { assetOffersSlice } from './assetOffers'
import { assetsSlice } from './assets'
import { fedAuthSlice } from './fedAuth'
import { notificationsSlice } from './notification'
import { rtkQueryErrorLogger as rtkQueryErrorHandler } from './store-error-handler'
import { venlyClientSlice } from './venlyClient'
import { assetsViewSlice } from './assetViewMode'
import { sidebarViewSlice } from './sidebar'

// Export this so we can use it in testing
export const reducerObj = {
  [apiMirrorSlice.reducerPath]: apiMirrorSlice.reducer,

  // note that I think these are deprecated; apiMIrrorSlice should be used instead for everything (e.g. note that Spaces isn't in the list below)
  [modalSlice.name]: modalSlice.reducer,
  [assetsSlice.name]: assetsSlice.reducer,
  [assetsAPI.reducerPath]: assetsAPI.reducer,
  [assetsOffersAPI.reducerPath]: assetsOffersAPI.reducer,
  [assetOffersSlice.name]: assetOffersSlice.reducer,
  [assetListingsSlice.name]: assetListingsSlice.reducer,
  [assetListingsAPI.reducerPath]: assetListingsAPI.reducer,
  [fedAuthSlice.name]: fedAuthSlice.reducer,
  [federatedAuthAPI.reducerPath]: federatedAuthAPI.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [venlyClientSlice.name]: venlyClientSlice.reducer,
  [assetsViewSlice.name]: assetsViewSlice.reducer,
  [sidebarViewSlice.name]: sidebarViewSlice.reducer
}

const makeStore = () =>
  configureStore({
    reducer: reducerObj,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(rtkQueryErrorHandler)
        .concat(federatedAuthAPI.middleware)
        .concat(assetsAPI.middleware)
        .concat(assetListingsAPI.middleware)
        .concat(assetsOffersAPI.middleware)

        .concat(apiMirrorSlice.middleware)
  })

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const wrapper = createWrapper<AppStore>(makeStore)
