import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'SpaceTemplates'>({
  endpoints: {
    spaceControllerFindSpaceTemplates: {
      providesTags: (result, error, arg) => [{ type: 'SpaceTemplates' }]
    }
  }
})

export const selectSpaceTemplates =
  apiMirrorSlice.endpoints.spaceControllerFindSpaceTemplates.select()
