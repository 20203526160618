import {
  apiMirrorSlice,
  useStripeControllerAddBankAccountMutation,
  useStripeControllerCreateCardMutation,
  useStripeControllerCreateConnectAccountMutation,
  useStripeControllerCreateCustomerAccountMutation,
  useStripeControllerCreateDashboardLinkQuery,
  useStripeControllerCreateSubscriptionMutation,
  useStripeControllerDeleteCardMutation,
  useStripeControllerDeleteConnectAccountMutation,
  useStripeControllerGetAllProductsWithPriceQuery,
  useStripeControllerGetCardsListQuery,
  useStripeControllerGetStripeAccountInfoQuery,
  useStripeControllerSetDefaultPaymentMethodMutation,
  useStripeControllerCreateCustomerPortalLinkQuery,
  useStripeControllerGetPaymentMethodsQuery,
  CreatePaymentSessionDto,
  useStripeControllerCreatePaymentSessionMutation
} from 'features/api/apiMirrorSlice.generated'
import { NotificationType, useNotification } from 'features/notification'
import { selectMyUser } from 'features/users/usersSlice'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'

const usePayments = () => {
  const dispatch = useAppDispatch()
  const { notify } = useNotification()
  const { data: user } = useAppSelector(selectMyUser)
  const [clientSecret, setClientSecret] = useState('')

  const [
    connectAccount,
    { isLoading: connectLoading, data: connectedAccountData }
  ] = useStripeControllerCreateConnectAccountMutation()

  const [
    createCustomerAccount,
    { isLoading: createCustomerAccountLoading, data: createCustomerAccountData }
  ] = useStripeControllerCreateCustomerAccountMutation()

  const [
    deleteStripeAccount,
    { isLoading: deleteAccountLoading, data: deletedAccountData }
  ] = useStripeControllerDeleteConnectAccountMutation()

  const [addNewCard, { isLoading: addNewCardLoading, data: addedCardData }] =
    useStripeControllerCreateCardMutation()

  const [addNewBank, { isLoading: addNewBankLoading, data: addedBankData }] =
    useStripeControllerAddBankAccountMutation()

  const {
    data: stripeInfo,
    isLoading: stripeInfoLoading,
    isError,
    refetch: getStripeInfo
  } = useStripeControllerGetStripeAccountInfoQuery(undefined, {
    skip: !user?.stripeAccountId
  })

  const [deleteCard, { isLoading: deleteCardLoading, data: deletedCardData }] =
    useStripeControllerDeleteCardMutation()

  // const {
  //   data: getSubscriptions,
  //   isLoading: getSubscriptionsLoading,
  //   isError: getSubscriptionErrors
  // } = useStripeControllerGetAllProductsWithPriceQuery()

  const [subscribe, { isLoading: subscribeLoading, isError: subscribeError }] =
    useStripeControllerCreateSubscriptionMutation()

  const [
    createPaymentSession,
    {
      data: paymentSession,
      isLoading: paymentSessionLoading,
      isError: paymentSessionError
    }
  ] = useStripeControllerCreatePaymentSessionMutation()

  const [
    updateDefaultPayment,
    {
      isLoading: updateDefaultPayMethodLoading,
      isError: updateDefaultPayMethodError
    }
  ] = useStripeControllerSetDefaultPaymentMethodMutation()

  const {
    data: dashboardLink,
    isLoading: getDashboardLoading,
    isError: getDashboardError
  } = useStripeControllerCreateDashboardLinkQuery(undefined, {
    skip:
      !user?.stripeAccountId ||
      !stripeInfo?.details_submitted ||
      !stripeInfo?.payouts_enabled
  })

  const {
    data: customerPortal,
    isLoading: customerPortalLoading,
    isError: customerPortalError
  } = useStripeControllerCreateCustomerPortalLinkQuery(undefined, {
    skip: !user?.stripeCustomerId
  })

  // const handleSubscribe = async (amount, currency, productId, priceId) => {
  //   const subscriptionDto: any = {
  //     amount,
  //     currency,
  //     productId,
  //     priceId,
  //     destination: null
  //   }
  //   const data = await subscribe({ subscriptionDto: subscriptionDto })
  //   return data
  // }

  const handleRemoveAccount = async () => {
    try {
      await deleteStripeAccount()
      notify('Account removed successfully', NotificationType.SUCCESS)
    } catch (error) {
      notify(
        'An error occurred while removing the account',
        NotificationType.ERROR
      )
    }
  }

  const handleRemoveCard = async (id: string) => {
    try {
      await deleteCard({ cardId: id })
      notify('Card removed successfully', NotificationType.SUCCESS)
    } catch (error) {
      notify(
        'An error occurred while removing the card',
        NotificationType.ERROR
      )
    }
  }

  const handleUpdateDefaultCard = async (id: string) => {
    try {
      await updateDefaultPayment({ paymentMethodId: id })
      notify('Default card updated successfully', NotificationType.SUCCESS)
    } catch (error) {
      notify('Something went wrong!', NotificationType.ERROR)
    }
  }

  const handleCreatePaymentSession = async (purchaseOptionIds) => {
    const createPaymentSessionDto: CreatePaymentSessionDto = {
      purchaseOptionIds: purchaseOptionIds
    }
    try {
      await createPaymentSession({ createPaymentSessionDto }).unwrap()
    } catch (error: any) {
      if (error?.data) {
        notify(error?.data?.message, NotificationType.ERROR)
      } else {
        notify(
          'Error creating payment session. Please try again or reach out to us on Discord',
          NotificationType.ERROR
        )
      }
    }
  }

  useEffect(() => {
    if (deletedAccountData) {
      dispatch(apiMirrorSlice.util.invalidateTags(['Users']))
      getStripeInfo()
    }
  }, [deletedAccountData])

  return {
    connectAccount,
    connectLoading,
    connectedAccountData,
    createCustomerAccount,
    createCustomerAccountLoading,
    createCustomerAccountData,
    deleteStripeAccount,
    deleteAccountLoading,
    deletedAccountData,
    addNewCard,
    addNewCardLoading,
    addedCardData,
    addNewBank,
    addNewBankLoading,
    addedBankData,
    stripeInfo,
    stripeInfoLoading,
    getStripeInfo,
    deleteCard,
    deleteCardLoading,
    deletedCardData,
    // getSubscriptions,
    // getSubscriptionsLoading,
    // getSubscriptionErrors,
    subscribe,
    subscribeLoading,
    subscribeError,
    // handleSubscribe,
    clientSecret,
    handleRemoveAccount,
    handleRemoveCard,
    handleUpdateDefaultCard,
    dashboardLink,
    getDashboardLoading,
    customerPortal,
    customerPortalLoading,
    customerPortalError,
    handleCreatePaymentSession,
    paymentSession,
    paymentSessionLoading,
    paymentSessionError
  }
}

export default usePayments
