import SignInModalContent from '../login/SignInModalContent'
import SignUpModalContent from '../login/SignUpModalContent'
import Terms from '../../components/Terms'
import CreateSpaceForm from '../spaces/CreateSpaceForm'
import CreateAssetForm from '../assets/CreateAssetForm'
import EditAssetForm from '../assets/EditAssetForm'
import DownloadForm from '../download/DownloadForm'
import CreateGroupForm from '../groups/CreateGroupForm'
import EditGroupForm from '../../components/groups/EditGroupForm'
import CreateListingForm from '../../components/CreateListingForm'
import ChangeEmailForm from '../account/ChangeEmailForm'
import ChangePasswordForm from '../account/ChangePasswordForm'
import ForgotPasswordForm from '../login/ForgotPasswordForm'
import CreateUserFeedbackItemForm from '../userfeedback/CreateUserFeedbackItemForm'
import DeleteUserFeedbackCommentForm from '../userfeedback/DeleteUserFeedbackCommentForm'
import SubmitUserAccessKeyForm from '../useraccesskey/SubmitUserAccessKeyForm'
import DeleteUserFeedbackItem from '../userfeedback/DeleteUserFeedbackItem'
import EditUserFeedbackForm from '../userfeedback/EditUserFeedbackItemForm'
import Confirmation from '../../components/ConfirmationDialog'
import InfoModal from '../../components/InfoModal'
import AssetPurchaseOption from 'components/AssetPurchaseOption'
import SubConfirmation from 'components/Subscription/subscriptionConfirmationModal'

/** Dynamic Modal Component mapping - Every component rendered by useModal hook must be defined here */
export const MODAL_MAP = {
  ChangeEmailForm,
  ChangePasswordForm,
  CreateAssetForm,
  CreateGroupForm,
  CreateListingForm,
  CreateSpaceForm,
  CreateUserFeedbackItemForm,
  DeleteUserFeedbackItem,
  EditUserFeedbackForm,
  DeleteUserFeedbackCommentForm,
  DownloadForm,
  EditAssetForm,
  EditGroupForm,
  ForgotPasswordForm,
  SignInModalContent,
  SignUpModalContent,
  SubmitUserAccessKeyForm,
  Terms,
  Confirmation,
  InfoModal,
  AssetPurchaseOption,
  SubConfirmation
}
