import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { FC, Fragment, useEffect } from 'react'
import { Image } from 'components/Image'
import { getAuth } from 'firebase/auth'
import { useRouter } from 'next/router'
import { useModal } from '../modal/useModal'
import { ModalContent } from '../modal/modalContent.enum'
import { DownloadButton } from '../download/DownloadButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { Icon, SizeProp } from '@fortawesome/fontawesome-svg-core'
import Link from 'next/link'
import { selectMyCart } from 'features/Cart/cartSlice'
import { useAppSelector } from 'store/store'
const DEFAULT_PROFILE_IMAGE = '/Icons/navigation/Man.svg'

interface NavigationOption {
  name: NAVIGATION_OPTION
}

enum NAVIGATION_OPTION {
  DOWNLOAD = 'Download',
  ACCOUNT_SETTINGS = 'Account Settings',
  VIEW_PUBLIC_PROFILE = 'View Public Profile',
  LOG_OUT = 'Log Out'
}

type ProfileButtonsProps = {
  close: () => void
  user: any // update type when codegen has correct shape
}

const ProfileButtons: FC<ProfileButtonsProps> = ({ close, user }) => {
  const router = useRouter()
  const { showModalWith } = useModal()

  const { data: cart } = useAppSelector(selectMyCart)

  let cartItems: any = {}

  if (cart) {
    cartItems = cart.cartItems || {}
  }

  const showDownloadForm = () => {
    showModalWith({ name: ModalContent.DownloadForm })
  }

  useEffect(() => {
    const download = Object.keys(router.query)[0]
    if (download === 'download-popup') {
      showModalWith({ name: ModalContent.DownloadForm })
    }
  }, [])

  const userNavigationOptions: NavigationOption[] = [
    { name: NAVIGATION_OPTION.DOWNLOAD },
    { name: NAVIGATION_OPTION.ACCOUNT_SETTINGS },
    { name: NAVIGATION_OPTION.VIEW_PUBLIC_PROFILE },
    { name: NAVIGATION_OPTION.LOG_OUT }
  ]

  const clickHandler = (item: NavigationOption) => {
    switch (item.name) {
      case NAVIGATION_OPTION.LOG_OUT:
        getAuth().signOut()
        router.push('/')
        break
      case NAVIGATION_OPTION.ACCOUNT_SETTINGS:
        router.push('/account')
        break
      case NAVIGATION_OPTION.VIEW_PUBLIC_PROFILE:
        router.push('/u/' + user.id)
        break
      case NAVIGATION_OPTION.DOWNLOAD:
        window.open(
          'https://docs.themirror.space/docs/get-started',
          '_blank',
          'noopener,noreferrer'
        )
        break
      default:
        router.push('/')
        break
    }
  }
  return (
    <>
      <DownloadButton />
      {process.env.NEXT_PUBLIC_MARKETPLACE_ENABLED == 'true' &&
        cartItems &&
        cartItems?.length > 0 && (
          <Link href="/cart" passHref={true}>
            <a className="relative">
              <FontAwesomeIcon
                icon={faShoppingBag as Icon}
                size={'xl' as SizeProp}
                className="text-white ml-4 mr-2 cursor-pointer"
              />
              <span className="w-4 h-4 top-0 right-0 rounded-full bg-blueMirror text-white text-xxs font-primary font-semibold text-center absolute leading-relaxed">
                {cartItems?.length}
              </span>
            </a>
          </Link>
        )}
      <Menu as="div" className="relative px-3">
        {({ open }) => (
          <>
            <Menu.Button
              as="button"
              className={classNames(
                'flex w-full bg-blueMirror rounded-full focus:outline-none',
                {
                  'ring-2 ring-offset-2 ring-blue-500': open
                }
              )}
            >
              <Image
                className={classNames(
                  'mobile:w-[1.875rem] mobile:h-[1.875rem] w-10 h-10 rounded-full',
                  { 'p-2': !user.profileImage }
                )}
                url={
                  user.profileImage ? user.profileImage : DEFAULT_PROFILE_IMAGE
                }
                alt="Profile Image"
                fallbackUrl={DEFAULT_PROFILE_IMAGE}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  'absolute z-20 right-0 mt-4 mobile:mt-5 w-[10rem] rounded-b-xl ',
                  'bg-[rgba(0,0,0,0.80)] backdrop-blur-lg shadow-md shadow-disabledMirror focus:outline-none overflow-hidden'
                )}
              >
                {userNavigationOptions.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <p
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block py-2 text-sm text-white cursor-pointer hover:bg-gray-800 px-[1.25rem] '
                        )}
                        onClick={() => {
                          clickHandler(item)
                          close()
                        }}
                      >
                        {item.name}
                      </p>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  )
}

export default ProfileButtons
