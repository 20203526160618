import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationType } from 'features/notification'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from './store'

interface ShowPayload {
  message: string
  type: NotificationType
}

export interface INotificationsSlice extends ShowPayload {
  show: boolean
}

const initialState: INotificationsSlice = {
  show: false,
  message: '',
  type: NotificationType.INFO
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state, action: PayloadAction<ShowPayload>): void {
      state.show = true
      state.message = action.payload.message
      state.type = action.payload.type
    },
    unShowNotification(state): void {
      state.show = false
      state.message = ''
      state.type = NotificationType.INFO
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.notifications
      }
    }
  }
})

export const { showNotification, unShowNotification } =
  notificationsSlice.actions

export const selectNotification = (state: AppState) => state.notifications
