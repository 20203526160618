import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'UserFeedbackItems'>({
  endpoints: {
    userFeedbackControllerFindAllPublicUserFeedbackItems: {
      providesTags: ['UserFeedbackItems', 'UserFeedbackComments']
    },
    userFeedbackControllerFindNewestPublicUserFeedbackItems: {
      providesTags: ['UserFeedbackItems']
    },
    userFeedbackControllerFindTopPublicUserFeedbackItems: {
      providesTags: ['UserFeedbackItems']
    },
    userFeedbackControllerFindOne: {
      providesTags: ['UserFeedbackItems', 'UserFeedbackComments']
    },
    userFeedbackControllerCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'UserFeedbackItems', id: result?._id }
      ]
    },
    // Note: DONT add comment endpoints here: that's in a separate file. If added here, it will break because it's there twice.
    userFeedbackControllerUpdate: {
      invalidatesTags: ['UserFeedbackItems']
    },
    userFeedbackControllerVoteOnUserFeedbackItem: {
      invalidatesTags: ['UserFeedbackItems']
    }
  }
})

export const selectAllUserFeedbackItems =
  apiMirrorSlice.endpoints.userFeedbackControllerFindAllPublicUserFeedbackItems.select()

// export const selectMySpacesPreview = (state) =>
//   selectMySpaces(state).data?.slice(0, 3)
