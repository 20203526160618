import { NotificationType, useNotification } from '../notification'
import { useAuthControllerCreateUserWithEmailPasswordAndTypeMutation } from '../api/apiMirrorSlice.generated'
import { useSignInUser } from './useSignInUser'
import { SignUpValues } from './SignUpForm'

import * as Sentry from '@sentry/nextjs'

export const useSignUpUser = () => {
  const [createUser, { isLoading: isLoadingCreateUser }] =
    useAuthControllerCreateUserWithEmailPasswordAndTypeMutation()
  const { signInUser, isLoading: isLoadingLogin } = useSignInUser()
  const { notify } = useNotification()

  const signUpUser = async ({
    email,
    password,
    displayName,
    termsAgreedtoGeneralTOSandPP,
    alphaKey
  }: SignUpValues) => {
    try {
      await createUser({
        createUserWithEmailPasswordDto: {
          email,
          password,
          displayName,
          termsAgreedtoGeneralTOSandPP
        }
      }).unwrap()

      await signInUser({ email, password })
    } catch (error: any) {
      Sentry.captureException(error)
      const errorMessage = error?.data?.message || error?.message
      notify(errorMessage, NotificationType.WARNING)
    }
  }
  return { signUpUser, isLoading: isLoadingLogin || isLoadingCreateUser }
}
