import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'ThemeTags'>({
  endpoints: {
    tagControllerFindAllThemeTags: {
      providesTags: (result, error, arg) => [{ type: 'ThemeTags' }]
    }
  }
})

export const selectAllTags =
  apiMirrorSlice.endpoints.tagControllerFindAllThemeTags.select()
