import { ASSET_TYPE } from '../../enums/AssetsTypes'

export function getAssetTypeFromExtension(fileName: string) {
  const imageFormats = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp']
  const meshFormats = ['.obj', '.fbx', '.gltf', '.glb', '.stl']
  const audioFormats = ['.mp3', '.wav', '.wma', '.ogg']
  const scriptFormats = ['.json', '.vs', '.gd']
  const fileExtension = fileName.substring(fileName.lastIndexOf('.'))
  if (imageFormats.includes(fileExtension)) {
    return ASSET_TYPE.IMAGE
  } else if (meshFormats.includes(fileExtension)) {
    return ASSET_TYPE.MESH
  } else if (audioFormats.includes(fileExtension)) {
    return ASSET_TYPE.AUDIO
  } else if (scriptFormats.includes(fileExtension)) {
    return ASSET_TYPE.SCRIPT
  } else {
    console.warn('Unknown file type: ' + fileExtension)
    return ASSET_TYPE.UNKNOWN
  }
}

export const useFile = () => {
  /** Parses correct File type values for .glb, .gltf, .pck */
  const parseFileType = (file: File) => {
    if (!file) {
      return
    }

    let { type } = file
    if (type !== null && type !== '') {
      return file
    }

    /** File type must be added to mime by checking extension */
    const extension = file.name.split('.')[1]
    if (extension === 'glb') {
      type = 'model/gltf-binary'
    } else if (extension === 'gltf') {
      type = 'model/gltf+json'
    } else if (extension === 'pck') {
      type = 'application/scene-binary'
    } else {
      return
    }
    return new File([file], file.name, {
      type
    })
  }

  /** TODO - Update cases for correct ASSET_TYPES */
  const getAssetTypeFromFile = (file: File) => {
    let { type } = file
    switch (type) {
      case 'model/gltf-binary':
      case 'model/gltf+json':
      case 'application/scene-binary':
        return ASSET_TYPE.MESH
      case 'image/jpeg':
      case 'image/gif':
      case 'image/png':
      case 'image/bmp':
      case 'image/svg+xml':
      case 'image/tiff':
        return ASSET_TYPE.IMAGE
      case 'audio/mpeg':
      case 'audio/wav':
      case 'audio/ogg':
      case 'audio/x-ms-wma':
        return ASSET_TYPE.AUDIO
      case 'application/json':
      case 'script/gdscript':
      case 'script/mirror-visual-script+json':
        return ASSET_TYPE.SCRIPT
      default:
        return ASSET_TYPE.IMAGE
    }
  }

  const getFilePreview = (file: File) => {
    switch (file.type) {
      case 'model/gltf-binary':
      case 'model/gltf+json':
      case 'application/scene-binary':
        // TODO - update to 3d model placeholder image
        return '/images/placeholder_image.png'
      case 'image/jpeg':
      case 'image/gif':
      case 'image/png':
      case 'image/bmp':
      case 'image/svg+xml':
      case 'image/tiff':
      default:
        return URL.createObjectURL(file)
    }
  }

  return { parseFileType, getAssetTypeFromFile, getFilePreview }
}
