import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { signUpSchema } from './signUp.validationSchema'
import { Button } from '../../components/Button'
import Loader from '../../components/Loader'
import InputField from '../../components/InputField'
import CheckboxField from '../../components/CheckboxField'
import { useSignUpUser } from './useSignUpUser'
import { useModal } from '../modal/useModal'
import { ModalContent } from '../modal/modalContent.enum'
import { ButtonTransparent } from '../../components/ButtonTransparent'
export interface SignUpValues {
  displayName: string
  email: string
  password: string
  confirmPassword: string
  alphaKey: string
  termsAgreedtoGeneralTOSandPP: boolean
}

type Props = {
  /** Pre-populates form with specific values */
  formValues?: SignUpValues
}

const SignUpForm: FC<Props> = ({ formValues }) => {
  const { signUpUser, isLoading } = useSignUpUser()
  const { showModalWith } = useModal()

  const showTerms = (currentValues: SignUpValues) => {
    showModalWith({
      name: ModalContent.Terms,
      props: { formValues: currentValues }
    })
  }

  const initialValues: SignUpValues = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    alphaKey: '',
    termsAgreedtoGeneralTOSandPP: false
  }

  return (
    <Formik
      validateOnBlur={false}
      initialValues={formValues ?? initialValues}
      validationSchema={signUpSchema}
      onSubmit={signUpUser}
    >
      {({ values, isValid, errors }) => (
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField
              type="text"
              name="displayName"
              label="Display Name"
              disabled={isLoading}
            />

            <InputField
              type="email"
              name="email"
              label="Email"
              disabled={isLoading}
            />

            <InputField
              type="password"
              name="password"
              label="Password"
              disabled={isLoading}
            />

            <InputField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              disabled={isLoading}
            />

            {/* temp disable for alpha agreement */}
            <CheckboxField name="termsAgreedtoGeneralTOSandPP">
              <label className="text-white py-3">
                I agree to the &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://themirror.space/terms"
                  className="text-blueMirror"
                >
                  Terms of Service
                </a>
                &nbsp; and &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://themirror.space/privacy"
                  className="text-blueMirror"
                >
                  Privacy Policy
                </a>
              </label>
            </CheckboxField>

            <Button
              type="submit"
              disabled={isLoading || !isValid || Object.keys(errors).length > 0}
              className="w-full"
            >
              Sign Up {isLoading && <Loader className="ml-2" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
