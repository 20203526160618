import { Form, Formik } from 'formik'
import InputField from '../../components/InputField'
import { Button } from '../../components/Button'
import React from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { changePasswordValidationSchema } from './changePassword.validationSchema'
import { useChangePassword } from './useChangePassword'
import FormHeaderSection from '../../components/FormHeaderSection'

const ChangePasswordForm = () => {
  const { updatePassword } = useChangePassword()

  return (
    <div className="max-w-sm">
      <FormHeaderSection>
        <Heading size="2">Change Password</Heading>
        <Paragraph>Please verify and enter your new password below.</Paragraph>
      </FormHeaderSection>

      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={changePasswordValidationSchema}
        validateOnBlur={false}
        onSubmit={updatePassword}
      >
        <Form>
          <div className="flex flex-col gap-3">
            <InputField
              name="currentPassword"
              type="password"
              label="Current Password"
            />
            <InputField
              name="newPassword"
              type="password"
              label="New Password"
            />
            <InputField
              name="confirmPassword"
              type="password"
              label="Confirm Password"
            />
            <Button type="submit" className="w-full">
              Update
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ChangePasswordForm
