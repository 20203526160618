import { FC } from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { Button } from '../../components/Button'
import { Form, Formik } from 'formik'
import { assetSchema } from './asset.validationSchema'
import { useModal } from '../modal/useModal'
import InputField from '../../components/InputField'
import TextareaField from '../../components/TextareaField'
import CheckboxField from '../../components/CheckboxField'
import FormHeaderSection from '../../components/FormHeaderSection'
import FileField from '../files/FileField'
import { useSubmitAsset } from './useSubmitAsset'
import { useIsAdminOnAdminPage } from '../../hooks/admin'

interface CreateAssetValues {
  name: string
  description: string
  fileUpload: File | undefined
  privacy: string
  mirrorPublicLibrary?: boolean
}

const CreateAssetForm: FC = () => {
  const { addAsset, isLoading } = useSubmitAsset()
  const { dismissModal } = useModal()
  const isAdminOnAdminPage = useIsAdminOnAdminPage()

  const initialValues: CreateAssetValues = {
    name: '',
    description: '',
    fileUpload: undefined,
    privacy: 'public',
    mirrorPublicLibrary: undefined
  }

  const onSubmit = async (values) => {
    await addAsset({
      name: values.name,
      description: values.description,
      public: true, // temp till privacy workflow defined
      file: values.fileUpload,
      mirrorPublicLibrary: values?.mirrorPublicLibrary ?? undefined
    })

    dismissModal()
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Create your asset</Heading>
        <Paragraph>
          Please enter the information about your new asset below.
        </Paragraph>
      </FormHeaderSection>

      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={assetSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField
              type="text"
              name="name"
              label="Name"
              disabled={isLoading}
            />

            <TextareaField
              name="description"
              label="Description"
              disabled={isLoading}
            />

            <FileField
              name="fileUpload"
              disabled={isLoading}
              accept=".gif,.bmp,.tiff,.glb,.gltf,.pck"
            />

            {/* Admin-Only Fields */}
            {isAdminOnAdminPage && (
              <CheckboxField
                name="mirrorPublicLibrary"
                label="Public Mirror Library Asset"
                disabled={isLoading}
              />
            )}

            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
              Create Asset
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default CreateAssetForm
