import * as Yup from 'yup'
import { ACCOUNT_ERROR } from './accountError.enum'

export const changePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, ACCOUNT_ERROR.PASSWORD_MIN)
    .required(ACCOUNT_ERROR.PASSWORD),
  newPassword: Yup.string()
    .min(6, ACCOUNT_ERROR.PASSWORD_MIN)
    .required(ACCOUNT_ERROR.PASSWORD),
  confirmPassword: Yup.string()
    .required(ACCOUNT_ERROR.PASSWORD)
    .oneOf([Yup.ref('newPassword'), null], ACCOUNT_ERROR.CONFIRM_PASSWORD)
    .min(6, ACCOUNT_ERROR.PASSWORD_MIN)
})
