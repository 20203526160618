import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WindowMode } from '@venly/connect'
import { getVenlyConnectInstance } from '../services/venly'
import { AppState } from './store'
import * as Sentry from '@sentry/nextjs'

export const showVenlyWidgetPopup = createAsyncThunk<
  {},
  {},
  { rejectValue: string }
>(
  'venlyClient/widgetPopup',
  async (_: any, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const venlyConnect = getVenlyConnectInstance()
    try {
      const auth = await venlyConnect.flows.authenticate({
        windowMode: WindowMode.POPUP
      })
      if (auth.isAuthenticated) {
        return fulfillWithValue('Venly Wallet Linked')
      } else {
        return rejectWithValue("Authentication didn't complete")
      }
    } catch (error) {
      Sentry.captureException(error)
      // TODO add more detail for error types here
      return rejectWithValue('Unknown Error')
    }
  }
)
export interface IVenlyClientSlice {
  widgetPopupShown: boolean
  venlyConnectionSuccessful?: boolean
  venlyConnectionError?: string
}

const initialState: IVenlyClientSlice = {
  widgetPopupShown: false,
  venlyConnectionSuccessful: undefined,
  venlyConnectionError: undefined
}

/**
 * @description This is to manage the client-side Venly Widget
 */
export const venlyClientSlice = createSlice({
  name: 'venlyClient',
  initialState,
  reducers: {
    venlyWidgetPopupShown(state): void {
      state.widgetPopupShown = true
    },
    venlyWidgetPopupHidden(state): void {
      state.widgetPopupShown = false
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(showVenlyWidgetPopup.pending, (state, action) => {
      state.widgetPopupShown = true
    }),
      builder.addCase(showVenlyWidgetPopup.fulfilled, (state, action) => {
        state.widgetPopupShown = false
        state.venlyConnectionSuccessful = true
      }),
      builder.addCase(showVenlyWidgetPopup.rejected, (state, action) => {
        state.widgetPopupShown = false
        state.venlyConnectionSuccessful = false
        state.venlyConnectionError = action.payload
      })
  }
})

export const { venlyWidgetPopupShown, venlyWidgetPopupHidden } =
  venlyClientSlice.actions

export const selectWidgetPopupShown = (state: AppState) =>
  state.venlyClient.widgetPopupShown

export const selectVenlyConnectionSuccessful = (state: AppState) =>
  state.venlyClient.venlyConnectionSuccessful

export const selectVenlyConnectionError = (state: AppState) =>
  state.venlyClient.venlyConnectionError
