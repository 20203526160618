import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import { NotificationType, useNotification } from '../notification'
import { useModal } from '../modal/useModal'
import { useSignInUser } from '../login/useSignInUser'
import { useUserControllerUpdateProfileMutation } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'
interface ChangeEmailFormData {
  email: string
  password: string
}

export const useChangeEmail = () => {
  const { notify } = useNotification()
  const { dismissModal } = useModal()
  const { signInUser } = useSignInUser()
  const [updateProfile] = useUserControllerUpdateProfileMutation()

  /** Updates user's email in both mongodb and firebase. Re-authenticates user after successful update */
  const updateEmail = async ({ email, password }: ChangeEmailFormData) => {
    const user = firebase.auth().currentUser

    if (!user) {
      return
    }

    try {
      /** Verify user password through firebase */
      const currentEmail = getAuth().currentUser?.email as string
      const credentials = firebase.auth.EmailAuthProvider.credential(
        currentEmail,
        password
      )
      await user.reauthenticateWithCredential(credentials)

      /** On successful verification, update user email in mongodb and firebase */
      const updateUserProfileDto = { email }
      await updateProfile({ updateUserProfileDto }).unwrap()

      /** Firebase token expires on email change - user must be signed in again */
      await signInUser({ email, password })

      notify('Email updated!', NotificationType.SUCCESS)
      dismissModal()
    } catch (error: any) {
      Sentry.captureException(error)
      const message = error?.data?.message ?? error?.message
      notify(message, NotificationType.WARNING)
    }
  }

  return { updateEmail }
}
