import { FC, ReactNode } from 'react'
import Notification from './Notification'
import Modal from '../modal/Modal'

type Props = {
  children: ReactNode
}
const NotificationContainer: FC<Props> = ({ children }) => {
  return (
    <>
      {children}
      <Notification />
      <Modal />
    </>
  )
}

export default NotificationContainer
