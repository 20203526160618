import { apiMirrorSliceBase as api } from './apiMirrorSliceBase'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` })
    }),
    appControllerGetHealth: build.query<
      AppControllerGetHealthApiResponse,
      AppControllerGetHealthApiArg
    >({
      query: () => ({ url: `/util/version` })
    }),
    appControllerThrowIntentionalError: build.mutation<
      AppControllerThrowIntentionalErrorApiResponse,
      AppControllerThrowIntentionalErrorApiArg
    >({
      query: (queryArg) => ({
        url: `/util/intentional-error-3823937293729373923732949581/${queryArg.delay}`,
        method: 'POST'
      })
    }),
    appControllerNonResponsiveEndpoint: build.mutation<
      AppControllerNonResponsiveEndpointApiResponse,
      AppControllerNonResponsiveEndpointApiArg
    >({
      query: () => ({
        url: `/util/non-responsive-endpoint-9436339442720594681156236885`,
        method: 'POST'
      })
    }),
    godotServerOverrideConfigControllerFindOne: build.query<
      GodotServerOverrideConfigControllerFindOneApiResponse,
      GodotServerOverrideConfigControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/godot-server-override-config/${queryArg.spaceId}`
      })
    }),
    godotServerOverrideConfigControllerFindOneAlias: build.query<
      GodotServerOverrideConfigControllerFindOneAliasApiResponse,
      GodotServerOverrideConfigControllerFindOneAliasApiArg
    >({
      query: (queryArg) => ({ url: `/godot-server-override-config` })
    }),
    godotServerOverrideConfigControllerCreate: build.mutation<
      GodotServerOverrideConfigControllerCreateApiResponse,
      GodotServerOverrideConfigControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/godot-server-override-config`,
        method: 'POST',
        body: queryArg.createGodotServerOverrideConfigDto
      })
    }),
    venlyControllerGetVenlyWalletsForUser: build.query<
      VenlyControllerGetVenlyWalletsForUserApiResponse,
      VenlyControllerGetVenlyWalletsForUserApiArg
    >({
      query: () => ({ url: `/venly/me` })
    }),
    storageControllerGetClientVersion: build.query<
      StorageControllerGetClientVersionApiResponse,
      StorageControllerGetClientVersionApiArg
    >({
      query: () => ({ url: `/storage/client/version` })
    }),
    storageControllerGetClientUrl: build.query<
      StorageControllerGetClientUrlApiResponse,
      StorageControllerGetClientUrlApiArg
    >({
      query: (queryArg) => ({ url: `/storage/client/${queryArg.platform}` })
    }),
    storageControllerGetClientUrls: build.query<
      StorageControllerGetClientUrlsApiResponse,
      StorageControllerGetClientUrlsApiArg
    >({
      query: () => ({ url: `/storage/clients` })
    }),
    storageControllerDownloadFile: build.query<
      StorageControllerDownloadFileApiResponse,
      StorageControllerDownloadFileApiArg
    >({
      query: (queryArg) => ({ url: `/storage/${queryArg.fileName}` })
    }),
    mirrorServerConfigControllerGetConfig: build.query<
      MirrorServerConfigControllerGetConfigApiResponse,
      MirrorServerConfigControllerGetConfigApiArg
    >({
      query: () => ({ url: `/mirror-server-config` })
    }),
    mirrorServerConfigControllerSetGdServerVersion: build.mutation<
      MirrorServerConfigControllerSetGdServerVersionApiResponse,
      MirrorServerConfigControllerSetGdServerVersionApiArg
    >({
      query: () => ({ url: `/mirror-server-config`, method: 'PATCH' })
    }),
    assetControllerSearch: build.query<
      AssetControllerSearchApiResponse,
      AssetControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/search`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets
        }
      })
    }),
    assetControllerSearchV2: build.query<
      AssetControllerSearchV2ApiResponse,
      AssetControllerSearchV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/search-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets
        }
      })
    }),
    assetControllerGetMirrorPublicLibraryAssets: build.query<
      AssetControllerGetMirrorPublicLibraryAssetsApiResponse,
      AssetControllerGetMirrorPublicLibraryAssetsApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/library`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetMirrorPublicLibraryAssetsV2: build.query<
      AssetControllerGetMirrorPublicLibraryAssetsV2ApiResponse,
      AssetControllerGetMirrorPublicLibraryAssetsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/library-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerCreate: build.mutation<
      AssetControllerCreateApiResponse,
      AssetControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/asset`,
        method: 'POST',
        body: queryArg.createAssetDto
      })
    }),
    assetControllerGetUserRecentInstancedAssets: build.query<
      AssetControllerGetUserRecentInstancedAssetsApiResponse,
      AssetControllerGetUserRecentInstancedAssetsApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/recents`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets
        }
      })
    }),
    assetControllerCreateWithUpload: build.mutation<
      AssetControllerCreateWithUploadApiResponse,
      AssetControllerCreateWithUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/new`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    assetControllerGetAssetsForMe: build.query<
      AssetControllerGetAssetsForMeApiResponse,
      AssetControllerGetAssetsForMeApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/me`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetAssetsForMeV2: build.query<
      AssetControllerGetAssetsForMeV2ApiResponse,
      AssetControllerGetAssetsForMeV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/me-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetAllAccessibleAssetsOfUser: build.query<
      AssetControllerGetAllAccessibleAssetsOfUserApiResponse,
      AssetControllerGetAllAccessibleAssetsOfUserApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/my-library`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetAllAccessibleAssetsOfUserV2: build.query<
      AssetControllerGetAllAccessibleAssetsOfUserV2ApiResponse,
      AssetControllerGetAllAccessibleAssetsOfUserV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/my-library-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetRecentAssetsForUser: build.query<
      AssetControllerGetRecentAssetsForUserApiResponse,
      AssetControllerGetRecentAssetsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/recent`,
        params: {
          limit: queryArg.limit,
          includeSoftDeleted: queryArg.includeSoftDeleted
        }
      })
    }),
    assetControllerGetRecentAssetsForUserV2: build.query<
      AssetControllerGetRecentAssetsForUserV2ApiResponse,
      AssetControllerGetRecentAssetsForUserV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/recent-v2`,
        params: {
          limit: queryArg.limit,
          includeSoftDeleted: queryArg.includeSoftDeleted
        }
      })
    }),
    assetControllerGetPaginatedMyAssets: build.query<
      AssetControllerGetPaginatedMyAssetsApiResponse,
      AssetControllerGetPaginatedMyAssetsApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/my-assets`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetPaginatedMyAssetsV2: build.query<
      AssetControllerGetPaginatedMyAssetsV2ApiResponse,
      AssetControllerGetPaginatedMyAssetsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/my-assets-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetPaginatedMirrorAssets: build.query<
      AssetControllerGetPaginatedMirrorAssetsApiResponse,
      AssetControllerGetPaginatedMirrorAssetsApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/mirror-assets`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerGetPaginatedMirrorAssetsV2: build.query<
      AssetControllerGetPaginatedMirrorAssetsV2ApiResponse,
      AssetControllerGetPaginatedMirrorAssetsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/mirror-assets-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets,
          '': queryArg['']
        }
      })
    }),
    assetControllerFindAllForUser: build.query<
      AssetControllerFindAllForUserApiResponse,
      AssetControllerFindAllForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/user/${queryArg.targetUserId}`,
        params: { field: queryArg.field, search: queryArg.search }
      })
    }),
    assetControllerFindOneAssetUsage: build.query<
      AssetControllerFindOneAssetUsageApiResponse,
      AssetControllerFindOneAssetUsageApiArg
    >({
      query: (queryArg) => ({ url: `/asset/usage/${queryArg.assetId}` })
    }),
    assetControllerGetAssetsByTag: build.query<
      AssetControllerGetAssetsByTagApiResponse,
      AssetControllerGetAssetsByTagApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/tag`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets
        }
      })
    }),
    assetControllerAddTagToAssetsWithRoleChecks: build.mutation<
      AssetControllerAddTagToAssetsWithRoleChecksApiResponse,
      AssetControllerAddTagToAssetsWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/tag`,
        method: 'POST',
        body: queryArg.addTagToAssetDto
      })
    }),
    assetControllerUpdateAssetTagsByTypeWithRoleChecks: build.mutation<
      AssetControllerUpdateAssetTagsByTypeWithRoleChecksApiResponse,
      AssetControllerUpdateAssetTagsByTypeWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/tag`,
        method: 'PATCH',
        body: queryArg.updateAssetTagsDto
      })
    }),
    assetControllerDeleteTagFromAssetWithRoleChecks: build.mutation<
      AssetControllerDeleteTagFromAssetWithRoleChecksApiResponse,
      AssetControllerDeleteTagFromAssetWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/tag/${queryArg.assetId}/${queryArg.tagType}/${queryArg.tagName}`,
        method: 'DELETE'
      })
    }),
    assetControllerFindOne: build.query<
      AssetControllerFindOneApiResponse,
      AssetControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/asset/${queryArg.id}` })
    }),
    assetControllerUpdate: build.mutation<
      AssetControllerUpdateApiResponse,
      AssetControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateAssetDto
      })
    }),
    assetControllerRemove: build.mutation<
      AssetControllerRemoveApiResponse,
      AssetControllerRemoveApiArg
    >({
      query: (queryArg) => ({ url: `/asset/${queryArg.id}`, method: 'DELETE' })
    }),
    assetControllerUndoAssetSoftDelete: build.mutation<
      AssetControllerUndoAssetSoftDeleteApiResponse,
      AssetControllerUndoAssetSoftDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/undo-soft-delete/${queryArg.assetId}`,
        method: 'POST'
      })
    }),
    assetControllerUpload: build.mutation<
      AssetControllerUploadApiResponse,
      AssetControllerUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.assetId}/upload`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    assetControllerUploadPublic: build.mutation<
      AssetControllerUploadPublicApiResponse,
      AssetControllerUploadPublicApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.id}/upload/public`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    assetControllerUploadThumbnail: build.mutation<
      AssetControllerUploadThumbnailApiResponse,
      AssetControllerUploadThumbnailApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.id}/upload/thumbnail`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    assetControllerGetAsset: build.query<
      AssetControllerGetAssetApiResponse,
      AssetControllerGetAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/by/start-item`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          type: queryArg['type'],
          assetType: queryArg.assetType,
          assetTypes: queryArg.assetTypes,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          populate: queryArg.populate,
          includeSoftDeleted: queryArg.includeSoftDeleted,
          mirrorAssetManagerUserSortKey: queryArg.mirrorAssetManagerUserSortKey,
          includeAssetPackAssets: queryArg.includeAssetPackAssets
        }
      })
    }),
    assetControllerAddAssetPurchaseOption: build.mutation<
      AssetControllerAddAssetPurchaseOptionApiResponse,
      AssetControllerAddAssetPurchaseOptionApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.assetId}/purchase-option`,
        method: 'POST',
        body: queryArg.addAssetPurchaseOptionDto
      })
    }),
    assetControllerDeleteAssetPurchaseOption: build.mutation<
      AssetControllerDeleteAssetPurchaseOptionApiResponse,
      AssetControllerDeleteAssetPurchaseOptionApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/${queryArg.assetId}/purchase-option/${queryArg.purchaseOptionId}`,
        method: 'DELETE',
        body: queryArg.addAssetPurchaseOptionDto
      })
    }),
    assetControllerCheckIfAssetCopied: build.query<
      AssetControllerCheckIfAssetCopiedApiResponse,
      AssetControllerCheckIfAssetCopiedApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/check-if-asset-copied/${queryArg.assetId}`
      })
    }),
    assetControllerCopyFreeAsset: build.mutation<
      AssetControllerCopyFreeAssetApiResponse,
      AssetControllerCopyFreeAssetApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/copy-free-asset/${queryArg.assetId}`,
        method: 'POST'
      })
    }),
    assetControllerGetAssetsListPrice: build.mutation<
      AssetControllerGetAssetsListPriceApiResponse,
      AssetControllerGetAssetsListPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/asset-list/price`,
        method: 'POST',
        body: queryArg.getAssetsPriceDto
      })
    }),
    assetControllerDownloadAsset: build.query<
      AssetControllerDownloadAssetApiResponse,
      AssetControllerDownloadAssetApiArg
    >({
      query: (queryArg) => ({ url: `/asset/download/${queryArg.assetId}` })
    }),
    assetControllerGetAllAssetsBySpaceIdWithRolesCheck: build.query<
      AssetControllerGetAllAssetsBySpaceIdWithRolesCheckApiResponse,
      AssetControllerGetAllAssetsBySpaceIdWithRolesCheckApiArg
    >({
      query: (queryArg) => ({ url: `/asset/space/${queryArg.spaceId}` })
    }),
    assetControllerAddAssetToPackWithRolesCheck: build.mutation<
      AssetControllerAddAssetToPackWithRolesCheckApiResponse,
      AssetControllerAddAssetToPackWithRolesCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/pack/add-asset/${queryArg.packId}/${queryArg.assetId}`,
        method: 'PATCH'
      })
    }),
    assetControllerDeleteAssetFromPackWithRolesCheck: build.mutation<
      AssetControllerDeleteAssetFromPackWithRolesCheckApiResponse,
      AssetControllerDeleteAssetFromPackWithRolesCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/asset/pack/remove-asset/${queryArg.packId}/${queryArg.assetId}`,
        method: 'DELETE'
      })
    }),
    spaceObjectControllerCreate: build.mutation<
      SpaceObjectControllerCreateApiResponse,
      SpaceObjectControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/space`,
        method: 'POST',
        body: queryArg.createSpaceObjectDto
      })
    }),
    spaceObjectControllerCreateAlias: build.mutation<
      SpaceObjectControllerCreateAliasApiResponse,
      SpaceObjectControllerCreateAliasApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object`,
        method: 'POST',
        body: queryArg.createSpaceObjectDto
      })
    }),
    spaceObjectControllerCopy: build.mutation<
      SpaceObjectControllerCopyApiResponse,
      SpaceObjectControllerCopyApiArg
    >({
      query: () => ({ url: `/space-object/copy`, method: 'POST' })
    }),
    spaceObjectControllerFindAllBySpaceId: build.query<
      SpaceObjectControllerFindAllBySpaceIdApiResponse,
      SpaceObjectControllerFindAllBySpaceIdApiArg
    >({
      query: (queryArg) => ({ url: `/space-object/space/${queryArg.id}` })
    }),
    spaceObjectControllerFindAllBySpaceIdWithRolesCheck: build.query<
      SpaceObjectControllerFindAllBySpaceIdWithRolesCheckApiResponse,
      SpaceObjectControllerFindAllBySpaceIdWithRolesCheckApiArg
    >({
      query: (queryArg) => ({ url: `/space-object/space-v2/${queryArg.id}` })
    }),
    spaceObjectControllerGetSpaceObjectsByTag: build.query<
      SpaceObjectControllerGetSpaceObjectsByTagApiResponse,
      SpaceObjectControllerGetSpaceObjectsByTagApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/tag`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType
        }
      })
    }),
    spaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecks:
      build.mutation<
        SpaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecksApiResponse,
        SpaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecksApiArg
      >({
        query: (queryArg) => ({
          url: `/space-object/tag`,
          method: 'PATCH',
          body: queryArg.updateSpaceObjectTagsDto
        })
      }),
    spaceObjectControllerAddTagToSpaceObjectWithRoleChecks: build.mutation<
      SpaceObjectControllerAddTagToSpaceObjectWithRoleChecksApiResponse,
      SpaceObjectControllerAddTagToSpaceObjectWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/tag`,
        method: 'POST',
        body: queryArg.addTagToSpaceObjectDto
      })
    }),
    spaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecks: build.mutation<
      SpaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecksApiResponse,
      SpaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/tag/${queryArg.spaceObjectId}/${queryArg.tagType}/${queryArg.tagName}`,
        method: 'DELETE'
      })
    }),
    spaceObjectControllerSearchSpaceObjectsPaginated: build.query<
      SpaceObjectControllerSearchSpaceObjectsPaginatedApiResponse,
      SpaceObjectControllerSearchSpaceObjectsPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/search`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          '': queryArg['']
        }
      })
    }),
    spaceObjectControllerFindOne: build.query<
      SpaceObjectControllerFindOneApiResponse,
      SpaceObjectControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/space-object/${queryArg.id}` })
    }),
    spaceObjectControllerUpdate: build.mutation<
      SpaceObjectControllerUpdateApiResponse,
      SpaceObjectControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateSpaceObjectDto
      })
    }),
    spaceObjectControllerRemove: build.mutation<
      SpaceObjectControllerRemoveApiResponse,
      SpaceObjectControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/space-object/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    scriptEntityControllerGetRecentScripts: build.query<
      ScriptEntityControllerGetRecentScriptsApiResponse,
      ScriptEntityControllerGetRecentScriptsApiArg
    >({
      query: () => ({ url: `/script-entity/recents` })
    }),
    scriptEntityControllerCreate: build.mutation<
      ScriptEntityControllerCreateApiResponse,
      ScriptEntityControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/script-entity`,
        method: 'POST',
        body: queryArg.createScriptEntityDto
      })
    }),
    scriptEntityControllerFindOne: build.query<
      ScriptEntityControllerFindOneApiResponse,
      ScriptEntityControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/script-entity/${queryArg.id}` })
    }),
    scriptEntityControllerUpdate: build.mutation<
      ScriptEntityControllerUpdateApiResponse,
      ScriptEntityControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/script-entity/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateScriptEntityDto
      })
    }),
    scriptEntityControllerDelete: build.mutation<
      ScriptEntityControllerDeleteApiResponse,
      ScriptEntityControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/script-entity/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    userControllerFindOne: build.query<
      UserControllerFindOneApiResponse,
      UserControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/user/id/${queryArg.id}` })
    }),
    userControllerFindOneWithPublicProfile: build.query<
      UserControllerFindOneWithPublicProfileApiResponse,
      UserControllerFindOneWithPublicProfileApiArg
    >({
      query: (queryArg) => ({ url: `/user/${queryArg.id}/public-profile` })
    }),
    userControllerSearch: build.query<
      UserControllerSearchApiResponse,
      UserControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/user/search`,
        params: { query: queryArg.query }
      })
    }),
    userControllerGetCurrentUser: build.query<
      UserControllerGetCurrentUserApiResponse,
      UserControllerGetCurrentUserApiArg
    >({
      query: () => ({ url: `/user/me` })
    }),
    userControllerGetUserRecents: build.query<
      UserControllerGetUserRecentsApiResponse,
      UserControllerGetUserRecentsApiArg
    >({
      query: () => ({ url: `/user/recents/me` })
    }),
    userControllerUploadPublic: build.mutation<
      UserControllerUploadPublicApiResponse,
      UserControllerUploadPublicApiArg
    >({
      query: (queryArg) => ({
        url: `/user/upload/public`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    userControllerUpdateProfile: build.mutation<
      UserControllerUpdateProfileApiResponse,
      UserControllerUpdateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/user/profile`,
        method: 'PATCH',
        body: queryArg.updateUserProfileDto
      })
    }),
    userControllerUpdateUserTutorial: build.mutation<
      UserControllerUpdateUserTutorialApiResponse,
      UserControllerUpdateUserTutorialApiArg
    >({
      query: (queryArg) => ({
        url: `/user/tutorial`,
        method: 'PATCH',
        body: queryArg.updateUserTutorialDto
      })
    }),
    userControllerUpdateDeepLink: build.mutation<
      UserControllerUpdateDeepLinkApiResponse,
      UserControllerUpdateDeepLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/user/deep-link`,
        method: 'PATCH',
        body: queryArg.updateUserDeepLinkDto
      })
    }),
    userControllerUpdateAvatar: build.mutation<
      UserControllerUpdateAvatarApiResponse,
      UserControllerUpdateAvatarApiArg
    >({
      query: (queryArg) => ({
        url: `/user/avatar`,
        method: 'PATCH',
        body: queryArg.updateUserAvatarDto
      })
    }),
    userControllerUpdateTermsAgreedTo: build.mutation<
      UserControllerUpdateTermsAgreedToApiResponse,
      UserControllerUpdateTermsAgreedToApiArg
    >({
      query: (queryArg) => ({
        url: `/user/terms`,
        method: 'PATCH',
        body: queryArg.updateUserTermsDto
      })
    }),
    userControllerUpdateAvatarType: build.mutation<
      UserControllerUpdateAvatarTypeApiResponse,
      UserControllerUpdateAvatarTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/user/avatar-type`,
        method: 'PATCH',
        body: queryArg.updateUserAvatarTypeDto
      })
    }),
    userControllerGetUserEntityAction: build.query<
      UserControllerGetUserEntityActionApiResponse,
      UserControllerGetUserEntityActionApiArg
    >({
      query: (queryArg) => ({
        url: `/user/entity-action/for-entity/${queryArg.entityId}`
      })
    }),
    userControllerGetUserEntityActionsByMeForEntity: build.query<
      UserControllerGetUserEntityActionsByMeForEntityApiResponse,
      UserControllerGetUserEntityActionsByMeForEntityApiArg
    >({
      query: (queryArg) => ({
        url: `/user/entity-action/me/for-entity/${queryArg.entityId}`
      })
    }),
    userControllerUpsertUserEntityAction: build.mutation<
      UserControllerUpsertUserEntityActionApiResponse,
      UserControllerUpsertUserEntityActionApiArg
    >({
      query: (queryArg) => ({
        url: `/user/entity-action`,
        method: 'PATCH',
        body: queryArg.upsertUserEntityActionDto
      })
    }),
    userControllerDeleteUserEntityAction: build.mutation<
      UserControllerDeleteUserEntityActionApiResponse,
      UserControllerDeleteUserEntityActionApiArg
    >({
      query: (queryArg) => ({
        url: `/user/entity-action/${queryArg.userEntityActionId}`,
        method: 'DELETE'
      })
    }),
    userControllerGetMyFriends: build.query<
      UserControllerGetMyFriendsApiResponse,
      UserControllerGetMyFriendsApiArg
    >({
      query: () => ({ url: `/user/friends/me` })
    }),
    userControllerGetMyFriendRequests: build.query<
      UserControllerGetMyFriendRequestsApiResponse,
      UserControllerGetMyFriendRequestsApiArg
    >({
      query: () => ({ url: `/user/friend-requests/me` })
    }),
    userControllerAcceptFriendRequest: build.mutation<
      UserControllerAcceptFriendRequestApiResponse,
      UserControllerAcceptFriendRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/user/friend-requests/accept/${queryArg.fromUserId}`,
        method: 'POST'
      })
    }),
    userControllerRejectFriendRequest: build.mutation<
      UserControllerRejectFriendRequestApiResponse,
      UserControllerRejectFriendRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/user/friend-requests/reject/${queryArg.fromUserId}`,
        method: 'POST'
      })
    }),
    userControllerGetSentFriendRequests: build.query<
      UserControllerGetSentFriendRequestsApiResponse,
      UserControllerGetSentFriendRequestsApiArg
    >({
      query: () => ({ url: `/user/friend-requests/sent` })
    }),
    userControllerSendFriendRequest: build.mutation<
      UserControllerSendFriendRequestApiResponse,
      UserControllerSendFriendRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/user/friend-requests/${queryArg.toUserId}`,
        method: 'POST'
      })
    }),
    userControllerRemoveFriend: build.mutation<
      UserControllerRemoveFriendApiResponse,
      UserControllerRemoveFriendApiArg
    >({
      query: (queryArg) => ({
        url: `/user/friends/${queryArg.friendUserIdToRemove}`,
        method: 'DELETE'
      })
    }),
    userControllerGetUserCart: build.query<
      UserControllerGetUserCartApiResponse,
      UserControllerGetUserCartApiArg
    >({
      query: () => ({ url: `/user/cart` })
    }),
    userControllerAddUserCartItemToUserCart: build.mutation<
      UserControllerAddUserCartItemToUserCartApiResponse,
      UserControllerAddUserCartItemToUserCartApiArg
    >({
      query: (queryArg) => ({
        url: `/user/cart`,
        method: 'POST',
        body: queryArg.addUserCartItemToUserCartDto
      })
    }),
    userControllerRemoveAllUserItemsFromCart: build.mutation<
      UserControllerRemoveAllUserItemsFromCartApiResponse,
      UserControllerRemoveAllUserItemsFromCartApiArg
    >({
      query: () => ({ url: `/user/cart/all`, method: 'DELETE' })
    }),
    userControllerRemoveUserCartItemFromUserCart: build.mutation<
      UserControllerRemoveUserCartItemFromUserCartApiResponse,
      UserControllerRemoveUserCartItemFromUserCartApiArg
    >({
      query: (queryArg) => ({
        url: `/user/cart/${queryArg.cartItemId}`,
        method: 'DELETE'
      })
    }),
    userControllerAddRpmAvatarUrl: build.mutation<
      UserControllerAddRpmAvatarUrlApiResponse,
      UserControllerAddRpmAvatarUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/user/rpm-avatar-url`,
        method: 'POST',
        body: queryArg.addRpmAvatarUrlDto
      })
    }),
    userControllerRemoveRpmAvatarUrl: build.mutation<
      UserControllerRemoveRpmAvatarUrlApiResponse,
      UserControllerRemoveRpmAvatarUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/user/rpm-avatar-url`,
        method: 'DELETE',
        body: queryArg.removeRpmAvatarUrlDto
      })
    }),
    userControllerCreateSignUpKey: build.mutation<
      UserControllerCreateSignUpKeyApiResponse,
      UserControllerCreateSignUpKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/user/access-key`,
        method: 'POST',
        body: queryArg.createUserAccessKeyDto
      })
    }),
    userControllerSubmitUserAccessKey: build.mutation<
      UserControllerSubmitUserAccessKeyApiResponse,
      UserControllerSubmitUserAccessKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/user/submit-user-access-key`,
        method: 'POST',
        body: queryArg.submitUserAccessKeyDto
      })
    }),
    userControllerGetUserSidebarTags: build.query<
      UserControllerGetUserSidebarTagsApiResponse,
      UserControllerGetUserSidebarTagsApiArg
    >({
      query: () => ({ url: `/user/sidebar-tags` })
    }),
    userControllerAddUserSidebarTag: build.mutation<
      UserControllerAddUserSidebarTagApiResponse,
      UserControllerAddUserSidebarTagApiArg
    >({
      query: (queryArg) => ({
        url: `/user/sidebar-tags`,
        method: 'POST',
        body: queryArg.addUserSidebarTagDto
      })
    }),
    userControllerUpdateUserSidebarTags: build.mutation<
      UserControllerUpdateUserSidebarTagsApiResponse,
      UserControllerUpdateUserSidebarTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/sidebar-tags`,
        method: 'PATCH',
        body: queryArg.updateUserSidebarTagsDto
      })
    }),
    userControllerDeleteUserSidebarTag: build.mutation<
      UserControllerDeleteUserSidebarTagApiResponse,
      UserControllerDeleteUserSidebarTagApiArg
    >({
      query: (queryArg) => ({
        url: `/user/sidebar-tags/${queryArg.sidebarTag}`,
        method: 'DELETE'
      })
    }),
    fileUploadControllerBatchAssetUploadFromQueueBucket: build.mutation<
      FileUploadControllerBatchAssetUploadFromQueueBucketApiResponse,
      FileUploadControllerBatchAssetUploadFromQueueBucketApiArg
    >({
      query: () => ({
        url: `/file-upload/batch-upload-assets-from-bucket`,
        method: 'POST'
      })
    }),
    spaceControllerGetAllPublicForUser: build.query<
      SpaceControllerGetAllPublicForUserApiResponse,
      SpaceControllerGetAllPublicForUserApiArg
    >({
      query: (queryArg) => ({ url: `/space/user/${queryArg.id}` })
    }),
    spaceControllerGetAllPublicForUserV2: build.query<
      SpaceControllerGetAllPublicForUserV2ApiResponse,
      SpaceControllerGetAllPublicForUserV2ApiArg
    >({
      query: (queryArg) => ({ url: `/space/user-v2/${queryArg.id}` })
    }),
    spaceControllerSearch: build.query<
      SpaceControllerSearchApiResponse,
      SpaceControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/space/search`,
        params: { query: queryArg.query }
      })
    }),
    spaceControllerSearchV2: build.query<
      SpaceControllerSearchV2ApiResponse,
      SpaceControllerSearchV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/search-v2`,
        params: { query: queryArg.query }
      })
    }),
    spaceControllerFindAllForMeWhereOwner: build.query<
      SpaceControllerFindAllForMeWhereOwnerApiResponse,
      SpaceControllerFindAllForMeWhereOwnerApiArg
    >({
      query: () => ({ url: `/space/me` })
    }),
    spaceControllerFindAllForMeWhereOwnerPaginatedV2: build.query<
      SpaceControllerFindAllForMeWhereOwnerPaginatedV2ApiResponse,
      SpaceControllerFindAllForMeWhereOwnerPaginatedV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/me-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerFindAllForMeWhereOwnerPaginatedV3: build.query<
      SpaceControllerFindAllForMeWhereOwnerPaginatedV3ApiResponse,
      SpaceControllerFindAllForMeWhereOwnerPaginatedV3ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/me-v3`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerGetPopularSpaces: build.query<
      SpaceControllerGetPopularSpacesApiResponse,
      SpaceControllerGetPopularSpacesApiArg
    >({
      query: (queryArg) => ({
        url: `/space/popular`,
        params: {
          populateCreator: queryArg.populateCreator,
          populateUsersPresent: queryArg.populateUsersPresent,
          '': queryArg['']
        }
      })
    }),
    spaceControllerGetFavoriteSpaces: build.query<
      SpaceControllerGetFavoriteSpacesApiResponse,
      SpaceControllerGetFavoriteSpacesApiArg
    >({
      query: (queryArg) => ({
        url: `/space/favorites`,
        params: {
          populateCreator: queryArg.populateCreator,
          populateUsersPresent: queryArg.populateUsersPresent,
          '': queryArg['']
        }
      })
    }),
    spaceControllerGetRecentSpaces: build.query<
      SpaceControllerGetRecentSpacesApiResponse,
      SpaceControllerGetRecentSpacesApiArg
    >({
      query: (queryArg) => ({
        url: `/space/recents`,
        params: {
          populateCreator: queryArg.populateCreator,
          populateUsersPresent: queryArg.populateUsersPresent,
          '': queryArg['']
        }
      })
    }),
    spaceControllerGetSpacesByTags: build.query<
      SpaceControllerGetSpacesByTagsApiResponse,
      SpaceControllerGetSpacesByTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/space/tag`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerAddTagToSpaceWithRoleChecks: build.mutation<
      SpaceControllerAddTagToSpaceWithRoleChecksApiResponse,
      SpaceControllerAddTagToSpaceWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/space/tag`,
        method: 'POST',
        body: queryArg.addTagToSpaceDto
      })
    }),
    spaceControllerUpdateSpaceTagsByTypeWithRoleChecks: build.mutation<
      SpaceControllerUpdateSpaceTagsByTypeWithRoleChecksApiResponse,
      SpaceControllerUpdateSpaceTagsByTypeWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/space/tag`,
        method: 'PATCH',
        body: queryArg.updateSpaceTagsDto
      })
    }),
    spaceControllerDeleteTagFromSpaceWithRoleChecks: build.mutation<
      SpaceControllerDeleteTagFromSpaceWithRoleChecksApiResponse,
      SpaceControllerDeleteTagFromSpaceWithRoleChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/space/tag/${queryArg.spaceId}/${queryArg.tagType}/${queryArg.tagName}`,
        method: 'DELETE'
      })
    }),
    spaceControllerFindDiscoverSpacesForUser: build.query<
      SpaceControllerFindDiscoverSpacesForUserApiResponse,
      SpaceControllerFindDiscoverSpacesForUserApiArg
    >({
      query: () => ({ url: `/space/discover` })
    }),
    spaceControllerFindDiscoverSpacesForUserPaginatedV2: build.query<
      SpaceControllerFindDiscoverSpacesForUserPaginatedV2ApiResponse,
      SpaceControllerFindDiscoverSpacesForUserPaginatedV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/discover-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerFindDiscoverSpacesForUserPaginatedV3: build.query<
      SpaceControllerFindDiscoverSpacesForUserPaginatedV3ApiResponse,
      SpaceControllerFindDiscoverSpacesForUserPaginatedV3ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/discover-v3`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerFindSpaceTemplates: build.query<
      SpaceControllerFindSpaceTemplatesApiResponse,
      SpaceControllerFindSpaceTemplatesApiArg
    >({
      query: () => ({ url: `/space/templates` })
    }),
    spaceControllerGetPublishedSpaces: build.query<
      SpaceControllerGetPublishedSpacesApiResponse,
      SpaceControllerGetPublishedSpacesApiArg
    >({
      query: (queryArg) => ({
        url: `/space/get-published-spaces`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems
        }
      })
    }),
    spaceControllerGetPublishedSpacesV2: build.query<
      SpaceControllerGetPublishedSpacesV2ApiResponse,
      SpaceControllerGetPublishedSpacesV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/space/get-published-spaces-v2`,
        params: {
          field: queryArg.field,
          search: queryArg.search,
          sortKey: queryArg.sortKey,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          perPage: queryArg.perPage,
          tag: queryArg.tag,
          tagType: queryArg.tagType,
          startItem: queryArg.startItem,
          numberOfItems: queryArg.numberOfItems,
          populateCreator: queryArg.populateCreator,
          populateUsersPresent: queryArg.populateUsersPresent
        }
      })
    }),
    spaceControllerGetLatestPublishedSpaceBySpaceId: build.query<
      SpaceControllerGetLatestPublishedSpaceBySpaceIdApiResponse,
      SpaceControllerGetLatestPublishedSpaceBySpaceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/space/latest-published/${queryArg.spaceId}`
      })
    }),
    spaceControllerRefreshStats: build.query<
      SpaceControllerRefreshStatsApiResponse,
      SpaceControllerRefreshStatsApiArg
    >({
      query: (queryArg) => ({ url: `/space/refresh-stats/${queryArg.spaceId}` })
    }),
    spaceControllerFindOne: build.query<
      SpaceControllerFindOneApiResponse,
      SpaceControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}`,
        params: {
          populateCreator: queryArg.populateCreator,
          populateUsersPresent: queryArg.populateUsersPresent
        }
      })
    }),
    spaceControllerUpdate: build.mutation<
      SpaceControllerUpdateApiResponse,
      SpaceControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateSpaceDto
      })
    }),
    spaceControllerRemove: build.mutation<
      SpaceControllerRemoveApiResponse,
      SpaceControllerRemoveApiArg
    >({
      query: (queryArg) => ({ url: `/space/${queryArg.id}`, method: 'DELETE' })
    }),
    spaceControllerFindAllForUserV2: build.query<
      SpaceControllerFindAllForUserV2ApiResponse,
      SpaceControllerFindAllForUserV2ApiArg
    >({
      query: () => ({ url: `/space/v2` })
    }),
    spaceControllerFindAllForUser: build.query<
      SpaceControllerFindAllForUserApiResponse,
      SpaceControllerFindAllForUserApiArg
    >({
      query: () => ({ url: `/space` })
    }),
    spaceControllerCreate: build.mutation<
      SpaceControllerCreateApiResponse,
      SpaceControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/space`,
        method: 'POST',
        body: queryArg.createSpaceDto
      })
    }),
    spaceControllerClearVoxels: build.mutation<
      SpaceControllerClearVoxelsApiResponse,
      SpaceControllerClearVoxelsApiArg
    >({
      query: (queryArg) => ({
        url: `/space/voxels/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    spaceControllerCopy: build.mutation<
      SpaceControllerCopyApiResponse,
      SpaceControllerCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/space/copy/${queryArg.id}`,
        method: 'POST'
      })
    }),
    spaceControllerCopyFromTemplate: build.mutation<
      SpaceControllerCopyFromTemplateApiResponse,
      SpaceControllerCopyFromTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/space/copy-from-template/${queryArg.id}`,
        method: 'POST',
        body: queryArg.spaceCopyFromTemplateDto
      })
    }),
    spaceControllerRemixSpace: build.mutation<
      SpaceControllerRemixSpaceApiResponse,
      SpaceControllerRemixSpaceApiArg
    >({
      query: (queryArg) => ({
        url: `/space/remix-space/${queryArg.id}`,
        method: 'POST',
        body: queryArg.remixSpaceDto
      })
    }),
    spaceControllerPublish: build.mutation<
      SpaceControllerPublishApiResponse,
      SpaceControllerPublishApiArg
    >({
      query: (queryArg) => ({
        url: `/space/version/${queryArg.id}`,
        method: 'POST',
        body: queryArg.createNewSpaceVersionDto
      })
    }),
    spaceControllerGetPublishedSpacesBySpaceId: build.query<
      SpaceControllerGetPublishedSpacesBySpaceIdApiResponse,
      SpaceControllerGetPublishedSpacesBySpaceIdApiArg
    >({
      query: (queryArg) => ({ url: `/space/version/${queryArg.id}` })
    }),
    spaceControllerUploadPublic: build.mutation<
      SpaceControllerUploadPublicApiResponse,
      SpaceControllerUploadPublicApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}/upload/public`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    spaceControllerRestoreSpaceFromSpaceVersion: build.mutation<
      SpaceControllerRestoreSpaceFromSpaceVersionApiResponse,
      SpaceControllerRestoreSpaceFromSpaceVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/space/restore-space-from-version/${queryArg.id}`,
        method: 'POST'
      })
    }),
    spaceControllerSetUserRoleForOne: build.mutation<
      SpaceControllerSetUserRoleForOneApiResponse,
      SpaceControllerSetUserRoleForOneApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}/role/set`,
        method: 'PATCH',
        body: queryArg.setUserRoleForOneDto
      })
    }),
    spaceControllerRemoveUserRoleForOne: build.mutation<
      SpaceControllerRemoveUserRoleForOneApiResponse,
      SpaceControllerRemoveUserRoleForOneApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}/role/unset`,
        method: 'PATCH',
        body: queryArg.removeUserRoleForOneDto
      })
    }),
    spaceControllerKickMe: build.mutation<
      SpaceControllerKickMeApiResponse,
      SpaceControllerKickMeApiArg
    >({
      query: (queryArg) => ({
        url: `/space/${queryArg.id}/kickme`,
        method: 'POST'
      })
    }),
    spaceControllerGetAssetsListPerSpace: build.query<
      SpaceControllerGetAssetsListPerSpaceApiResponse,
      SpaceControllerGetAssetsListPerSpaceApiArg
    >({
      query: (queryArg) => ({ url: `/space/assets-list/${queryArg.spaceId}` })
    }),
    spaceGodotServerControllerGetLatestPublishedSpaceBySpaceId: build.query<
      SpaceGodotServerControllerGetLatestPublishedSpaceBySpaceIdApiResponse,
      SpaceGodotServerControllerGetLatestPublishedSpaceBySpaceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/space-godot-server/latest/${queryArg.id}`
      })
    }),
    spaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceId:
      build.query<
        SpaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceIdApiResponse,
        SpaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/space-godot-server/active/${queryArg.id}`
        })
      }),
    spaceGodotServerControllerUpdateTerrain: build.mutation<
      SpaceGodotServerControllerUpdateTerrainApiResponse,
      SpaceGodotServerControllerUpdateTerrainApiArg
    >({
      query: (queryArg) => ({
        url: `/space-godot-server/voxels/${queryArg.id}`,
        method: 'PUT'
      })
    }),
    materialInstanceControllerCreate: build.mutation<
      MaterialInstanceControllerCreateApiResponse,
      MaterialInstanceControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/space/material-instance`,
        method: 'POST',
        body: queryArg.createMaterialInstanceDto
      })
    }),
    materialInstanceControllerFindOne: build.query<
      MaterialInstanceControllerFindOneApiResponse,
      MaterialInstanceControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/space/material-instance/${queryArg.spaceId}/${queryArg.materialInstanceId}`
      })
    }),
    materialInstanceControllerUpdate: build.mutation<
      MaterialInstanceControllerUpdateApiResponse,
      MaterialInstanceControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/space/material-instance/${queryArg.spaceId}/${queryArg.materialInstanceId}`,
        method: 'PATCH',
        body: queryArg.updateMaterialInstanceDto
      })
    }),
    materialInstanceControllerDelete: build.mutation<
      MaterialInstanceControllerDeleteApiResponse,
      MaterialInstanceControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/space/material-instance/${queryArg.spaceId}/${queryArg.materialInstanceId}`,
        method: 'DELETE'
      })
    }),
    terrainControllerFindAllForUser: build.query<
      TerrainControllerFindAllForUserApiResponse,
      TerrainControllerFindAllForUserApiArg
    >({
      query: () => ({ url: `/terrain` })
    }),
    terrainControllerCreate: build.mutation<
      TerrainControllerCreateApiResponse,
      TerrainControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/terrain`,
        method: 'POST',
        body: queryArg.createTerrainDto
      })
    }),
    terrainControllerFindAllPublic: build.query<
      TerrainControllerFindAllPublicApiResponse,
      TerrainControllerFindAllPublicApiArg
    >({
      query: () => ({ url: `/terrain/public` })
    }),
    terrainControllerFindOne: build.query<
      TerrainControllerFindOneApiResponse,
      TerrainControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/terrain/${queryArg.id}` })
    }),
    terrainControllerUpdate: build.mutation<
      TerrainControllerUpdateApiResponse,
      TerrainControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/terrain/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateTerrainDto
      })
    }),
    terrainControllerRemove: build.mutation<
      TerrainControllerRemoveApiResponse,
      TerrainControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/terrain/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    environmentControllerCreate: build.mutation<
      EnvironmentControllerCreateApiResponse,
      EnvironmentControllerCreateApiArg
    >({
      query: () => ({ url: `/environment`, method: 'POST' })
    }),
    environmentControllerFindOne: build.query<
      EnvironmentControllerFindOneApiResponse,
      EnvironmentControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/environment/${queryArg.id}` })
    }),
    environmentControllerUpdate: build.mutation<
      EnvironmentControllerUpdateApiResponse,
      EnvironmentControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/environment/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateEnvironmentDto
      })
    }),
    environmentControllerRemove: build.mutation<
      EnvironmentControllerRemoveApiResponse,
      EnvironmentControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/environment/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    userGroupControllerCreate: build.mutation<
      UserGroupControllerCreateApiResponse,
      UserGroupControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/user-group`,
        method: 'POST',
        body: queryArg.createUserGroupDto
      })
    }),
    userGroupControllerGetAllGroupsForMe: build.query<
      UserGroupControllerGetAllGroupsForMeApiResponse,
      UserGroupControllerGetAllGroupsForMeApiArg
    >({
      query: () => ({ url: `/user-group/my-groups` })
    }),
    userGroupControllerGetAllGroupInvitesForMe: build.query<
      UserGroupControllerGetAllGroupInvitesForMeApiResponse,
      UserGroupControllerGetAllGroupInvitesForMeApiArg
    >({
      query: () => ({ url: `/user-group/my-invites` })
    }),
    userGroupControllerFindPublicGroupMembershipForOtherUser: build.query<
      UserGroupControllerFindPublicGroupMembershipForOtherUserApiResponse,
      UserGroupControllerFindPublicGroupMembershipForOtherUserApiArg
    >({
      query: (queryArg) => ({
        url: `/user-group/group-membership/${queryArg.otherUserId}`
      })
    }),
    userGroupControllerGetGroupMembershipForMe: build.query<
      UserGroupControllerGetGroupMembershipForMeApiResponse,
      UserGroupControllerGetGroupMembershipForMeApiArg
    >({
      query: (queryArg) => ({
        url: `/user-group/my-group-membership/${queryArg.id}`
      })
    }),
    userGroupControllerSearch: build.query<
      UserGroupControllerSearchApiResponse,
      UserGroupControllerSearchApiArg
    >({
      query: () => ({ url: `/user-group/search` })
    }),
    userGroupControllerFindOne: build.query<
      UserGroupControllerFindOneApiResponse,
      UserGroupControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/user-group/${queryArg.id}` })
    }),
    userGroupControllerUpdate: build.mutation<
      UserGroupControllerUpdateApiResponse,
      UserGroupControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/user-group/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserGroupDto
      })
    }),
    userGroupControllerRemove: build.mutation<
      UserGroupControllerRemoveApiResponse,
      UserGroupControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/user-group/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    zoneControllerJoinBuildServer: build.query<
      ZoneControllerJoinBuildServerApiResponse,
      ZoneControllerJoinBuildServerApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/join-build-server/${queryArg.spaceId}`
      })
    }),
    zoneControllerGetPlayServersForSpaceVersionId: build.query<
      ZoneControllerGetPlayServersForSpaceVersionIdApiResponse,
      ZoneControllerGetPlayServersForSpaceVersionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/list-play-servers/${queryArg.spaceVersionId}`
      })
    }),
    zoneControllerGetPlayServersForSpaceId: build.query<
      ZoneControllerGetPlayServersForSpaceIdApiResponse,
      ZoneControllerGetPlayServersForSpaceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/list-play-servers/space/${queryArg.spaceId}`,
        params: { populateOwner: queryArg.populateOwner }
      })
    }),
    zoneControllerJoinPlayServerByZoneId: build.query<
      ZoneControllerJoinPlayServerByZoneIdApiResponse,
      ZoneControllerJoinPlayServerByZoneIdApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/join-play-server/zone/${queryArg.zoneId}`
      })
    }),
    zoneControllerJoinPlayServerBySpaceId: build.query<
      ZoneControllerJoinPlayServerBySpaceIdApiResponse,
      ZoneControllerJoinPlayServerBySpaceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/join-play-server/space/${queryArg.spaceId}`
      })
    }),
    zoneControllerCreatePlayServerWithSpaceVersion: build.mutation<
      ZoneControllerCreatePlayServerWithSpaceVersionApiResponse,
      ZoneControllerCreatePlayServerWithSpaceVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/create-play-server/${queryArg.spaceVersionId}`,
        method: 'POST',
        body: queryArg.createPlayServerDto
      })
    }),
    zoneControllerCreatePlayServerWithSpace: build.mutation<
      ZoneControllerCreatePlayServerWithSpaceApiResponse,
      ZoneControllerCreatePlayServerWithSpaceApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/create-play-server/space/${queryArg.spaceId}`,
        method: 'POST',
        body: queryArg.createPlayServerDto
      })
    }),
    zoneControllerFindAllZonesBySpaceId: build.query<
      ZoneControllerFindAllZonesBySpaceIdApiResponse,
      ZoneControllerFindAllZonesBySpaceIdApiArg
    >({
      query: (queryArg) => ({ url: `/zone/space/${queryArg.spaceId}` })
    }),
    zoneControllerFindAllZonesByUserId: build.query<
      ZoneControllerFindAllZonesByUserIdApiResponse,
      ZoneControllerFindAllZonesByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/zone/user/${queryArg.userId}` })
    }),
    zoneControllerFindOneZone: build.query<
      ZoneControllerFindOneZoneApiResponse,
      ZoneControllerFindOneZoneApiArg
    >({
      query: (queryArg) => ({ url: `/zone/${queryArg.zoneId}` })
    }),
    zoneControllerUpdateOneZone: build.mutation<
      ZoneControllerUpdateOneZoneApiResponse,
      ZoneControllerUpdateOneZoneApiArg
    >({
      query: (queryArg) => ({
        url: `/zone/${queryArg.zoneId}`,
        method: 'PATCH',
        body: queryArg.updateZoneDto
      })
    }),
    zoneControllerStopAllActiveZones: build.mutation<
      ZoneControllerStopAllActiveZonesApiResponse,
      ZoneControllerStopAllActiveZonesApiArg
    >({
      query: () => ({ url: `/zone/admin/stop-all`, method: 'DELETE' })
    }),
    mirrorDbControllerGetRecordFromMirrorDbById: build.query<
      MirrorDbControllerGetRecordFromMirrorDbByIdApiResponse,
      MirrorDbControllerGetRecordFromMirrorDbByIdApiArg
    >({
      query: (queryArg) => ({ url: `/mirror-db/${queryArg.id}` })
    }),
    mirrorDbControllerUpdateRecordInMirrorDbById: build.mutation<
      MirrorDbControllerUpdateRecordInMirrorDbByIdApiResponse,
      MirrorDbControllerUpdateRecordInMirrorDbByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/mirror-db/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateMirrorDbRecordDto
      })
    }),
    mirrorDbControllerDeleteRecordFromMirrorDbById: build.mutation<
      MirrorDbControllerDeleteRecordFromMirrorDbByIdApiResponse,
      MirrorDbControllerDeleteRecordFromMirrorDbByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/mirror-db/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    mirrorDbControllerGetRecordFromMirrorDbBySpaceId: build.query<
      MirrorDbControllerGetRecordFromMirrorDbBySpaceIdApiResponse,
      MirrorDbControllerGetRecordFromMirrorDbBySpaceIdApiArg
    >({
      query: (queryArg) => ({ url: `/mirror-db/space/${queryArg.spaceId}` })
    }),
    mirrorDbControllerGetRecordFromMirrorDbBySpaceVersionId: build.query<
      MirrorDbControllerGetRecordFromMirrorDbBySpaceVersionIdApiResponse,
      MirrorDbControllerGetRecordFromMirrorDbBySpaceVersionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/mirror-db/space-version/${queryArg.spaceVersionId}`
      })
    }),
    tagControllerCreate: build.mutation<
      TagControllerCreateApiResponse,
      TagControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/tag`,
        method: 'POST',
        body: queryArg.createTagDto
      })
    }),
    tagControllerFindAllMirrorPublicLibraryTags: build.query<
      TagControllerFindAllMirrorPublicLibraryTagsApiResponse,
      TagControllerFindAllMirrorPublicLibraryTagsApiArg
    >({
      query: () => ({ url: `/tag/mirror-public-library` })
    }),
    tagControllerFindAllThemeTags: build.query<
      TagControllerFindAllThemeTagsApiResponse,
      TagControllerFindAllThemeTagsApiArg
    >({
      query: () => ({ url: `/tag/theme-tags` })
    }),
    tagControllerGetTagTypes: build.query<
      TagControllerGetTagTypesApiResponse,
      TagControllerGetTagTypesApiArg
    >({
      query: () => ({ url: `/tag/tag-types` })
    }),
    tagControllerFindOne: build.query<
      TagControllerFindOneApiResponse,
      TagControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/tag/${queryArg.id}` })
    }),
    tagControllerUpdate: build.mutation<
      TagControllerUpdateApiResponse,
      TagControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/tag/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateTagDto
      })
    }),
    tagControllerRemove: build.mutation<
      TagControllerRemoveApiResponse,
      TagControllerRemoveApiArg
    >({
      query: (queryArg) => ({ url: `/tag/${queryArg.id}`, method: 'DELETE' })
    }),
    authControllerDiscord: build.mutation<
      AuthControllerDiscordApiResponse,
      AuthControllerDiscordApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/discord`,
        method: 'POST',
        body: queryArg.mintCustomTokenDiscordDto
      })
    }),
    authControllerCreateUserWithEmailPasswordAndType: build.mutation<
      AuthControllerCreateUserWithEmailPasswordAndTypeApiResponse,
      AuthControllerCreateUserWithEmailPasswordAndTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/email-password`,
        method: 'POST',
        body: queryArg.createUserWithEmailPasswordDto
      })
    }),
    authControllerAuthedUserCreate: build.mutation<
      AuthControllerAuthedUserCreateApiResponse,
      AuthControllerAuthedUserCreateApiArg
    >({
      query: () => ({ url: `/auth/auth-user-create`, method: 'POST' })
    }),
    authControllerCreateAnonymousAccount: build.mutation<
      AuthControllerCreateAnonymousAccountApiResponse,
      AuthControllerCreateAnonymousAccountApiArg
    >({
      query: () => ({ url: `/auth/create-anon-user`, method: 'POST' })
    }),
    authControllerConvertAnonymousAccountToFull: build.mutation<
      AuthControllerConvertAnonymousAccountToFullApiResponse,
      AuthControllerConvertAnonymousAccountToFullApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/convert-to-full-account`,
        method: 'POST',
        body: queryArg.createUserWithEmailPasswordDto
      })
    }),
    authControllerDeleteAccount: build.mutation<
      AuthControllerDeleteAccountApiResponse,
      AuthControllerDeleteAccountApiArg
    >({
      query: () => ({ url: `/auth`, method: 'DELETE' })
    }),
    userFeedbackControllerFindNewestPublicUserFeedbackItems: build.query<
      UserFeedbackControllerFindNewestPublicUserFeedbackItemsApiResponse,
      UserFeedbackControllerFindNewestPublicUserFeedbackItemsApiArg
    >({
      query: () => ({ url: `/user-feedback/new` })
    }),
    userFeedbackControllerFindTopPublicUserFeedbackItems: build.query<
      UserFeedbackControllerFindTopPublicUserFeedbackItemsApiResponse,
      UserFeedbackControllerFindTopPublicUserFeedbackItemsApiArg
    >({
      query: () => ({ url: `/user-feedback/top` })
    }),
    userFeedbackControllerGetUserFeedbackItemTypes: build.query<
      UserFeedbackControllerGetUserFeedbackItemTypesApiResponse,
      UserFeedbackControllerGetUserFeedbackItemTypesApiArg
    >({
      query: () => ({ url: `/user-feedback/user-feedback-types` })
    }),
    userFeedbackControllerFindOne: build.query<
      UserFeedbackControllerFindOneApiResponse,
      UserFeedbackControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/user-feedback/${queryArg.id}` })
    }),
    userFeedbackControllerUpdate: build.mutation<
      UserFeedbackControllerUpdateApiResponse,
      UserFeedbackControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserFeedbackDto
      })
    }),
    userFeedbackControllerRemoveUserFeedbackItem: build.mutation<
      UserFeedbackControllerRemoveUserFeedbackItemApiResponse,
      UserFeedbackControllerRemoveUserFeedbackItemApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    userFeedbackControllerFindComments: build.query<
      UserFeedbackControllerFindCommentsApiResponse,
      UserFeedbackControllerFindCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/comments/${queryArg.userFeedbackItemId}`
      })
    }),
    userFeedbackControllerFindAllPublicUserFeedbackItems: build.query<
      UserFeedbackControllerFindAllPublicUserFeedbackItemsApiResponse,
      UserFeedbackControllerFindAllPublicUserFeedbackItemsApiArg
    >({
      query: () => ({ url: `/user-feedback` })
    }),
    userFeedbackControllerCreate: build.mutation<
      UserFeedbackControllerCreateApiResponse,
      UserFeedbackControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback`,
        method: 'POST',
        body: queryArg.createUserFeedbackItemDto
      })
    }),
    userFeedbackControllerVoteOnUserFeedbackItem: build.mutation<
      UserFeedbackControllerVoteOnUserFeedbackItemApiResponse,
      UserFeedbackControllerVoteOnUserFeedbackItemApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/vote`,
        method: 'POST',
        body: queryArg.createVoteOnUserFeedbackItemDto
      })
    }),
    userFeedbackControllerCreateComment: build.mutation<
      UserFeedbackControllerCreateCommentApiResponse,
      UserFeedbackControllerCreateCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/comment`,
        method: 'POST',
        body: queryArg.createUserFeedbackCommentDto
      })
    }),
    userFeedbackControllerRemoveComment: build.mutation<
      UserFeedbackControllerRemoveCommentApiResponse,
      UserFeedbackControllerRemoveCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/user-feedback/comment/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    stripeControllerCreateConnectAccount: build.mutation<
      StripeControllerCreateConnectAccountApiResponse,
      StripeControllerCreateConnectAccountApiArg
    >({
      query: () => ({ url: `/stripe/connect`, method: 'POST' })
    }),
    stripeControllerDeleteConnectAccount: build.mutation<
      StripeControllerDeleteConnectAccountApiResponse,
      StripeControllerDeleteConnectAccountApiArg
    >({
      query: () => ({ url: `/stripe/connect`, method: 'DELETE' })
    }),
    stripeControllerGetCardsList: build.query<
      StripeControllerGetCardsListApiResponse,
      StripeControllerGetCardsListApiArg
    >({
      query: () => ({ url: `/stripe/card` })
    }),
    stripeControllerCreateCard: build.mutation<
      StripeControllerCreateCardApiResponse,
      StripeControllerCreateCardApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/card`,
        method: 'POST',
        body: queryArg.addCardDto
      })
    }),
    stripeControllerDeleteCard: build.mutation<
      StripeControllerDeleteCardApiResponse,
      StripeControllerDeleteCardApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/card/${queryArg.cardId}`,
        method: 'DELETE'
      })
    }),
    stripeControllerGetPaymentMethods: build.query<
      StripeControllerGetPaymentMethodsApiResponse,
      StripeControllerGetPaymentMethodsApiArg
    >({
      query: () => ({ url: `/stripe/payment/methods` })
    }),
    stripeControllerSetDefaultPaymentMethod: build.mutation<
      StripeControllerSetDefaultPaymentMethodApiResponse,
      StripeControllerSetDefaultPaymentMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/payment/methods/default/${queryArg.paymentMethodId}`,
        method: 'POST'
      })
    }),
    stripeControllerCreatePaymentSession: build.mutation<
      StripeControllerCreatePaymentSessionApiResponse,
      StripeControllerCreatePaymentSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/create/payment/session`,
        method: 'POST',
        body: queryArg.createPaymentSessionDto
      })
    }),
    stripeControllerCreatePaymentIntent: build.mutation<
      StripeControllerCreatePaymentIntentApiResponse,
      StripeControllerCreatePaymentIntentApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/intent`,
        method: 'POST',
        body: queryArg.createPaymentIntentDto
      })
    }),
    stripeControllerSetupIntent: build.mutation<
      StripeControllerSetupIntentApiResponse,
      StripeControllerSetupIntentApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/intent/setup`,
        method: 'POST',
        body: queryArg.setupPaymentIntentDto
      })
    }),
    stripeControllerCreateCustomerAccount: build.mutation<
      StripeControllerCreateCustomerAccountApiResponse,
      StripeControllerCreateCustomerAccountApiArg
    >({
      query: () => ({ url: `/stripe/customer`, method: 'POST' })
    }),
    stripeControllerGetStripeAccountInfo: build.query<
      StripeControllerGetStripeAccountInfoApiResponse,
      StripeControllerGetStripeAccountInfoApiArg
    >({
      query: () => ({ url: `/stripe/account/info` })
    }),
    stripeControllerAddBankAccount: build.mutation<
      StripeControllerAddBankAccountApiResponse,
      StripeControllerAddBankAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/account/external/bank`,
        method: 'POST',
        body: queryArg.addExternalBankAccountTokenDto
      })
    }),
    stripeControllerTransfersAmount: build.mutation<
      StripeControllerTransfersAmountApiResponse,
      StripeControllerTransfersAmountApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/transfers`,
        method: 'POST',
        body: queryArg.createTransferDto
      })
    }),
    stripeControllerCreateProduct: build.mutation<
      StripeControllerCreateProductApiResponse,
      StripeControllerCreateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/product`,
        method: 'POST',
        body: queryArg.createProductDto
      })
    }),
    stripeControllerGetAllProductsWithPrice: build.query<
      StripeControllerGetAllProductsWithPriceApiResponse,
      StripeControllerGetAllProductsWithPriceApiArg
    >({
      query: () => ({ url: `/stripe/product` })
    }),
    stripeControllerCreateSubscription: build.mutation<
      StripeControllerCreateSubscriptionApiResponse,
      StripeControllerCreateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/stripe/subscription`,
        method: 'POST',
        body: queryArg.createSubscriptionDto
      })
    }),
    stripeControllerDeleteSubscription: build.mutation<
      StripeControllerDeleteSubscriptionApiResponse,
      StripeControllerDeleteSubscriptionApiArg
    >({
      query: () => ({ url: `/stripe/subscription`, method: 'DELETE' })
    }),
    stripeControllerPauseSubscription: build.mutation<
      StripeControllerPauseSubscriptionApiResponse,
      StripeControllerPauseSubscriptionApiArg
    >({
      query: () => ({ url: `/stripe/subscription/pause`, method: 'PATCH' })
    }),
    stripeControllerResumeSubscription: build.mutation<
      StripeControllerResumeSubscriptionApiResponse,
      StripeControllerResumeSubscriptionApiArg
    >({
      query: () => ({ url: `/stripe/subscription/resume`, method: 'PATCH' })
    }),
    stripeControllerGetSubscriptionStatus: build.query<
      StripeControllerGetSubscriptionStatusApiResponse,
      StripeControllerGetSubscriptionStatusApiArg
    >({
      query: () => ({ url: `/stripe/subscription-status` })
    }),
    stripeControllerCreateDashboardLink: build.query<
      StripeControllerCreateDashboardLinkApiResponse,
      StripeControllerCreateDashboardLinkApiArg
    >({
      query: () => ({ url: `/stripe/link/dashboard` })
    }),
    stripeControllerCreateCustomerPortalLink: build.query<
      StripeControllerCreateCustomerPortalLinkApiResponse,
      StripeControllerCreateCustomerPortalLinkApiArg
    >({
      query: () => ({ url: `/stripe/link/customer-portal` })
    }),
    stripeControllerCreatePaymentLinkForOptionalDonationAssetPayment:
      build.mutation<
        StripeControllerCreatePaymentLinkForOptionalDonationAssetPaymentApiResponse,
        StripeControllerCreatePaymentLinkForOptionalDonationAssetPaymentApiArg
      >({
        query: (queryArg) => ({
          url: `/stripe/payment-link/optinal-donation`,
          method: 'POST',
          body: queryArg.paymentLinkForOptinalDonationAsset
        })
      }),
    stripeControllerHandleStripeWebhook: build.mutation<
      StripeControllerHandleStripeWebhookApiResponse,
      StripeControllerHandleStripeWebhookApiArg
    >({
      query: () => ({ url: `/stripe/webhook`, method: 'POST' })
    }),
    blockControllerCreate: build.mutation<
      BlockControllerCreateApiResponse,
      BlockControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/block`,
        method: 'POST',
        body: queryArg.createBlockDto
      })
    }),
    blockControllerFindOne: build.query<
      BlockControllerFindOneApiResponse,
      BlockControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/block/${queryArg.id}` })
    }),
    blockControllerUpdate: build.mutation<
      BlockControllerUpdateApiResponse,
      BlockControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/block/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateBlockDto
      })
    }),
    blockControllerRemove: build.mutation<
      BlockControllerRemoveApiResponse,
      BlockControllerRemoveApiArg
    >({
      query: (queryArg) => ({ url: `/block/${queryArg.id}`, method: 'DELETE' })
    }),
    transferBetweenEnvironmentsControllerTransferSpace: build.mutation<
      TransferBetweenEnvironmentsControllerTransferSpaceApiResponse,
      TransferBetweenEnvironmentsControllerTransferSpaceApiArg
    >({
      query: (queryArg) => ({
        url: `/transfer-between-environments/trasfer-space`,
        method: 'POST',
        body: queryArg.transferSpaceDto
      })
    }),
    chatControllerSendMessage: build.mutation<
      ChatControllerSendMessageApiResponse,
      ChatControllerSendMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/chat`,
        method: 'POST',
        body: queryArg.sendChatMessageDto
      })
    }),
    chatControllerSendMessageToChannel: build.mutation<
      ChatControllerSendMessageToChannelApiResponse,
      ChatControllerSendMessageToChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/channel/${queryArg.channelName}`,
        method: 'POST',
        body: queryArg.sendChatMessageDto
      })
    }),
    chatControllerGetChannelTextMessageHistory: build.query<
      ChatControllerGetChannelTextMessageHistoryApiResponse,
      ChatControllerGetChannelTextMessageHistoryApiArg
    >({
      query: (queryArg) => ({ url: `/chat/channel/${queryArg.channelName}` })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as apiMirrorSlice }
export type AppControllerGetHelloApiResponse = /** status 200  */ string
export type AppControllerGetHelloApiArg = void
export type AppControllerGetHealthApiResponse = /** status 200  */ string
export type AppControllerGetHealthApiArg = void
export type AppControllerThrowIntentionalErrorApiResponse = unknown
export type AppControllerThrowIntentionalErrorApiArg = {
  delay?: any
}
export type AppControllerNonResponsiveEndpointApiResponse = unknown
export type AppControllerNonResponsiveEndpointApiArg = void
export type GodotServerOverrideConfigControllerFindOneApiResponse =
  /** status 200  */ string
export type GodotServerOverrideConfigControllerFindOneApiArg = {
  spaceId: string
}
export type GodotServerOverrideConfigControllerFindOneAliasApiResponse =
  /** status 200  */ string
export type GodotServerOverrideConfigControllerFindOneAliasApiArg = {
  spaceId: string
}
export type GodotServerOverrideConfigControllerCreateApiResponse =
  /** status 201  */ object
export type GodotServerOverrideConfigControllerCreateApiArg = {
  createGodotServerOverrideConfigDto: CreateGodotServerOverrideConfigDto
}
export type VenlyControllerGetVenlyWalletsForUserApiResponse = unknown
export type VenlyControllerGetVenlyWalletsForUserApiArg = void
export type StorageControllerGetClientVersionApiResponse =
  /** status 200 Public endpoint for getting the current version of the client application. */ object
export type StorageControllerGetClientVersionApiArg = void
export type StorageControllerGetClientUrlApiResponse =
  /** status 200 Public endpoint for getting the client application download URL for the target platform parameter. */ string
export type StorageControllerGetClientUrlApiArg = {
  platform: string
}
export type StorageControllerGetClientUrlsApiResponse =
  /** status 200 Public endpoint for getting all supported platforms client application download URLs. */ object
export type StorageControllerGetClientUrlsApiArg = void
export type StorageControllerDownloadFileApiResponse = unknown
export type StorageControllerDownloadFileApiArg = {
  fileName: string
}
export type MirrorServerConfigControllerGetConfigApiResponse =
  /** status 200  */ object
export type MirrorServerConfigControllerGetConfigApiArg = void
export type MirrorServerConfigControllerSetGdServerVersionApiResponse =
  /** status 200  */ object
export type MirrorServerConfigControllerSetGdServerVersionApiArg = void
export type AssetControllerSearchApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerSearchApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
}
export type AssetControllerSearchV2ApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerSearchV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
}
export type AssetControllerGetMirrorPublicLibraryAssetsApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetMirrorPublicLibraryAssetsApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetMirrorPublicLibraryAssetsV2ApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetMirrorPublicLibraryAssetsV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerCreateApiResponse =
  /** status 201  */ AssetApiResponse
export type AssetControllerCreateApiArg = {
  createAssetDto: CreateAssetDto
}
export type AssetControllerGetUserRecentInstancedAssetsApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetUserRecentInstancedAssetsApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
}
export type AssetControllerCreateWithUploadApiResponse =
  /** status 201  */ AssetApiResponse
export type AssetControllerCreateWithUploadApiArg = {
  body: any
}
export type AssetControllerGetAssetsForMeApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetAssetsForMeApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetAssetsForMeV2ApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetAssetsForMeV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetAllAccessibleAssetsOfUserApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetAllAccessibleAssetsOfUserApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetAllAccessibleAssetsOfUserV2ApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetAllAccessibleAssetsOfUserV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetRecentAssetsForUserApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetRecentAssetsForUserApiArg = {
  limit: number
  includeSoftDeleted?: boolean
}
export type AssetControllerGetRecentAssetsForUserV2ApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerGetRecentAssetsForUserV2ApiArg = {
  limit: number
  includeSoftDeleted?: boolean
}
export type AssetControllerGetPaginatedMyAssetsApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetPaginatedMyAssetsApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetPaginatedMyAssetsV2ApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetPaginatedMyAssetsV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetPaginatedMirrorAssetsApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetPaginatedMirrorAssetsApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerGetPaginatedMirrorAssetsV2ApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetPaginatedMirrorAssetsV2ApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
  ''?: any
}
export type AssetControllerFindAllForUserApiResponse =
  /** status 200  */ AssetApiResponse[]
export type AssetControllerFindAllForUserApiArg = {
  field?: string
  search?: string
  targetUserId: string
}
export type AssetControllerFindOneAssetUsageApiResponse =
  /** status 200  */ AssetUsageApiResponse
export type AssetControllerFindOneAssetUsageApiArg = {
  assetId: string
}
export type AssetControllerGetAssetsByTagApiResponse =
  /** status 200  */ AssetFullDataPaginatedResponse
export type AssetControllerGetAssetsByTagApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
}
export type AssetControllerAddTagToAssetsWithRoleChecksApiResponse =
  /** status 201  */ object
export type AssetControllerAddTagToAssetsWithRoleChecksApiArg = {
  addTagToAssetDto: AddTagToAssetDto
}
export type AssetControllerUpdateAssetTagsByTypeWithRoleChecksApiResponse =
  /** status 200  */ object
export type AssetControllerUpdateAssetTagsByTypeWithRoleChecksApiArg = {
  updateAssetTagsDto: UpdateAssetTagsDto
}
export type AssetControllerDeleteTagFromAssetWithRoleChecksApiResponse = unknown
export type AssetControllerDeleteTagFromAssetWithRoleChecksApiArg = {
  tagType:
    | 'aiGenerated'
    | 'thirdParty'
    | 'spaceGenre'
    | 'material'
    | 'theme'
    | 'userGenerated'
    | 'search'
  tagName: string
  assetId: string
}
export type AssetControllerFindOneApiResponse =
  /** status 200  */ AssetApiResponse
export type AssetControllerFindOneApiArg = {
  id: string
}
export type AssetControllerUpdateApiResponse =
  /** status 200  */ AssetApiResponse
export type AssetControllerUpdateApiArg = {
  id: string
  updateAssetDto: UpdateAssetDto
}
export type AssetControllerRemoveApiResponse =
  /** status 200  */ AssetApiResponse
export type AssetControllerRemoveApiArg = {
  id: string
}
export type AssetControllerUndoAssetSoftDeleteApiResponse =
  /** status 200  */ string
export type AssetControllerUndoAssetSoftDeleteApiArg = {
  assetId: string
}
export type AssetControllerUploadApiResponse =
  /** status 201  */ FileUploadApiResponse
export type AssetControllerUploadApiArg = {
  assetId: string
  body: any
}
export type AssetControllerUploadPublicApiResponse =
  /** status 201  */ FileUploadPublicApiResponse
export type AssetControllerUploadPublicApiArg = {
  id: string
  body: any
}
export type AssetControllerUploadThumbnailApiResponse =
  /** status 201  */ FileUploadPublicApiResponse
export type AssetControllerUploadThumbnailApiArg = {
  id: string
  body: any
}
export type AssetControllerGetAssetApiResponse = unknown
export type AssetControllerGetAssetApiArg = {
  field: string
  search: string
  /** Default is updatedAt: desc */
  sortKey: string
  /** Default is updatedAt: desc */
  sortDirection: -1 | 1
  page: number
  perPage: number
  startItem?: number
  numberOfItems?: number
  /** DEPRECATED: use assetType instead to line up with MongoDB property. This will be overriden by assetType if both are provided */
  type:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  /**
        Filter by assetType as array. Options: PANEL, BOX, SPHERE, MESH, IMAGE, AUDIO, MATERIAL, TEXTURE, MAP, SCRIPT, PACK.
           */
  assetTypes: (
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  )[]
  tag?: string[]
  tagType?
  /** Comma-separated list of fields to populate */
  populate: string[]
  includeSoftDeleted?: boolean
  mirrorAssetManagerUserSortKey?: string
  includeAssetPackAssets?: any
}
export type AssetControllerAddAssetPurchaseOptionApiResponse =
  /** status 201  */ object
export type AssetControllerAddAssetPurchaseOptionApiArg = {
  assetId: string
  addAssetPurchaseOptionDto: AddAssetPurchaseOptionDto
}
export type AssetControllerDeleteAssetPurchaseOptionApiResponse =
  /** status 200  */ object
export type AssetControllerDeleteAssetPurchaseOptionApiArg = {
  purchaseOptionId: string
  assetId: string
  addAssetPurchaseOptionDto: AddAssetPurchaseOptionDto
}
export type AssetControllerCheckIfAssetCopiedApiResponse = unknown
export type AssetControllerCheckIfAssetCopiedApiArg = {
  assetId: string
}
export type AssetControllerCopyFreeAssetApiResponse = /** status 201  */ object
export type AssetControllerCopyFreeAssetApiArg = {
  assetId: string
}
export type AssetControllerGetAssetsListPriceApiResponse = unknown
export type AssetControllerGetAssetsListPriceApiArg = {
  getAssetsPriceDto: GetAssetsPriceDto
}
export type AssetControllerDownloadAssetApiResponse = unknown
export type AssetControllerDownloadAssetApiArg = {
  assetId: string
}
export type AssetControllerGetAllAssetsBySpaceIdWithRolesCheckApiResponse =
  /** status 200  */ object[]
export type AssetControllerGetAllAssetsBySpaceIdWithRolesCheckApiArg = {
  spaceId: string
}
export type AssetControllerAddAssetToPackWithRolesCheckApiResponse =
  /** status 200  */ object
export type AssetControllerAddAssetToPackWithRolesCheckApiArg = {
  packId: string
  assetId: string
}
export type AssetControllerDeleteAssetFromPackWithRolesCheckApiResponse =
  /** status 200  */ object
export type AssetControllerDeleteAssetFromPackWithRolesCheckApiArg = {
  packId: string
  assetId: string
}
export type SpaceObjectControllerCreateApiResponse =
  /** status 201  */ CreateSpaceObjectResponse
export type SpaceObjectControllerCreateApiArg = {
  createSpaceObjectDto: CreateSpaceObjectDto
}
export type SpaceObjectControllerCreateAliasApiResponse =
  /** status 201  */ CreateSpaceObjectResponse
export type SpaceObjectControllerCreateAliasApiArg = {
  createSpaceObjectDto: CreateSpaceObjectDto
}
export type SpaceObjectControllerCopyApiResponse = /** status 201  */ object
export type SpaceObjectControllerCopyApiArg = void
export type SpaceObjectControllerFindAllBySpaceIdApiResponse =
  /** status 200  */ object[]
export type SpaceObjectControllerFindAllBySpaceIdApiArg = {
  id: string
}
export type SpaceObjectControllerFindAllBySpaceIdWithRolesCheckApiResponse =
  /** status 200  */ object[]
export type SpaceObjectControllerFindAllBySpaceIdWithRolesCheckApiArg = {
  id: string
}
export type SpaceObjectControllerGetSpaceObjectsByTagApiResponse =
  /** status 200  */ SpaceObject
export type SpaceObjectControllerGetSpaceObjectsByTagApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
}
export type SpaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecksApiResponse =
  /** status 200  */ object
export type SpaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecksApiArg =
  {
    updateSpaceObjectTagsDto: UpdateSpaceObjectTagsDto
  }
export type SpaceObjectControllerAddTagToSpaceObjectWithRoleChecksApiResponse =
  /** status 201  */ object
export type SpaceObjectControllerAddTagToSpaceObjectWithRoleChecksApiArg = {
  addTagToSpaceObjectDto: AddTagToSpaceObjectDto
}
export type SpaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecksApiResponse =
  unknown
export type SpaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecksApiArg =
  {
    tagType:
      | 'aiGenerated'
      | 'thirdParty'
      | 'spaceGenre'
      | 'material'
      | 'theme'
      | 'userGenerated'
      | 'search'
    tagName: string
    spaceObjectId: string
  }
export type SpaceObjectControllerSearchSpaceObjectsPaginatedApiResponse =
  unknown
export type SpaceObjectControllerSearchSpaceObjectsPaginatedApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  ''?: any
}
export type SpaceObjectControllerFindOneApiResponse = /** status 200  */ object
export type SpaceObjectControllerFindOneApiArg = {
  id: string
}
export type SpaceObjectControllerUpdateApiResponse = /** status 200  */ object
export type SpaceObjectControllerUpdateApiArg = {
  id: string
  updateSpaceObjectDto: UpdateSpaceObjectDto
}
export type SpaceObjectControllerRemoveApiResponse = unknown
export type SpaceObjectControllerRemoveApiArg = {
  id: string
}
export type ScriptEntityControllerGetRecentScriptsApiResponse =
  /** status 201  */ ScriptEntityResponse[]
export type ScriptEntityControllerGetRecentScriptsApiArg = void
export type ScriptEntityControllerCreateApiResponse =
  /** status 201  */ ScriptEntityResponse
export type ScriptEntityControllerCreateApiArg = {
  createScriptEntityDto: CreateScriptEntityDto
}
export type ScriptEntityControllerFindOneApiResponse = /** status 200  */ object
export type ScriptEntityControllerFindOneApiArg = {
  id: string
}
export type ScriptEntityControllerUpdateApiResponse = /** status 200  */ object
export type ScriptEntityControllerUpdateApiArg = {
  id: string
  updateScriptEntityDto: UpdateScriptEntityDto
}
export type ScriptEntityControllerDeleteApiResponse = /** status 200  */ object
export type ScriptEntityControllerDeleteApiArg = {
  id: string
}
export type UserControllerFindOneApiResponse =
  /** status 200 PUBLICLY ACCESSIBLE ENDPOINTS
id prefix added to prevent wildcard route clashes with file method order */ UserPublicData
export type UserControllerFindOneApiArg = {
  id: string
}
export type UserControllerFindOneWithPublicProfileApiResponse =
  /** status 200  */ UserPublicData
export type UserControllerFindOneWithPublicProfileApiArg = {
  id: string
}
export type UserControllerSearchApiResponse =
  /** status 200  */ UserPublicData[]
export type UserControllerSearchApiArg = {
  query: string
}
export type UserControllerGetCurrentUserApiResponse = /** status 200  */ User
export type UserControllerGetCurrentUserApiArg = void
export type UserControllerGetUserRecentsApiResponse =
  /** status 200  */ UserRecents
export type UserControllerGetUserRecentsApiArg = void
export type UserControllerUploadPublicApiResponse =
  /** status 201  */ FileUploadPublicApiResponse
export type UserControllerUploadPublicApiArg = {
  body: any
}
export type UserControllerUpdateProfileApiResponse = /** status 200  */ User
export type UserControllerUpdateProfileApiArg = {
  updateUserProfileDto: UpdateUserProfileDto
}
export type UserControllerUpdateUserTutorialApiResponse =
  /** status 200  */ User
export type UserControllerUpdateUserTutorialApiArg = {
  updateUserTutorialDto: UpdateUserTutorialDto
}
export type UserControllerUpdateDeepLinkApiResponse = /** status 200  */ object
export type UserControllerUpdateDeepLinkApiArg = {
  updateUserDeepLinkDto: UpdateUserDeepLinkDto
}
export type UserControllerUpdateAvatarApiResponse = /** status 200  */ object
export type UserControllerUpdateAvatarApiArg = {
  updateUserAvatarDto: UpdateUserAvatarDto
}
export type UserControllerUpdateTermsAgreedToApiResponse =
  /** status 200  */ object
export type UserControllerUpdateTermsAgreedToApiArg = {
  updateUserTermsDto: UpdateUserTermsDto
}
export type UserControllerUpdateAvatarTypeApiResponse =
  /** status 200  */ object
export type UserControllerUpdateAvatarTypeApiArg = {
  updateUserAvatarTypeDto: UpdateUserAvatarTypeDto
}
export type UserControllerGetUserEntityActionApiResponse =
  /** status 200  */ object
export type UserControllerGetUserEntityActionApiArg = {
  entityId: string
}
export type UserControllerGetUserEntityActionsByMeForEntityApiResponse =
  /** status 200  */ object[]
export type UserControllerGetUserEntityActionsByMeForEntityApiArg = {
  entityId: string
}
export type UserControllerUpsertUserEntityActionApiResponse =
  /** status 200  */ object
export type UserControllerUpsertUserEntityActionApiArg = {
  upsertUserEntityActionDto: UpsertUserEntityActionDto
}
export type UserControllerDeleteUserEntityActionApiResponse =
  /** status 200  */ object
export type UserControllerDeleteUserEntityActionApiArg = {
  userEntityActionId: string
}
export type UserControllerGetMyFriendsApiResponse =
  /** status 200 Gets friend requests for the current user from the token */ Friend[]
export type UserControllerGetMyFriendsApiArg = void
export type UserControllerGetMyFriendRequestsApiResponse =
  /** status 200  */ Friend[]
export type UserControllerGetMyFriendRequestsApiArg = void
export type UserControllerAcceptFriendRequestApiResponse =
  /** status 200 Accepts a friend request. This uses the current user from the token as the accepting user */ Friend[]
export type UserControllerAcceptFriendRequestApiArg = {
  fromUserId: string
}
export type UserControllerRejectFriendRequestApiResponse =
  /** status 200 Rejects a friend request. This uses the current user from the token as the user that rejects the request */ Friend[]
export type UserControllerRejectFriendRequestApiArg = {
  fromUserId: string
}
export type UserControllerGetSentFriendRequestsApiResponse =
  /** status 200 Gets SENT friend requests by the current user from the token */ Friend[]
export type UserControllerGetSentFriendRequestsApiArg = void
export type UserControllerSendFriendRequestApiResponse =
  /** status 201 Sends a friend request. This uses the current user from the token */ User
export type UserControllerSendFriendRequestApiArg = {
  toUserId: string
}
export type UserControllerRemoveFriendApiResponse =
  /** status 200 Removes a friend and returns the updated friends list */ User
export type UserControllerRemoveFriendApiArg = {
  friendUserIdToRemove: string
}
export type UserControllerGetUserCartApiResponse = /** status 200  */ User
export type UserControllerGetUserCartApiArg = void
export type UserControllerAddUserCartItemToUserCartApiResponse =
  /** status 201  */ User
export type UserControllerAddUserCartItemToUserCartApiArg = {
  addUserCartItemToUserCartDto: AddUserCartItemToUserCartDto
}
export type UserControllerRemoveAllUserItemsFromCartApiResponse =
  /** status 200  */ User
export type UserControllerRemoveAllUserItemsFromCartApiArg = void
export type UserControllerRemoveUserCartItemFromUserCartApiResponse =
  /** status 200  */ User
export type UserControllerRemoveUserCartItemFromUserCartApiArg = {
  cartItemId: string
}
export type UserControllerAddRpmAvatarUrlApiResponse =
  /** status 201 END Section: Cart  ------------------------------------------------------
@description Add a url to the array of the user's RPM avatars, readyPlayerMeAvatarUrls in Mongo */ object
export type UserControllerAddRpmAvatarUrlApiArg = {
  addRpmAvatarUrlDto: AddRpmAvatarUrlDto
}
export type UserControllerRemoveRpmAvatarUrlApiResponse =
  /** status 200 @description Removes an RPM url from readyPlayerMeAvatarUrls in Mongo */ object
export type UserControllerRemoveRpmAvatarUrlApiArg = {
  removeRpmAvatarUrlDto: RemoveRpmAvatarUrlDto
}
export type UserControllerCreateSignUpKeyApiResponse = /** status 201  */ object
export type UserControllerCreateSignUpKeyApiArg = {
  createUserAccessKeyDto: CreateUserAccessKeyDto
}
export type UserControllerSubmitUserAccessKeyApiResponse = unknown
export type UserControllerSubmitUserAccessKeyApiArg = {
  submitUserAccessKeyDto: SubmitUserAccessKeyDto
}
export type UserControllerGetUserSidebarTagsApiResponse =
  /** status 200  */ string[]
export type UserControllerGetUserSidebarTagsApiArg = void
export type UserControllerAddUserSidebarTagApiResponse =
  /** status 201  */ string
export type UserControllerAddUserSidebarTagApiArg = {
  addUserSidebarTagDto: AddUserSidebarTagDto
}
export type UserControllerUpdateUserSidebarTagsApiResponse =
  /** status 200  */ string[]
export type UserControllerUpdateUserSidebarTagsApiArg = {
  updateUserSidebarTagsDto: UpdateUserSidebarTagsDto
}
export type UserControllerDeleteUserSidebarTagApiResponse = unknown
export type UserControllerDeleteUserSidebarTagApiArg = {
  sidebarTag: string
}
export type FileUploadControllerBatchAssetUploadFromQueueBucketApiResponse =
  unknown
export type FileUploadControllerBatchAssetUploadFromQueueBucketApiArg = void
export type SpaceControllerGetAllPublicForUserApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerGetAllPublicForUserApiArg = {
  id: string
}
export type SpaceControllerGetAllPublicForUserV2ApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerGetAllPublicForUserV2ApiArg = {
  id: string
}
export type SpaceControllerSearchApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerSearchApiArg = {
  query: string
}
export type SpaceControllerSearchV2ApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerSearchV2ApiArg = {
  query: string
}
export type SpaceControllerFindAllForMeWhereOwnerApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerFindAllForMeWhereOwnerApiArg = void
export type SpaceControllerFindAllForMeWhereOwnerPaginatedV2ApiResponse =
  /** status 200  */ SpacePublicDataPaginatedResponse
export type SpaceControllerFindAllForMeWhereOwnerPaginatedV2ApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerFindAllForMeWhereOwnerPaginatedV3ApiResponse =
  /** status 200  */ SpacePublicDataPaginatedResponse
export type SpaceControllerFindAllForMeWhereOwnerPaginatedV3ApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerGetPopularSpacesApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerGetPopularSpacesApiArg = {
  populateCreator?: boolean
  populateUsersPresent?: boolean
  ''?: any
}
export type SpaceControllerGetFavoriteSpacesApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerGetFavoriteSpacesApiArg = {
  populateCreator?: boolean
  populateUsersPresent?: boolean
  ''?: any
}
export type SpaceControllerGetRecentSpacesApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerGetRecentSpacesApiArg = {
  populateCreator?: boolean
  populateUsersPresent?: boolean
  ''?: any
}
export type SpaceControllerGetSpacesByTagsApiResponse =
  /** status 200  */ SpacePublicDataPaginatedResponse
export type SpaceControllerGetSpacesByTagsApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerAddTagToSpaceWithRoleChecksApiResponse =
  /** status 201  */ object
export type SpaceControllerAddTagToSpaceWithRoleChecksApiArg = {
  addTagToSpaceDto: AddTagToSpaceDto
}
export type SpaceControllerUpdateSpaceTagsByTypeWithRoleChecksApiResponse =
  /** status 200  */ object
export type SpaceControllerUpdateSpaceTagsByTypeWithRoleChecksApiArg = {
  updateSpaceTagsDto: UpdateSpaceTagsDto
}
export type SpaceControllerDeleteTagFromSpaceWithRoleChecksApiResponse = unknown
export type SpaceControllerDeleteTagFromSpaceWithRoleChecksApiArg = {
  tagType:
    | 'aiGenerated'
    | 'thirdParty'
    | 'spaceGenre'
    | 'material'
    | 'theme'
    | 'userGenerated'
    | 'search'
  tagName: string
  spaceId: string
}
export type SpaceControllerFindDiscoverSpacesForUserApiResponse =
  /** status 200 The space data for the /discover tab */ SpacePublicData[]
export type SpaceControllerFindDiscoverSpacesForUserApiArg = void
export type SpaceControllerFindDiscoverSpacesForUserPaginatedV2ApiResponse =
  /** status 200 The space data for the /discover tab */ SpacePublicDataPaginatedResponse
export type SpaceControllerFindDiscoverSpacesForUserPaginatedV2ApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerFindDiscoverSpacesForUserPaginatedV3ApiResponse =
  /** status 200 The space data for the /discover tab */ SpacePublicDataPaginatedResponse
export type SpaceControllerFindDiscoverSpacesForUserPaginatedV3ApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerFindSpaceTemplatesApiResponse =
  /** status 200 The space data for the /discover tab */ SpacePublicDataPaginatedResponse
export type SpaceControllerFindSpaceTemplatesApiArg = void
export type SpaceControllerGetPublishedSpacesApiResponse =
  /** status 200  */ SpacePublicDataPaginatedResponse
export type SpaceControllerGetPublishedSpacesApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
}
export type SpaceControllerGetPublishedSpacesV2ApiResponse =
  /** status 200  */ SpacePublicDataPaginatedResponse
export type SpaceControllerGetPublishedSpacesV2ApiArg = {
  field?: string
  search?: string
  /** Default is updatedAt: desc */
  sortKey?: string
  /** Default is updatedAt: desc */
  sortDirection?: -1 | 1
  page?: number
  perPage?: number
  tag?: string[]
  tagType?
  startItem?: number
  numberOfItems?: number
  populateCreator?: boolean
  populateUsersPresent?: boolean
}
export type SpaceControllerGetLatestPublishedSpaceBySpaceIdApiResponse =
  /** status 200 The latest version of a published space. This is the CLIENT-SIDE route for /latest */ SpaceVersion[]
export type SpaceControllerGetLatestPublishedSpaceBySpaceIdApiArg = {
  spaceId: string
}
export type SpaceControllerRefreshStatsApiResponse =
  /** status 200  */ SpaceStatsModel
export type SpaceControllerRefreshStatsApiArg = {
  spaceId: string
}
export type SpaceControllerFindOneApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerFindOneApiArg = {
  populateCreator?: boolean
  populateUsersPresent?: boolean
  id: string
}
export type SpaceControllerUpdateApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerUpdateApiArg = {
  id: string
  updateSpaceDto: UpdateSpaceDto
}
export type SpaceControllerRemoveApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerRemoveApiArg = {
  id: string
}
export type SpaceControllerFindAllForUserV2ApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerFindAllForUserV2ApiArg = void
export type SpaceControllerFindAllForUserApiResponse =
  /** status 200  */ SpacePublicData[]
export type SpaceControllerFindAllForUserApiArg = void
export type SpaceControllerCreateApiResponse =
  /** status 201  */ SpacePublicData
export type SpaceControllerCreateApiArg = {
  createSpaceDto: CreateSpaceDto
}
export type SpaceControllerClearVoxelsApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerClearVoxelsApiArg = {
  id: string
}
export type SpaceControllerCopyApiResponse =
  /** status 200 Copy a user's own space */ SpacePublicData
export type SpaceControllerCopyApiArg = {
  id: string
}
export type SpaceControllerCopyFromTemplateApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerCopyFromTemplateApiArg = {
  id: string
  spaceCopyFromTemplateDto: SpaceCopyFromTemplateDto
}
export type SpaceControllerRemixSpaceApiResponse = /** status 201  */ object
export type SpaceControllerRemixSpaceApiArg = {
  id: string
  remixSpaceDto: RemixSpaceDto
}
export type SpaceControllerPublishApiResponse = /** status 201  */ object
export type SpaceControllerPublishApiArg = {
  id: string
  createNewSpaceVersionDto: CreateNewSpaceVersionDto
}
export type SpaceControllerGetPublishedSpacesBySpaceIdApiResponse =
  /** status 200  */ object[]
export type SpaceControllerGetPublishedSpacesBySpaceIdApiArg = {
  id: string
}
export type SpaceControllerUploadPublicApiResponse =
  /** status 201  */ SpacePublicData
export type SpaceControllerUploadPublicApiArg = {
  id: string
  body: any
}
export type SpaceControllerRestoreSpaceFromSpaceVersionApiResponse = unknown
export type SpaceControllerRestoreSpaceFromSpaceVersionApiArg = {
  id: string
}
export type SpaceControllerSetUserRoleForOneApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerSetUserRoleForOneApiArg = {
  id: string
  setUserRoleForOneDto: SetUserRoleForOneDto
}
export type SpaceControllerRemoveUserRoleForOneApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerRemoveUserRoleForOneApiArg = {
  id: string
  removeUserRoleForOneDto: RemoveUserRoleForOneDto
}
export type SpaceControllerKickMeApiResponse =
  /** status 200  */ SpacePublicData
export type SpaceControllerKickMeApiArg = {
  id: string
}
export type SpaceControllerGetAssetsListPerSpaceApiResponse =
  /** status 200  */ object[]
export type SpaceControllerGetAssetsListPerSpaceApiArg = {
  spaceId: string
}
export type SpaceGodotServerControllerGetLatestPublishedSpaceBySpaceIdApiResponse =
  /** status 200 TEMP: AUTHED ENDPOINT UP HERE FOR 2023-03-17 13:53:32 RELEASE ISSUE
We should likely refactor our pattern for classes because order of routes DOES matter in NestJS, so being @Public() up top can cause issues */ object
export type SpaceGodotServerControllerGetLatestPublishedSpaceBySpaceIdApiArg = {
  id: string
}
export type SpaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceIdApiResponse =
  /** status 200  */ object
export type SpaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceIdApiArg =
  {
    id: string
  }
export type SpaceGodotServerControllerUpdateTerrainApiResponse = unknown
export type SpaceGodotServerControllerUpdateTerrainApiArg = {
  id: string
}
export type MaterialInstanceControllerCreateApiResponse =
  /** status 201  */ MaterialInstanceResponse
export type MaterialInstanceControllerCreateApiArg = {
  createMaterialInstanceDto: CreateMaterialInstanceDto
}
export type MaterialInstanceControllerFindOneApiResponse =
  /** status 200  */ MaterialInstance
export type MaterialInstanceControllerFindOneApiArg = {
  materialInstanceId: string
  spaceId: string
}
export type MaterialInstanceControllerUpdateApiResponse =
  /** status 200  */ MaterialInstance
export type MaterialInstanceControllerUpdateApiArg = {
  materialInstanceId: string
  spaceId: string
  updateMaterialInstanceDto: UpdateMaterialInstanceDto
}
export type MaterialInstanceControllerDeleteApiResponse =
  /** status 200  */ string
export type MaterialInstanceControllerDeleteApiArg = {
  materialInstanceId: string
  spaceId: string
}
export type TerrainControllerFindAllForUserApiResponse =
  /** status 200  */ TerrainApiResponse[]
export type TerrainControllerFindAllForUserApiArg = void
export type TerrainControllerCreateApiResponse =
  /** status 201  */ TerrainApiResponse
export type TerrainControllerCreateApiArg = {
  createTerrainDto: CreateTerrainDto
}
export type TerrainControllerFindAllPublicApiResponse =
  /** status 200  */ TerrainApiResponse[]
export type TerrainControllerFindAllPublicApiArg = void
export type TerrainControllerFindOneApiResponse =
  /** status 200  */ TerrainApiResponse
export type TerrainControllerFindOneApiArg = {
  id: string
}
export type TerrainControllerUpdateApiResponse =
  /** status 200  */ TerrainApiResponse
export type TerrainControllerUpdateApiArg = {
  id: string
  updateTerrainDto: UpdateTerrainDto
}
export type TerrainControllerRemoveApiResponse =
  /** status 200  */ TerrainApiResponse
export type TerrainControllerRemoveApiArg = {
  id: string
}
export type EnvironmentControllerCreateApiResponse =
  /** status 201  */ EnvironmentApiResponse
export type EnvironmentControllerCreateApiArg = void
export type EnvironmentControllerFindOneApiResponse =
  /** status 200  */ EnvironmentApiResponse
export type EnvironmentControllerFindOneApiArg = {
  id: string
}
export type EnvironmentControllerUpdateApiResponse =
  /** status 200  */ EnvironmentApiResponse
export type EnvironmentControllerUpdateApiArg = {
  id: string
  updateEnvironmentDto: UpdateEnvironmentDto
}
export type EnvironmentControllerRemoveApiResponse =
  /** status 200  */ EnvironmentApiResponse
export type EnvironmentControllerRemoveApiArg = {
  id: string
}
export type UserGroupControllerCreateApiResponse =
  /** status 201  */ UserGroupApiResponse
export type UserGroupControllerCreateApiArg = {
  createUserGroupMembershipDto: CreateUserGroupMembershipDto
  createUserGroupDto: CreateUserGroupDto
}
export type UserGroupControllerGetAllGroupsForMeApiResponse =
  /** status 200  */ UserGroupApiResponse[]
export type UserGroupControllerGetAllGroupsForMeApiArg = void
export type UserGroupControllerGetAllGroupInvitesForMeApiResponse =
  /** status 200  */ UserGroupInviteApiResponse[]
export type UserGroupControllerGetAllGroupInvitesForMeApiArg = void
export type UserGroupControllerFindPublicGroupMembershipForOtherUserApiResponse =
  /** status 200 so we only get the PUBLIC groups that the person is a part of
TODO - add ApiOkResponse type for UserGroupMembership */ object[]
export type UserGroupControllerFindPublicGroupMembershipForOtherUserApiArg = {
  otherUserId: string
}
export type UserGroupControllerGetGroupMembershipForMeApiResponse =
  /** status 200 TODO - add ApiOkResponse type for UserGroupMembership */ object
export type UserGroupControllerGetGroupMembershipForMeApiArg = {
  id: string
}
export type UserGroupControllerSearchApiResponse =
  /** status 200  */ UserGroupApiResponse[]
export type UserGroupControllerSearchApiArg = void
export type UserGroupControllerFindOneApiResponse =
  /** status 200  */ UserGroupApiResponse
export type UserGroupControllerFindOneApiArg = {
  id: string
}
export type UserGroupControllerUpdateApiResponse =
  /** status 200  */ UserGroupApiResponse
export type UserGroupControllerUpdateApiArg = {
  id: string
  updateUserGroupDto: UpdateUserGroupDto
}
export type UserGroupControllerRemoveApiResponse =
  /** status 200  */ UserGroupApiResponse
export type UserGroupControllerRemoveApiArg = {
  id: string
}
export type ZoneControllerJoinBuildServerApiResponse =
  /** status 200 @description Requests a zone server with a specific space id and launches the server if needed for BUILD MODE */ object
export type ZoneControllerJoinBuildServerApiArg = {
  spaceId: string
}
export type ZoneControllerGetPlayServersForSpaceVersionIdApiResponse =
  /** status 200  */ object[]
export type ZoneControllerGetPlayServersForSpaceVersionIdApiArg = {
  spaceVersionId: string
}
export type ZoneControllerGetPlayServersForSpaceIdApiResponse =
  /** status 200  */ object[]
export type ZoneControllerGetPlayServersForSpaceIdApiArg = {
  populateOwner?: boolean
  spaceId: string
}
export type ZoneControllerJoinPlayServerByZoneIdApiResponse =
  /** status 200  */ object
export type ZoneControllerJoinPlayServerByZoneIdApiArg = {
  zoneId: string
}
export type ZoneControllerJoinPlayServerBySpaceIdApiResponse =
  /** status 200  */ object
export type ZoneControllerJoinPlayServerBySpaceIdApiArg = {
  spaceId: string
}
export type ZoneControllerCreatePlayServerWithSpaceVersionApiResponse =
  /** status 201  */ object
export type ZoneControllerCreatePlayServerWithSpaceVersionApiArg = {
  spaceVersionId: string
  createPlayServerDto: CreatePlayServerDto
}
export type ZoneControllerCreatePlayServerWithSpaceApiResponse =
  /** status 201  */ object
export type ZoneControllerCreatePlayServerWithSpaceApiArg = {
  spaceId: string
  createPlayServerDto: CreatePlayServerDto
}
export type ZoneControllerFindAllZonesBySpaceIdApiResponse =
  /** status 200 @description Get all the Zone entities associated with a space id. */ object[]
export type ZoneControllerFindAllZonesBySpaceIdApiArg = {
  spaceId: string
}
export type ZoneControllerFindAllZonesByUserIdApiResponse =
  /** status 200 @description Get all the Zone entities associated with a user id. */ object[]
export type ZoneControllerFindAllZonesByUserIdApiArg = {
  userId: string
}
export type ZoneControllerFindOneZoneApiResponse =
  /** status 200 @description Retrieves a zone entity. */ object
export type ZoneControllerFindOneZoneApiArg = {
  zoneId: string
}
export type ZoneControllerUpdateOneZoneApiResponse =
  /** status 200 @description Update the user controlled values of a zone entity (Name, Description, Space) */ object
export type ZoneControllerUpdateOneZoneApiArg = {
  zoneId: string
  updateZoneDto: UpdateZoneDto
}
export type ZoneControllerStopAllActiveZonesApiResponse = unknown
export type ZoneControllerStopAllActiveZonesApiArg = void
export type MirrorDbControllerGetRecordFromMirrorDbByIdApiResponse =
  /** status 200  */ object
export type MirrorDbControllerGetRecordFromMirrorDbByIdApiArg = {
  id: string
}
export type MirrorDbControllerUpdateRecordInMirrorDbByIdApiResponse =
  /** status 200  */ object
export type MirrorDbControllerUpdateRecordInMirrorDbByIdApiArg = {
  id: string
  updateMirrorDbRecordDto: UpdateMirrorDbRecordDto
}
export type MirrorDbControllerDeleteRecordFromMirrorDbByIdApiResponse =
  /** status 200  */ string
export type MirrorDbControllerDeleteRecordFromMirrorDbByIdApiArg = {
  id: string
}
export type MirrorDbControllerGetRecordFromMirrorDbBySpaceIdApiResponse =
  /** status 200  */ object
export type MirrorDbControllerGetRecordFromMirrorDbBySpaceIdApiArg = {
  spaceId: string
}
export type MirrorDbControllerGetRecordFromMirrorDbBySpaceVersionIdApiResponse =
  /** status 200  */ object
export type MirrorDbControllerGetRecordFromMirrorDbBySpaceVersionIdApiArg = {
  spaceVersionId: string
}
export type TagControllerCreateApiResponse =
  /** status 201  */ CreateTagResponse
export type TagControllerCreateApiArg = {
  createTagDto: CreateTagDto
}
export type TagControllerFindAllMirrorPublicLibraryTagsApiResponse =
  /** status 200  */ object[]
export type TagControllerFindAllMirrorPublicLibraryTagsApiArg = void
export type TagControllerFindAllThemeTagsApiResponse =
  /** status 200  */ object[]
export type TagControllerFindAllThemeTagsApiArg = void
export type TagControllerGetTagTypesApiResponse = /** status 200  */ string[]
export type TagControllerGetTagTypesApiArg = void
export type TagControllerFindOneApiResponse = /** status 200  */ object
export type TagControllerFindOneApiArg = {
  id: string
}
export type TagControllerUpdateApiResponse = /** status 200  */ object
export type TagControllerUpdateApiArg = {
  id: string
  updateTagDto: UpdateTagDto
}
export type TagControllerRemoveApiResponse = /** status 200  */ object
export type TagControllerRemoveApiArg = {
  id: string
}
export type AuthControllerDiscordApiResponse =
  /** status 201  */ FirebaseCustomTokenResponse
export type AuthControllerDiscordApiArg = {
  mintCustomTokenDiscordDto: MintCustomTokenDiscordDto
}
export type AuthControllerCreateUserWithEmailPasswordAndTypeApiResponse =
  /** status 201  */ CreateUserResponse
export type AuthControllerCreateUserWithEmailPasswordAndTypeApiArg = {
  createUserWithEmailPasswordDto: CreateUserWithEmailPasswordDto
}
export type AuthControllerAuthedUserCreateApiResponse =
  /** status 201  */ CreateAuthUserResponse
export type AuthControllerAuthedUserCreateApiArg = void
export type AuthControllerCreateAnonymousAccountApiResponse = unknown
export type AuthControllerCreateAnonymousAccountApiArg = void
export type AuthControllerConvertAnonymousAccountToFullApiResponse =
  /** status 201  */ CreateUserResponse
export type AuthControllerConvertAnonymousAccountToFullApiArg = {
  createUserWithEmailPasswordDto: CreateUserWithEmailPasswordDto
}
export type AuthControllerDeleteAccountApiResponse = unknown
export type AuthControllerDeleteAccountApiArg = void
export type UserFeedbackControllerFindNewestPublicUserFeedbackItemsApiResponse =
  /** status 200  */ UserFeedbackItemResponse[]
export type UserFeedbackControllerFindNewestPublicUserFeedbackItemsApiArg = void
export type UserFeedbackControllerFindTopPublicUserFeedbackItemsApiResponse =
  /** status 200  */ UserFeedbackItemResponse[]
export type UserFeedbackControllerFindTopPublicUserFeedbackItemsApiArg = void
export type UserFeedbackControllerGetUserFeedbackItemTypesApiResponse =
  /** status 200  */ string[]
export type UserFeedbackControllerGetUserFeedbackItemTypesApiArg = void
export type UserFeedbackControllerFindOneApiResponse =
  /** status 200  */ UserFeedbackItemResponse
export type UserFeedbackControllerFindOneApiArg = {
  id: string
}
export type UserFeedbackControllerUpdateApiResponse = /** status 200  */ object
export type UserFeedbackControllerUpdateApiArg = {
  id: string
  updateUserFeedbackDto: UpdateUserFeedbackDto
}
export type UserFeedbackControllerRemoveUserFeedbackItemApiResponse =
  /** status 200  */ object
export type UserFeedbackControllerRemoveUserFeedbackItemApiArg = {
  id: string
}
export type UserFeedbackControllerFindCommentsApiResponse =
  /** status 200  */ UserFeedbackCommentResponse[]
export type UserFeedbackControllerFindCommentsApiArg = {
  userFeedbackItemId: string
}
export type UserFeedbackControllerFindAllPublicUserFeedbackItemsApiResponse =
  /** status 200  */ UserFeedbackItemResponse[]
export type UserFeedbackControllerFindAllPublicUserFeedbackItemsApiArg = void
export type UserFeedbackControllerCreateApiResponse =
  /** status 201  */ UserFeedbackItemResponse
export type UserFeedbackControllerCreateApiArg = {
  createUserFeedbackItemDto: CreateUserFeedbackItemDto
}
export type UserFeedbackControllerVoteOnUserFeedbackItemApiResponse =
  /** status 200  */
  UserFeedbackItemResponse | /** status 201  */ UserFeedbackItemResponse
export type UserFeedbackControllerVoteOnUserFeedbackItemApiArg = {
  createVoteOnUserFeedbackItemDto: CreateVoteOnUserFeedbackItemDto
}
export type UserFeedbackControllerCreateCommentApiResponse =
  /** status 201  */ UserFeedbackCommentResponse
export type UserFeedbackControllerCreateCommentApiArg = {
  createUserFeedbackCommentDto: CreateUserFeedbackCommentDto
}
export type UserFeedbackControllerRemoveCommentApiResponse =
  /** status 200  */ object
export type UserFeedbackControllerRemoveCommentApiArg = {
  id: string
}
export type StripeControllerCreateConnectAccountApiResponse =
  /** status 201  */ object
export type StripeControllerCreateConnectAccountApiArg = void
export type StripeControllerDeleteConnectAccountApiResponse =
  /** status 200  */ object
export type StripeControllerDeleteConnectAccountApiArg = void
export type StripeControllerGetCardsListApiResponse =
  /** status 200  */ StripeCardModel[]
export type StripeControllerGetCardsListApiArg = void
export type StripeControllerCreateCardApiResponse = /** status 201  */ object
export type StripeControllerCreateCardApiArg = {
  addCardDto: AddCardDto
}
export type StripeControllerDeleteCardApiResponse = /** status 200  */ string
export type StripeControllerDeleteCardApiArg = {
  cardId: string
}
export type StripeControllerGetPaymentMethodsApiResponse =
  /** status 200  */ PaymentMethodModel[]
export type StripeControllerGetPaymentMethodsApiArg = void
export type StripeControllerSetDefaultPaymentMethodApiResponse =
  /** status 201  */ string
export type StripeControllerSetDefaultPaymentMethodApiArg = {
  paymentMethodId: string
}
export type StripeControllerCreatePaymentSessionApiResponse =
  /** status 201  */ object
export type StripeControllerCreatePaymentSessionApiArg = {
  createPaymentSessionDto: CreatePaymentSessionDto
}
export type StripeControllerCreatePaymentIntentApiResponse = unknown
export type StripeControllerCreatePaymentIntentApiArg = {
  createPaymentIntentDto: CreatePaymentIntentDto
}
export type StripeControllerSetupIntentApiResponse =
  /** status 200  */ SetupPaymentIntentResponseDto
export type StripeControllerSetupIntentApiArg = {
  setupPaymentIntentDto: SetupPaymentIntentDto
}
export type StripeControllerCreateCustomerAccountApiResponse =
  /** status 201  */ object
export type StripeControllerCreateCustomerAccountApiArg = void
export type StripeControllerGetStripeAccountInfoApiResponse =
  /** status 200  */ object
export type StripeControllerGetStripeAccountInfoApiArg = void
export type StripeControllerAddBankAccountApiResponse =
  /** status 201  */ object
export type StripeControllerAddBankAccountApiArg = {
  addExternalBankAccountTokenDto: AddExternalBankAccountTokenDto
}
export type StripeControllerTransfersAmountApiResponse =
  /** status 201  */ object
export type StripeControllerTransfersAmountApiArg = {
  createTransferDto: CreateTransferDto
}
export type StripeControllerCreateProductApiResponse = /** status 201  */ object
export type StripeControllerCreateProductApiArg = {
  createProductDto: CreateProductDto
}
export type StripeControllerGetAllProductsWithPriceApiResponse =
  /** status 200  */ object
export type StripeControllerGetAllProductsWithPriceApiArg = void
export type StripeControllerCreateSubscriptionApiResponse =
  /** status 201  */ object
export type StripeControllerCreateSubscriptionApiArg = {
  createSubscriptionDto: CreateSubscriptionDto
}
export type StripeControllerDeleteSubscriptionApiResponse =
  /** status 200  */ boolean
export type StripeControllerDeleteSubscriptionApiArg = void
export type StripeControllerPauseSubscriptionApiResponse =
  /** status 200  */ boolean
export type StripeControllerPauseSubscriptionApiArg = void
export type StripeControllerResumeSubscriptionApiResponse =
  /** status 200  */ boolean
export type StripeControllerResumeSubscriptionApiArg = void
export type StripeControllerGetSubscriptionStatusApiResponse =
  /** status 200  */ boolean
export type StripeControllerGetSubscriptionStatusApiArg = void
export type StripeControllerCreateDashboardLinkApiResponse =
  /** status 200  */ object
export type StripeControllerCreateDashboardLinkApiArg = void
export type StripeControllerCreateCustomerPortalLinkApiResponse =
  /** status 200  */ object
export type StripeControllerCreateCustomerPortalLinkApiArg = void
export type StripeControllerCreatePaymentLinkForOptionalDonationAssetPaymentApiResponse =
  /** status 201  */ object
export type StripeControllerCreatePaymentLinkForOptionalDonationAssetPaymentApiArg =
  {
    paymentLinkForOptinalDonationAsset: PaymentLinkForOptinalDonationAsset
  }
export type StripeControllerHandleStripeWebhookApiResponse =
  /** status 201  */ object
export type StripeControllerHandleStripeWebhookApiArg = void
export type BlockControllerCreateApiResponse =
  /** status 201  */ CreateBlockResponse
export type BlockControllerCreateApiArg = {
  createBlockDto: CreateBlockDto
}
export type BlockControllerFindOneApiResponse = /** status 200  */ object
export type BlockControllerFindOneApiArg = {
  id: string
}
export type BlockControllerUpdateApiResponse = /** status 200  */ object
export type BlockControllerUpdateApiArg = {
  id: string
  updateBlockDto: UpdateBlockDto
}
export type BlockControllerRemoveApiResponse = /** status 200  */ object
export type BlockControllerRemoveApiArg = {
  id: string
}
export type TransferBetweenEnvironmentsControllerTransferSpaceApiResponse =
  /** status 201  */ object
export type TransferBetweenEnvironmentsControllerTransferSpaceApiArg = {
  transferSpaceDto: TransferSpaceDto
}
export type ChatControllerSendMessageApiResponse = /** status 201  */ object
export type ChatControllerSendMessageApiArg = {
  sendChatMessageDto: SendChatMessageDto
}
export type ChatControllerSendMessageToChannelApiResponse =
  /** status 201  */ ChatMessageResponse
export type ChatControllerSendMessageToChannelApiArg = {
  channelName: string
  sendChatMessageDto: SendChatMessageDto
}
export type ChatControllerGetChannelTextMessageHistoryApiResponse =
  /** status 200  */ object
export type ChatControllerGetChannelTextMessageHistoryApiArg = {
  channelName: string
}
export type CreateGodotServerOverrideConfigDto = {
  spaceId: string
}
export type CustomData = {
  data: object
}
export type UserRecentAssets = {
  instanced: string[]
}
export type UserRecents = {
  spaces: string[]
  assets: UserRecentAssets
  scripts: string[]
}
export type UserMarketing = {
  emailsSent: string[]
}
export type UserCartItem = {
  forEntity: string
  entityType: string
}
export type GtUserData = {
  salesForceOpportunityId?: string
  salesForceAuthCallbackCode?: string
  gtUserType?
  gtRPMUserAvatarUrl?: string
}
export type User = {
  _id: string
  createdAt: string
  updatedAt: string
  firebaseUID: string
  isInternalAdmin: string
  displayName: string
  groupInvitations: string[]
  friends: string[]
  sentFriendRequestsToUsers: string[]
  recents: UserRecents
  marketing: UserMarketing
  email: string
  emailVerified: boolean
  publicBio: string
  avatarUrl: string
  avatarType: 'MIRROR_AVATAR_V1' | 'READY_PLAYER_ME'
  customData: CustomData
  readyPlayerMeUrlGlb: string
  readyPlayerMeAvatarUrls: string[]
  closedBetaHasClickedInterestedInBeta: boolean
  closedBetaIsInClosedBeta: boolean
  termsAgreedtoClosedAlpha: boolean
  termsAgreedtoGeneralTOSandPP: boolean
  discordUserId: string
  polygonPublicKey: string
  ethereumPublicKey: string
  twitterUsername: string
  githubUsername: string
  instagramUsername: string
  youtubeChannel: string
  artStationUsername: string
  sketchfabUsername: string
  premiumAccess: 'CLOSED_ALPHA' | 'PLUS_ALPHA_OFFER_1' | 'PREMIUM_ALPHA_OFFER_1'
  premiumAccessSince: string
  premiumSubscriptionStatus: 'ACTIVE' | 'PAUSE' | 'CANCEL'
  stripeCustomerId: string
  stripeAccountId: string
  deepLinkKey: string
  deepLinkValue: string
  deepLinkLastUpdatedAt: string
  profileImage: string
  coverImage: string
  cartItems: UserCartItem
  gtUserData?: GtUserData
  stripeSubscriptionId: string
  sidebarTags?: string[]
  lastActiveTimestamp?: string
  deleted?: boolean
}
export type PurchaseOption = {
  enabled: boolean
  price: number
  currency: 'usd'
  type:
    | 'ONE_TIME'
    | 'ONE_TIME_OPTIONAL_DONATION'
    | 'MIRROR_REV_SHARE'
    | 'SUBSCRIPTION_MONTHLY'
  licenseType:
    | 'STANDARD_MIRROR_LICENSE'
    | 'ALL_RIGHTS'
    | 'TRY'
    | 'CC0'
    | 'CC_BY'
    | 'CC_BY_SA'
    | 'EXTERNAL'
  description?: string
  startDate?: string
  endDate?: string
}
export type License = {
  licenseType:
    | 'STANDARD_MIRROR_LICENSE'
    | 'ALL_RIGHTS'
    | 'TRY'
    | 'CC0'
    | 'CC_BY'
    | 'CC_BY_SA'
    | 'EXTERNAL'
  entityType: string
  urlToLicense: string
  purchaseDate: string
  originalEntityIdIfTransferred: string
  tryTimeDuration: string
  externalLicenseIssuer?: string
  externalLicenseName?: string
  externalLicenseUrl?: string
}
export type Tags = {
  search: string[]
  userGenerated: string[]
  thirdParty: string[]
  spaceGenre: string[]
  material: string[]
  theme: string[]
  aiGenerated: string[]
}
export type Role = {
  defaultRole: number
  owners: string[]
}
export type AssetApiResponse = {
  _id: string
  createdAt: string
  updatedAt: string
  name: string
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  gameplayType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  description: string
  mirrorPublicLibrary: boolean
  customData: CustomData
  owner: User
  purchaseOptions: PurchaseOption
  license: License
  creator: User
  public: boolean
  thumbnail: string
  currentFile: string
  fileHash: string
  initPositionX: number
  initPositionY: number
  initPositionZ: number
  initRotationX: number
  initRotationY: number
  initRotationZ: number
  initScaleX: number
  initScaleY: number
  initScaleZ: number
  collisionEnabled: boolean
  staticEnabled: boolean
  massKg: number
  gravityScale: number
  tags: Tags
  isSoftDeleted: boolean
  softDeletedAt: string
  isEquipable: boolean
  objectColor: object
  role: Role
  purchasedParentAssetId: string
  assetPack: boolean
  assetsInPack: string[]
}
export type CreateAssetDto = {
  name: string
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  defaultRole?: 1000 | 700 | 400 | 150 | 100 | 50 | 0 | -100
  description?: string
  mirrorPublicLibrary?: boolean
  customData?: string
  thirdPartySourceDisplayName?: string
  thirdPartySourceUrl?: string
  thumbnail?: string
  categories?: string[]
  currentFile?: string
  fileHash?: string
  public?: boolean
  initPositionX?: number
  initPositionY?: number
  initPositionZ?: number
  initRotationX?: number
  initRotationY?: number
  initRotationZ?: number
  initScaleX?: number
  initScaleY?: number
  initScaleZ?: number
  collisionEnabled?: boolean
  staticEnabled?: boolean
  massKg?: number
  gravityScale?: number
  objectColor?: object
  tags?: Tags
  assetPack?: boolean
  assetsInPack?: string[]
}
export type Asset = {
  _id: string
  createdAt: string
  updatedAt: string
  name: string
  assetType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  gameplayType:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  description: string
  mirrorPublicLibrary: boolean
  customData: CustomData
  owner: User
  purchaseOptions: PurchaseOption
  license: License
  creator: User
  public: boolean
  thumbnail: string
  currentFile: string
  fileHash: string
  initPositionX: number
  initPositionY: number
  initPositionZ: number
  initRotationX: number
  initRotationY: number
  initRotationZ: number
  initScaleX: number
  initScaleY: number
  initScaleZ: number
  collisionEnabled: boolean
  staticEnabled: boolean
  massKg: number
  gravityScale: number
  tags: Tags
  isSoftDeleted: boolean
  softDeletedAt: string
  isEquipable: boolean
  objectColor: object
  role: Role
  purchasedParentAssetId: string
  assetPack: boolean
  assetsInPack: string[]
}
export type AssetFullDataPaginatedResponse = {
  page: number
  perPage: number
  total: number
  totalPage: number
  data: Asset[]
}
export type AssetUsageApiResponse = {
  numberOfSpacesAssetUsedIn: number
}
export type AddTagToAssetDto = {
  assetId: object
  tagName: string
  tagType
  thirdPartySourceHomePageUrl: string
}
export type UpdateAssetTagsDto = {
  assetId: object
  tagType
  tags: string[]
}
export type UpdateAssetDto = {
  name?: string
  assetType?:
    | 'PANEL'
    | 'BOX'
    | 'SPHERE'
    | 'MESH'
    | 'IMAGE'
    | 'AUDIO'
    | 'MATERIAL'
    | 'TEXTURE'
    | 'MAP'
    | 'SCRIPT'
    | 'PACK'
  defaultRole?: 1000 | 700 | 400 | 150 | 100 | 50 | 0 | -100
  description?: string
  mirrorPublicLibrary?: boolean
  customData?: string
  thirdPartySourceDisplayName?: string
  thirdPartySourceUrl?: string
  thumbnail?: string
  categories?: string[]
  currentFile?: string
  fileHash?: string
  public?: boolean
  initPositionX?: number
  initPositionY?: number
  initPositionZ?: number
  initRotationX?: number
  initRotationY?: number
  initRotationZ?: number
  initScaleX?: number
  initScaleY?: number
  initScaleZ?: number
  collisionEnabled?: boolean
  staticEnabled?: boolean
  massKg?: number
  gravityScale?: number
  objectColor?: object
  tags?: Tags
  assetPack?: boolean
  assetsInPack?: string[]
  __t?: object
}
export type FileUploadApiResponse = {
  success: boolean
  relativePath: string
}
export type FileUploadPublicApiResponse = {
  success: boolean
  publicUrl: string
}
export type AddAssetPurchaseOptionDto = {
  enabled: boolean
  price: number
  currency: 'usd'
  type:
    | 'ONE_TIME'
    | 'ONE_TIME_OPTIONAL_DONATION'
    | 'MIRROR_REV_SHARE'
    | 'SUBSCRIPTION_MONTHLY'
  licenseType:
    | 'STANDARD_MIRROR_LICENSE'
    | 'ALL_RIGHTS'
    | 'TRY'
    | 'CC0'
    | 'CC_BY'
    | 'CC_BY_SA'
    | 'EXTERNAL'
  description?: string
  startDate?: string
  endDate?: string
}
export type GetAssetsPriceDto = {
  assetsIds: string[]
}
export type ObjectId = {}
export type Space = {
  name: string
  description: string
  images: string[]
  scriptIds: string[]
  scriptInstances: string[]
  materialInstances: string[]
  type: 'OPEN_WORLD' | 'MATCH'
  activeSpaceVersion: ObjectId
  customData: CustomData
  spaceVariablesData: ObjectId
  lowerLimitY: number
  template:
    | 'mars_template'
    | 'desert_template'
    | 'grass_template'
    | 'flat_template'
  fromTemplate: ObjectId
  terrain: ObjectId
  environment: ObjectId
  role: Role
  creator: ObjectId
  createdAt: string
  updatedAt: string
  _id: string
  tags: Tags
  isTMTemplate: boolean
  maxUsers: number
  AVG_RATING: number
  COUNT_FOLLOW: number
  COUNT_RATING: number
  COUNT_LIKE: number
  COUNT_SAVES: number
  usersCount: number
  usersPresent: string[]
  servers: object
  publicBuildPermissions
  previousUsers: string[]
  mirrorDBRecord: ObjectId
  kickRequests: string[]
}
export type CreateSpaceObjectResponse = {
  name: string
  description: string
  creator: User
  asset: Asset
  customData: CustomData
  role: Role
  parentSpaceObject: ObjectId
  isGroup: boolean
  parentId: string
  space: Space
  locked: boolean
  preloadBeforeSpaceStarts: boolean
  position: object
  rotation: object
  scale: object
  offset: object
  collisionEnabled: boolean
  shapeType: string
  bodyType: string
  staticEnabled: boolean
  massKg: number
  gravityScale: number
  castShadows: boolean
  visibleFrom: number
  visibleTo: number
  visibleFromMargin: number
  visibleToMargin: number
  materialAssetId: string
  objectColor: object
  objectTexture: string
  objectTextureSize: number
  objectTextureSizeV2: object
  objectTextureOffset: object
  objectTextureTriplanar: boolean
  objectTextureRepeat: boolean
  audioAutoPlay: boolean
  audioLoop: boolean
  audioIsSpatial: boolean
  audioPitch: number
  audioBaseVolume: number
  audioSpatialMaxVolume: number
  audioSpatialRange: number
  surfaceMaterialId: string[]
  scriptEvents: string[]
  extraNodes: string[]
  _id: string
  tags: Tags
}
export type CreateSpaceObjectDto = {
  spaceId: string
  name: string
  asset: object
  parentSpaceObject?: string
  description?: string
  locked?: boolean
  preloadBeforeSpaceStarts?: boolean
  position?: object
  rotation?: object
  scale?: object
  offset?: object
  collisionEnabled?: boolean
  shapeType?: string
  bodyType?: string
  staticEnabled?: boolean
  massKg?: number
  gravityScale?: number
  castShadows?: boolean
  visibleFrom?: number
  visibleTo?: number
  visibleFromMargin?: number
  visibleToMargin?: number
  materialAssetId?: string
  objectColor?: object
  objectTexture?: string
  objectTextureSize?: number
  objectTextureSizeV2?: object
  objectTextureOffset?: object
  objectTextureTriplanar?: boolean
  objectTextureRepeat?: boolean
  audioAutoPlay?: boolean
  audioLoop?: boolean
  audioIsSpatial?: boolean
  audioPitch?: number
  audioBaseVolume?: number
  audioSpatialMaxVolume?: number
  audioSpatialRange?: number
  surfaceMaterialId?: string[]
  scriptEvents?: string[]
  extraNodes?: string[]
}
export type SpaceObject = {
  name: string
  description: string
  creator: User
  asset: Asset
  customData: CustomData
  role: Role
  parentSpaceObject: ObjectId
  isGroup: boolean
  parentId: string
  space: Space
  locked: boolean
  preloadBeforeSpaceStarts: boolean
  position: object
  rotation: object
  scale: object
  offset: object
  collisionEnabled: boolean
  shapeType: string
  bodyType: string
  staticEnabled: boolean
  massKg: number
  gravityScale: number
  castShadows: boolean
  visibleFrom: number
  visibleTo: number
  visibleFromMargin: number
  visibleToMargin: number
  materialAssetId: string
  objectColor: object
  objectTexture: string
  objectTextureSize: number
  objectTextureSizeV2: object
  objectTextureOffset: object
  objectTextureTriplanar: boolean
  objectTextureRepeat: boolean
  audioAutoPlay: boolean
  audioLoop: boolean
  audioIsSpatial: boolean
  audioPitch: number
  audioBaseVolume: number
  audioSpatialMaxVolume: number
  audioSpatialRange: number
  surfaceMaterialId: string[]
  scriptEvents: string[]
  extraNodes: string[]
  _id: string
  tags: Tags
}
export type UpdateSpaceObjectTagsDto = {
  spaceObjectId: object
  tagType
  tags: string[]
}
export type AddTagToSpaceObjectDto = {
  spaceObjectId: object
  tagName: string
  tagType
  thirdPartySourceHomePageUrl: string
}
export type UpdateSpaceObjectDto = {
  spaceId?: string
  name?: string
  asset?: object
  parentSpaceObject?: string
  description?: string
  locked?: boolean
  preloadBeforeSpaceStarts?: boolean
  position?: object
  rotation?: object
  scale?: object
  offset?: object
  collisionEnabled?: boolean
  shapeType?: string
  bodyType?: string
  staticEnabled?: boolean
  massKg?: number
  gravityScale?: number
  castShadows?: boolean
  visibleFrom?: number
  visibleTo?: number
  visibleFromMargin?: number
  visibleToMargin?: number
  materialAssetId?: string
  objectColor?: object
  objectTexture?: string
  objectTextureSize?: number
  objectTextureSizeV2?: object
  objectTextureOffset?: object
  objectTextureTriplanar?: boolean
  objectTextureRepeat?: boolean
  audioAutoPlay?: boolean
  audioLoop?: boolean
  audioIsSpatial?: boolean
  audioPitch?: number
  audioBaseVolume?: number
  audioSpatialMaxVolume?: number
  audioSpatialRange?: number
  surfaceMaterialId?: string[]
  scriptEvents?: string[]
  extraNodes?: string[]
}
export type ScriptEntityResponse = {
  blocks: string[]
  role: Role
  creator: ObjectId
  _id: string
}
export type CreateScriptEntityDto = {
  blocks: string[]
  defaultRole?: 1000 | 700 | 400 | 150 | 100 | 50 | 0 | -100
}
export type UpdateScriptEntityDto = {
  blocks?: string[]
  defaultRole?: 1000 | 700 | 400 | 150 | 100 | 50 | 0 | -100
}
export type UserPublicData = {
  _id: string
  createdAt: string
  updatedAt: string
  discordUserId: string
  isInternalAdmin: string
  displayName: string
  email: string
  publicBio: string
  avatarUrl: string
  avatarType: 'MIRROR_AVATAR_V1' | 'READY_PLAYER_ME'
  readyPlayerMeUrlGlb: string
  readyPlayerMeAvatarUrls: string[]
  polygonPublicKey: string
  ethereumPublicKey: string
  twitterUsername: string
  githubUsername: string
  instagramUsername: string
  youtubeChannel: string
  artStationUsername: string
  sketchfabUsername: string
  profileImage: string
  coverImage: string
  sidebarTags: string[]
  gtUserData?: GtUserData
  closedBetaHasClickedInterestedInBeta: boolean
  closedBetaIsInClosedBeta: boolean
  termsAgreedtoClosedAlpha: boolean
  termsAgreedtoGeneralTOSandPP: boolean
  premiumAccess: 'CLOSED_ALPHA' | 'PLUS_ALPHA_OFFER_1' | 'PREMIUM_ALPHA_OFFER_1'
  premiumAccessSince: string
  premiumSubscriptionStatus: 'ACTIVE' | 'PAUSE' | 'CANCEL'
}
export type UpdateUserProfileDto = {
  email?: string
  displayName?: string
  publicBio?: string
  discordUserId?: string
  polygonPublicKey?: string
  ethereumPublicKey?: string
  twitterUsername?: string
  githubUsername?: string
  instagramUsername?: string
  youtubeChannel?: string
  artStationUsername?: string
  sketchfabUsername?: string
  profileImage?: string
  coverImage?: string
}
export type UpdateUserTutorialDto = {
  shownFirstInSpacePopupV1?: boolean
  shownFirstHomeScreenPopupV1?: boolean
  shownWebAppPopupV1?: boolean
}
export type UpdateUserDeepLinkDto = {
  deepLinkKey: string
  deepLinkValue: string
}
export type UpdateUserAvatarDto = {
  avatarUrl?: string
}
export type UpdateUserTermsDto = {
  termsAgreedtoClosedAlpha?: boolean
  termsAgreedtoGeneralTOSandPP?: boolean
}
export type UpdateUserAvatarTypeDto = {
  avatarType: string
  readyPlayerMeUrlGlb?: string
}
export type UpsertUserEntityActionDto = {
  forEntity: string
  actionType: string
  entityType: string
  rating?: number
}
export type Friend = {
  displayName: string
  coverImage: string
  profileImage: string
  id: string
  _id: string
}
export type AddUserCartItemToUserCartDto = {
  purchaseOptionId: string
  forEntity: string
  entityType: string
}
export type AddRpmAvatarUrlDto = {
  rpmAvatarUrl: string
}
export type RemoveRpmAvatarUrlDto = {
  rpmAvatarUrl: string
}
export type CreateUserAccessKeyDto = {
  token: string
  adminNote: string
}
export type SubmitUserAccessKeyDto = {
  key: string
}
export type AddUserSidebarTagDto = {
  sidebarTag: string
}
export type UpdateUserSidebarTagsDto = {
  sidebarTags: string[]
}
export type Map = {}
export type SpacePublicData = {
  _id: string
  createdAt: string
  updatedAt: string
  creator: User
  activeSpaceVersion: string
  name: string
  description: string
  tags: Tags
  scriptIds: string[]
  scriptInstances: Map[]
  materialInstances: Map[]
  images: string[]
  type: string
  role: string
  template:
    | 'mars_template'
    | 'desert_template'
    | 'grass_template'
    | 'flat_template'
  publicBuildPermissions: 'private' | 'manager' | 'contributor' | 'observer'
  AVG_RATING?: number
  COUNT_LIKE?: number
  COUNT_FOLLOW?: number
  COUNT_SAVES?: number
  COUNT_RATING?: number
  usersCount?: number
  servers?: object
  usersPresent?: any
  previousUsers: any
}
export type SpacePublicDataPaginatedResponse = {
  page: number
  perPage: number
  total: number
  totalPage: number
  data: SpacePublicData[]
}
export type AddTagToSpaceDto = {
  spaceId: object
  tagName: string
  tagType
  thirdPartySourceHomePageUrl: string
}
export type UpdateSpaceTagsDto = {
  spaceId: object
  tagType
  tags: string[]
}
export type SpaceVersion = {}
export type SpaceStatsModel = {
  AVG_RATING: number
  COUNT_LIKE: number
  COUNT_FOLLOW: number
  COUNT_SAVES: number
  COUNT_RATING: number
  usersCount: number
  servers: object
  usersPresent: string[]
}
export type UpdateSpaceDto = {
  name?: string
  type?: string
  users?: object
  userGroups?: object
  terrain?: string
  environment?: string
  ownerUserGroup?: string
  template?: string
  lowerLimitY?: number
  description?: string
  images?: string[]
  publicBuildPermissions?
  tags?: Tags
  maxUsers?: number
  patchCustomData?: object
  activeSpaceVersion?: string
  removeCustomDataKeys?: string[]
  patchSpaceVariablesData?: object
  removeSpaceVariablesDataKeys?: string[]
  tagsV2?: string[]
  scriptIds?: string[]
  scriptInstances?: string[]
  materialInstances?: string[]
  kickRequests: string[]
}
export type CreateSpaceDto = {
  name: string
  type: string
  users?: object
  userGroups?: object
  terrain?: string
  environment?: string
  ownerUserGroup?: string
  template: string
  lowerLimitY?: number
  description?: string
  images?: string[]
  publicBuildPermissions?
  tags?: Tags
  maxUsers?: number
}
export type SpaceCopyFromTemplateDto = {
  name: string
  description: string
  publicBuildPermissions
  maxUsers?: number
}
export type RemixSpaceDto = {
  name: string
  description: string
  publicBuildPermissions
  maxUsers?: number
}
export type CreateNewSpaceVersionDto = {
  updateSpaceWithActiveSpaceVersion: boolean
  name: string
}
export type SetUserRoleForOneDto = {
  targetUserId: string
  role
}
export type RemoveUserRoleForOneDto = {
  targetUserId: string
}
export type MaterialInstanceResponse = {
  parameters: object
  _id: string
}
export type CreateMaterialInstanceDto = {
  parameters: object
  spaceId: object
}
export type MaterialInstance = {
  parameters: object
}
export type UpdateMaterialInstanceDto = {
  parameters: object
}
export type TerrainApiResponse = {
  name: string
  description: string
  owner: ObjectId
  public: boolean
  positionX: number
  positionY: number
  positionZ: number
  generator: string
  material: 'mars' | 'desert' | 'grass'
  noiseType: 1 | 0 | 2 | 3 | 4 | 5
  seed: number
  heightStart: number
  heightRange: number
  createdAt: string
  updatedAt: string
  _id: string
}
export type CreateTerrainDto = {
  name: string
  material?: string
  description?: string
  public?: boolean
  generator?:
    | ''
    | 'flat_generator01'
    | 'fnl_generator01'
    | 'rolling_hills_generator01'
  noiseType?: number
  positionX?: number
  positionY?: number
  positionZ?: number
  heightStart?: number
  heightRange?: number
  seed?: number
}
export type UpdateTerrainDto = {
  name?: string
  material?: string
  description?: string
  public?: boolean
  generator?:
    | ''
    | 'flat_generator01'
    | 'fnl_generator01'
    | 'rolling_hills_generator01'
  noiseType?: number
  positionX?: number
  positionY?: number
  positionZ?: number
  heightStart?: number
  heightRange?: number
  seed?: number
}
export type Clouds = {
  albedo: string[]
  coverage: number
  height: number
  timeScale: number
  visible: boolean
}
export type EnvironmentApiResponse = {
  _id: string
  skyTopColor: object
  skyHorizonColor: object
  skyBottomColor: object
  sunCount: number
  suns: string[]
  fogEnabled: boolean
  fogVolumetric: boolean
  fogDensity: number
  fogColor: object
  ssao: boolean
  glow: boolean
  glowHdrThreshold: number
  shadowsPreset: string
  globalIllumination: boolean
  environment: string
  tonemap: number
  clouds: Clouds
  ssr?: boolean
  createdAt: string
  updatedAt: string
}
export type UpdateEnvironmentDto = {
  _id?: string
  skyTopColor?: object
  skyHorizonColor?: object
  skyBottomColor?: object
  sunCount?: number
  suns?: string[]
  fogEnabled?: boolean
  fogVolumetric?: boolean
  fogDensity?: number
  fogColor?: object
  ssao?: boolean
  glow?: boolean
  glowHdrThreshold?: number
  shadowsPreset?: string
  globalIllumination?: boolean
  environment?: string
  tonemap?: number
  clouds?: Clouds
  ssr?: boolean
  createdAt?: string
  updatedAt?: string
}
export type UserGroupApiResponse = {
  _id: string
  createdAt: string
  updatedAt: string
  name: string
  public: string
  discordUrl: string
  ethereumDaoContractPublicKey: string
  polygonDaoContractPublicKey: string
  moderators: string[]
  owners: string[]
  primaryContact: User
  image: string
  publicDescription: string
  twitterUrl: string
  users: string[]
  websiteUrl: string
  creator: User
}
export type CreateUserGroupMembershipDto = {
  group: string
  status: 'ACTIVE' | 'SUSPENDED' | 'PENDING' | 'TERMINATED' | 'TEMPORARY'
  creator: string
  user: string
  role: 'GROUP_OWNER' | 'GROUP_ADMIN' | 'GROUP_MODERATOR' | 'MEMBER'
  expirationDate: string
}
export type CreateUserGroupDto = {
  name: string
  public: boolean
  publicDescription: string
  primaryContact: string
  moderators: string[]
  owners: string[]
  image: string
  discordUrl: string
  polygonDaoContractPublicKey: string
  ethereumDaoContractPublicKey: string
  twitterUrl: string
  websiteUrl: string
  creator: string
}
export type UserGroup = {
  _id: string
  createdAt: string
  updatedAt: string
  name: string
  public: string
  discordUrl: string
  ethereumDaoContractPublicKey: string
  polygonDaoContractPublicKey: string
  moderators: string[]
  owners: string[]
  primaryContact: User
  image: string
  publicDescription: string
  twitterUrl: string
  users: string[]
  websiteUrl: string
  creator: User
}
export type UserGroupInviteApiResponse = {
  group: UserGroup
  unlimited: boolean
  used: boolean
  status: string
  completed: boolean
  completedDate: string
  creator: ObjectId
  recipient: ObjectId
  _id: string
}
export type UpdateUserGroupDto = {
  name?: string
  public?: boolean
  publicDescription?: string
  primaryContact?: string
  moderators?: string[]
  owners?: string[]
  image?: string
  discordUrl?: string
  polygonDaoContractPublicKey?: string
  ethereumDaoContractPublicKey?: string
  twitterUrl?: string
  websiteUrl?: string
  creator?: string
}
export type CreatePlayServerDto = {
  zoneName?: string
}
export type UpdateZoneDto = {
  zoneMode?: string
  owner?: string
  state?: string
  ipAddress?: string
  port?: number
  uuid?: string
  url?: string
  gdServerVersion?: string
  containerLastRefreshed?: string
  name?: string
  description?: string
  space?: string
  spaceVersion?: string
  id?: string
}
export type UpdateMirrorDbRecordDto = {
  recordData: object
}
export type CreateTagResponse = {
  name: string
  parentTag: object
  creator: User
  _id: string
}
export type CreateTagDto = {
  name: string
  tagType:
    | 'MATERIAL'
    | 'THEME'
    | 'THIRD_PARTY_SOURCE'
    | 'AI_GENERATED_BY_TM'
    | 'SPACE_GENRE'
    | 'USER_GENERATED'
  mirrorPublicLibrary: boolean
  public: boolean
  parentTag: string
}
export type UpdateTagDto = {
  name?: string
  tagType?:
    | 'MATERIAL'
    | 'THEME'
    | 'THIRD_PARTY_SOURCE'
    | 'AI_GENERATED_BY_TM'
    | 'SPACE_GENRE'
    | 'USER_GENERATED'
  mirrorPublicLibrary?: boolean
  public?: boolean
  parentTag?: string
}
export type FirebaseCustomTokenResponse = {
  token: string
}
export type DiscordUserInfo = {
  id: string
  username: string
  avatar: string
  avatar_decoration: string
  discriminator: string
  public_flags: number
  flags: number
  banner: string
  banner_color: string
  accent_color: string
  locale: string
  mfa_enabled: boolean
  email: string
  verified: boolean
}
export type MintCustomTokenDiscordDto = {
  accessToken: string
  userInfo: DiscordUserInfo
}
export type CreateUserResponse = {
  _id: string
  createdAt: string
  updatedAt: string
  firebaseUID: string
  isInternalAdmin: string
  displayName: string
  groupInvitations: string[]
  friends: string[]
  sentFriendRequestsToUsers: string[]
  recents: UserRecents
  marketing: UserMarketing
  email: string
  emailVerified: boolean
  publicBio: string
  avatarUrl: string
  avatarType: 'MIRROR_AVATAR_V1' | 'READY_PLAYER_ME'
  customData: CustomData
  readyPlayerMeUrlGlb: string
  readyPlayerMeAvatarUrls: string[]
  closedBetaHasClickedInterestedInBeta: boolean
  closedBetaIsInClosedBeta: boolean
  termsAgreedtoClosedAlpha: boolean
  termsAgreedtoGeneralTOSandPP: boolean
  discordUserId: string
  polygonPublicKey: string
  ethereumPublicKey: string
  twitterUsername: string
  githubUsername: string
  instagramUsername: string
  youtubeChannel: string
  artStationUsername: string
  sketchfabUsername: string
  premiumAccess: 'CLOSED_ALPHA' | 'PLUS_ALPHA_OFFER_1' | 'PREMIUM_ALPHA_OFFER_1'
  premiumAccessSince: string
  premiumSubscriptionStatus: 'ACTIVE' | 'PAUSE' | 'CANCEL'
  stripeCustomerId: string
  stripeAccountId: string
  deepLinkKey: string
  deepLinkValue: string
  deepLinkLastUpdatedAt: string
  profileImage: string
  coverImage: string
  cartItems: UserCartItem
  gtUserData?: GtUserData
  stripeSubscriptionId: string
  sidebarTags?: string[]
  lastActiveTimestamp?: string
  deleted?: boolean
}
export type CreateUserWithEmailPasswordDto = {
  email: string
  password: string
  displayName: string
  termsAgreedtoGeneralTOSandPP: boolean
  gtUserType?
}
export type CreateAuthUserResponse = {
  _id: string
  createdAt: string
  updatedAt: string
  firebaseUID: string
  isInternalAdmin: string
  displayName: string
  groupInvitations: string[]
  friends: string[]
  sentFriendRequestsToUsers: string[]
  recents: UserRecents
  marketing: UserMarketing
  email: string
  emailVerified: boolean
  publicBio: string
  avatarUrl: string
  avatarType: 'MIRROR_AVATAR_V1' | 'READY_PLAYER_ME'
  customData: CustomData
  readyPlayerMeUrlGlb: string
  readyPlayerMeAvatarUrls: string[]
  closedBetaHasClickedInterestedInBeta: boolean
  closedBetaIsInClosedBeta: boolean
  termsAgreedtoClosedAlpha: boolean
  termsAgreedtoGeneralTOSandPP: boolean
  discordUserId: string
  polygonPublicKey: string
  ethereumPublicKey: string
  twitterUsername: string
  githubUsername: string
  instagramUsername: string
  youtubeChannel: string
  artStationUsername: string
  sketchfabUsername: string
  premiumAccess: 'CLOSED_ALPHA' | 'PLUS_ALPHA_OFFER_1' | 'PREMIUM_ALPHA_OFFER_1'
  premiumAccessSince: string
  premiumSubscriptionStatus: 'ACTIVE' | 'PAUSE' | 'CANCEL'
  stripeCustomerId: string
  stripeAccountId: string
  deepLinkKey: string
  deepLinkValue: string
  deepLinkLastUpdatedAt: string
  profileImage: string
  coverImage: string
  cartItems: UserCartItem
  gtUserData?: GtUserData
  stripeSubscriptionId: string
  sidebarTags?: string[]
  lastActiveTimestamp?: string
  deleted?: boolean
}
export type UserFeedbackItemResponse = {
  name: string
  description: string
  public: boolean
  status: string
  creator: User
  owner: ObjectId
  upvotedBy: string[]
  role: Role
  _id: string
  createdAt: string
  updatedAt: string
  __t: string
}
export type UpdateUserFeedbackDto = {
  name?: string
  description?: string
  userFeedbackType?: 'FEATURE_REQUEST' | 'BUG'
  USER_FEEDBACK_ITEM_STATUS?: 'OPEN' | 'PLANNED' | 'IN_PROGRESS' | 'COMPLETE'
  public?: boolean
}
export type UserFeedbackItem = {
  name: string
  description: string
  public: boolean
  status: string
  creator: User
  owner: ObjectId
  upvotedBy: string[]
  role: Role
  _id: string
}
export type UserFeedbackCommentResponse = {
  text: string
  owner: User
  creator: User
  userFeedbackItem: UserFeedbackItem
  adminHidden: boolean
  role: Role
  _id: string
  createdAt: string
  updatedAt: string
}
export type CreateUserFeedbackItemDto = {
  name: string
  description?: string
  userFeedbackType?: 'FEATURE_REQUEST' | 'BUG'
  USER_FEEDBACK_ITEM_STATUS?: 'OPEN' | 'PLANNED' | 'IN_PROGRESS' | 'COMPLETE'
  public?: boolean
}
export type CreateVoteOnUserFeedbackItemDto = {
  userFeedbackItemId: string
  vote: string
}
export type CreateUserFeedbackCommentDto = {
  text: string
  userFeedbackItemId: string
}
export type StripeCardModel = {}
export type AddCardDto = {
  cardToken: string
}
export type PaymentMethodModel = {}
export type CreatePaymentSessionDto = {
  purchaseOptionIds: string[]
}
export type PaymentIntentMetadata = {
  purchaseOptionIds: string[]
}
export type CreatePaymentIntentDto = {
  amount: number
  currency: string
  payment_method: string
  metadata: PaymentIntentMetadata
}
export type SetupPaymentIntentResponseDto = {
  clientSecret: string
}
export type SetupPaymentIntentDto = {
  paymentMethod: string
}
export type AddExternalBankAccountTokenDto = {
  externalBankAccountToken: string
}
export type CreateTransferDto = {
  amount: number
  currency: string
  destinationAccountId: object
  paymentIntentId: string
}
export type CreateProductDto = {
  name: string
  description: string
  unitAmount: number
  currency: string
}
export type CreateSubscriptionDto = {
  subscriptionType:
    | 'CLOSED_ALPHA'
    | 'PLUS_ALPHA_OFFER_1'
    | 'PREMIUM_ALPHA_OFFER_1'
}
export type PaymentLinkForOptinalDonationAsset = {
  amount: number
  purchaseOptionId: object
}
export type CreateBlockResponse = {
  name: string
  blockType: 'GENERIC' | 'BEHAVIOR' | 'TRAIT' | 'EVENT'
  description?: string
  creator: User
  mirrorPublicLibrary: boolean
  _id: string
}
export type CreateBlockDto = {
  name: string
  blockType: string
  description?: string
  mirrorPublicLibrary?: boolean
}
export type UpdateBlockDto = {
  name?: string
  blockType?: string
  description?: string
  mirrorPublicLibrary?: boolean
}
export type TransferSpaceDto = {
  gcsConfig: object
  sourceEnv: object
  destinationEnv: object
  spaceId: string
  newSpaceOwnerId?: string
}
export type SendChatMessageDto = {
  messageBody: string
  senderName: string
  serviceUserId: string
  serviceChannelId: string
  serviceChannelName: string
  serviceName: string
  serviceSentStatus: string
  rawText: string
  finalText: string
  sender: string
  channel: string
}
export type ChatMessageResponse = {
  senderName: string
  channel: string
  serviceUserId: string
  serviceChannelId: string
  serviceChannelName: string
  serviceName: string
  serviceSentStatus: string
  rawText: string
  finalText: string
  sender: User
  _id: string
}
export const {
  useAppControllerGetHelloQuery,
  useAppControllerGetHealthQuery,
  useAppControllerThrowIntentionalErrorMutation,
  useAppControllerNonResponsiveEndpointMutation,
  useGodotServerOverrideConfigControllerFindOneQuery,
  useGodotServerOverrideConfigControllerFindOneAliasQuery,
  useGodotServerOverrideConfigControllerCreateMutation,
  useVenlyControllerGetVenlyWalletsForUserQuery,
  useStorageControllerGetClientVersionQuery,
  useStorageControllerGetClientUrlQuery,
  useStorageControllerGetClientUrlsQuery,
  useStorageControllerDownloadFileQuery,
  useMirrorServerConfigControllerGetConfigQuery,
  useMirrorServerConfigControllerSetGdServerVersionMutation,
  useAssetControllerSearchQuery,
  useAssetControllerSearchV2Query,
  useAssetControllerGetMirrorPublicLibraryAssetsQuery,
  useAssetControllerGetMirrorPublicLibraryAssetsV2Query,
  useAssetControllerCreateMutation,
  useAssetControllerGetUserRecentInstancedAssetsQuery,
  useAssetControllerCreateWithUploadMutation,
  useAssetControllerGetAssetsForMeQuery,
  useAssetControllerGetAssetsForMeV2Query,
  useAssetControllerGetAllAccessibleAssetsOfUserQuery,
  useAssetControllerGetAllAccessibleAssetsOfUserV2Query,
  useAssetControllerGetRecentAssetsForUserQuery,
  useAssetControllerGetRecentAssetsForUserV2Query,
  useAssetControllerGetPaginatedMyAssetsQuery,
  useAssetControllerGetPaginatedMyAssetsV2Query,
  useAssetControllerGetPaginatedMirrorAssetsQuery,
  useAssetControllerGetPaginatedMirrorAssetsV2Query,
  useAssetControllerFindAllForUserQuery,
  useAssetControllerFindOneAssetUsageQuery,
  useAssetControllerGetAssetsByTagQuery,
  useAssetControllerAddTagToAssetsWithRoleChecksMutation,
  useAssetControllerUpdateAssetTagsByTypeWithRoleChecksMutation,
  useAssetControllerDeleteTagFromAssetWithRoleChecksMutation,
  useAssetControllerFindOneQuery,
  useAssetControllerUpdateMutation,
  useAssetControllerRemoveMutation,
  useAssetControllerUndoAssetSoftDeleteMutation,
  useAssetControllerUploadMutation,
  useAssetControllerUploadPublicMutation,
  useAssetControllerUploadThumbnailMutation,
  useAssetControllerGetAssetQuery,
  useAssetControllerAddAssetPurchaseOptionMutation,
  useAssetControllerDeleteAssetPurchaseOptionMutation,
  useAssetControllerCheckIfAssetCopiedQuery,
  useAssetControllerCopyFreeAssetMutation,
  useAssetControllerGetAssetsListPriceMutation,
  useAssetControllerDownloadAssetQuery,
  useAssetControllerGetAllAssetsBySpaceIdWithRolesCheckQuery,
  useAssetControllerAddAssetToPackWithRolesCheckMutation,
  useAssetControllerDeleteAssetFromPackWithRolesCheckMutation,
  useSpaceObjectControllerCreateMutation,
  useSpaceObjectControllerCreateAliasMutation,
  useSpaceObjectControllerCopyMutation,
  useSpaceObjectControllerFindAllBySpaceIdQuery,
  useSpaceObjectControllerFindAllBySpaceIdWithRolesCheckQuery,
  useSpaceObjectControllerGetSpaceObjectsByTagQuery,
  useSpaceObjectControllerUpdateSpaceObjectTagsByTypeWithRoleChecksMutation,
  useSpaceObjectControllerAddTagToSpaceObjectWithRoleChecksMutation,
  useSpaceObjectControllerDeleteTagFromSpaceObjectWithRoleChecksMutation,
  useSpaceObjectControllerSearchSpaceObjectsPaginatedQuery,
  useSpaceObjectControllerFindOneQuery,
  useSpaceObjectControllerUpdateMutation,
  useSpaceObjectControllerRemoveMutation,
  useScriptEntityControllerGetRecentScriptsQuery,
  useScriptEntityControllerCreateMutation,
  useScriptEntityControllerFindOneQuery,
  useScriptEntityControllerUpdateMutation,
  useScriptEntityControllerDeleteMutation,
  useUserControllerFindOneQuery,
  useUserControllerFindOneWithPublicProfileQuery,
  useUserControllerSearchQuery,
  useUserControllerGetCurrentUserQuery,
  useUserControllerGetUserRecentsQuery,
  useUserControllerUploadPublicMutation,
  useUserControllerUpdateProfileMutation,
  useUserControllerUpdateUserTutorialMutation,
  useUserControllerUpdateDeepLinkMutation,
  useUserControllerUpdateAvatarMutation,
  useUserControllerUpdateTermsAgreedToMutation,
  useUserControllerUpdateAvatarTypeMutation,
  useUserControllerGetUserEntityActionQuery,
  useUserControllerGetUserEntityActionsByMeForEntityQuery,
  useUserControllerUpsertUserEntityActionMutation,
  useUserControllerDeleteUserEntityActionMutation,
  useUserControllerGetMyFriendsQuery,
  useUserControllerGetMyFriendRequestsQuery,
  useUserControllerAcceptFriendRequestMutation,
  useUserControllerRejectFriendRequestMutation,
  useUserControllerGetSentFriendRequestsQuery,
  useUserControllerSendFriendRequestMutation,
  useUserControllerRemoveFriendMutation,
  useUserControllerGetUserCartQuery,
  useUserControllerAddUserCartItemToUserCartMutation,
  useUserControllerRemoveAllUserItemsFromCartMutation,
  useUserControllerRemoveUserCartItemFromUserCartMutation,
  useUserControllerAddRpmAvatarUrlMutation,
  useUserControllerRemoveRpmAvatarUrlMutation,
  useUserControllerCreateSignUpKeyMutation,
  useUserControllerSubmitUserAccessKeyMutation,
  useUserControllerGetUserSidebarTagsQuery,
  useUserControllerAddUserSidebarTagMutation,
  useUserControllerUpdateUserSidebarTagsMutation,
  useUserControllerDeleteUserSidebarTagMutation,
  useFileUploadControllerBatchAssetUploadFromQueueBucketMutation,
  useSpaceControllerGetAllPublicForUserQuery,
  useSpaceControllerGetAllPublicForUserV2Query,
  useSpaceControllerSearchQuery,
  useSpaceControllerSearchV2Query,
  useSpaceControllerFindAllForMeWhereOwnerQuery,
  useSpaceControllerFindAllForMeWhereOwnerPaginatedV2Query,
  useSpaceControllerFindAllForMeWhereOwnerPaginatedV3Query,
  useSpaceControllerGetPopularSpacesQuery,
  useSpaceControllerGetFavoriteSpacesQuery,
  useSpaceControllerGetRecentSpacesQuery,
  useSpaceControllerGetSpacesByTagsQuery,
  useSpaceControllerAddTagToSpaceWithRoleChecksMutation,
  useSpaceControllerUpdateSpaceTagsByTypeWithRoleChecksMutation,
  useSpaceControllerDeleteTagFromSpaceWithRoleChecksMutation,
  useSpaceControllerFindDiscoverSpacesForUserQuery,
  useSpaceControllerFindDiscoverSpacesForUserPaginatedV2Query,
  useSpaceControllerFindDiscoverSpacesForUserPaginatedV3Query,
  useSpaceControllerFindSpaceTemplatesQuery,
  useSpaceControllerGetPublishedSpacesQuery,
  useSpaceControllerGetPublishedSpacesV2Query,
  useSpaceControllerGetLatestPublishedSpaceBySpaceIdQuery,
  useSpaceControllerRefreshStatsQuery,
  useSpaceControllerFindOneQuery,
  useSpaceControllerUpdateMutation,
  useSpaceControllerRemoveMutation,
  useSpaceControllerFindAllForUserV2Query,
  useSpaceControllerFindAllForUserQuery,
  useSpaceControllerCreateMutation,
  useSpaceControllerClearVoxelsMutation,
  useSpaceControllerCopyMutation,
  useSpaceControllerCopyFromTemplateMutation,
  useSpaceControllerRemixSpaceMutation,
  useSpaceControllerPublishMutation,
  useSpaceControllerGetPublishedSpacesBySpaceIdQuery,
  useSpaceControllerUploadPublicMutation,
  useSpaceControllerRestoreSpaceFromSpaceVersionMutation,
  useSpaceControllerSetUserRoleForOneMutation,
  useSpaceControllerRemoveUserRoleForOneMutation,
  useSpaceControllerKickMeMutation,
  useSpaceControllerGetAssetsListPerSpaceQuery,
  useSpaceGodotServerControllerGetLatestPublishedSpaceBySpaceIdQuery,
  useSpaceGodotServerControllerGetActiveSpaceVersionForSpaceBySpaceIdQuery,
  useSpaceGodotServerControllerUpdateTerrainMutation,
  useMaterialInstanceControllerCreateMutation,
  useMaterialInstanceControllerFindOneQuery,
  useMaterialInstanceControllerUpdateMutation,
  useMaterialInstanceControllerDeleteMutation,
  useTerrainControllerFindAllForUserQuery,
  useTerrainControllerCreateMutation,
  useTerrainControllerFindAllPublicQuery,
  useTerrainControllerFindOneQuery,
  useTerrainControllerUpdateMutation,
  useTerrainControllerRemoveMutation,
  useEnvironmentControllerCreateMutation,
  useEnvironmentControllerFindOneQuery,
  useEnvironmentControllerUpdateMutation,
  useEnvironmentControllerRemoveMutation,
  useUserGroupControllerCreateMutation,
  useUserGroupControllerGetAllGroupsForMeQuery,
  useUserGroupControllerGetAllGroupInvitesForMeQuery,
  useUserGroupControllerFindPublicGroupMembershipForOtherUserQuery,
  useUserGroupControllerGetGroupMembershipForMeQuery,
  useUserGroupControllerSearchQuery,
  useUserGroupControllerFindOneQuery,
  useUserGroupControllerUpdateMutation,
  useUserGroupControllerRemoveMutation,
  useZoneControllerJoinBuildServerQuery,
  useZoneControllerGetPlayServersForSpaceVersionIdQuery,
  useZoneControllerGetPlayServersForSpaceIdQuery,
  useZoneControllerJoinPlayServerByZoneIdQuery,
  useZoneControllerJoinPlayServerBySpaceIdQuery,
  useZoneControllerCreatePlayServerWithSpaceVersionMutation,
  useZoneControllerCreatePlayServerWithSpaceMutation,
  useZoneControllerFindAllZonesBySpaceIdQuery,
  useZoneControllerFindAllZonesByUserIdQuery,
  useZoneControllerFindOneZoneQuery,
  useZoneControllerUpdateOneZoneMutation,
  useZoneControllerStopAllActiveZonesMutation,
  useMirrorDbControllerGetRecordFromMirrorDbByIdQuery,
  useMirrorDbControllerUpdateRecordInMirrorDbByIdMutation,
  useMirrorDbControllerDeleteRecordFromMirrorDbByIdMutation,
  useMirrorDbControllerGetRecordFromMirrorDbBySpaceIdQuery,
  useMirrorDbControllerGetRecordFromMirrorDbBySpaceVersionIdQuery,
  useTagControllerCreateMutation,
  useTagControllerFindAllMirrorPublicLibraryTagsQuery,
  useTagControllerFindAllThemeTagsQuery,
  useTagControllerGetTagTypesQuery,
  useTagControllerFindOneQuery,
  useTagControllerUpdateMutation,
  useTagControllerRemoveMutation,
  useAuthControllerDiscordMutation,
  useAuthControllerCreateUserWithEmailPasswordAndTypeMutation,
  useAuthControllerAuthedUserCreateMutation,
  useAuthControllerCreateAnonymousAccountMutation,
  useAuthControllerConvertAnonymousAccountToFullMutation,
  useAuthControllerDeleteAccountMutation,
  useUserFeedbackControllerFindNewestPublicUserFeedbackItemsQuery,
  useUserFeedbackControllerFindTopPublicUserFeedbackItemsQuery,
  useUserFeedbackControllerGetUserFeedbackItemTypesQuery,
  useUserFeedbackControllerFindOneQuery,
  useUserFeedbackControllerUpdateMutation,
  useUserFeedbackControllerRemoveUserFeedbackItemMutation,
  useUserFeedbackControllerFindCommentsQuery,
  useUserFeedbackControllerFindAllPublicUserFeedbackItemsQuery,
  useUserFeedbackControllerCreateMutation,
  useUserFeedbackControllerVoteOnUserFeedbackItemMutation,
  useUserFeedbackControllerCreateCommentMutation,
  useUserFeedbackControllerRemoveCommentMutation,
  useStripeControllerCreateConnectAccountMutation,
  useStripeControllerDeleteConnectAccountMutation,
  useStripeControllerGetCardsListQuery,
  useStripeControllerCreateCardMutation,
  useStripeControllerDeleteCardMutation,
  useStripeControllerGetPaymentMethodsQuery,
  useStripeControllerSetDefaultPaymentMethodMutation,
  useStripeControllerCreatePaymentSessionMutation,
  useStripeControllerCreatePaymentIntentMutation,
  useStripeControllerSetupIntentMutation,
  useStripeControllerCreateCustomerAccountMutation,
  useStripeControllerGetStripeAccountInfoQuery,
  useStripeControllerAddBankAccountMutation,
  useStripeControllerTransfersAmountMutation,
  useStripeControllerCreateProductMutation,
  useStripeControllerGetAllProductsWithPriceQuery,
  useStripeControllerCreateSubscriptionMutation,
  useStripeControllerDeleteSubscriptionMutation,
  useStripeControllerPauseSubscriptionMutation,
  useStripeControllerResumeSubscriptionMutation,
  useStripeControllerGetSubscriptionStatusQuery,
  useStripeControllerCreateDashboardLinkQuery,
  useStripeControllerCreateCustomerPortalLinkQuery,
  useStripeControllerCreatePaymentLinkForOptionalDonationAssetPaymentMutation,
  useStripeControllerHandleStripeWebhookMutation,
  useBlockControllerCreateMutation,
  useBlockControllerFindOneQuery,
  useBlockControllerUpdateMutation,
  useBlockControllerRemoveMutation,
  useTransferBetweenEnvironmentsControllerTransferSpaceMutation,
  useChatControllerSendMessageMutation,
  useChatControllerSendMessageToChannelMutation,
  useChatControllerGetChannelTextMessageHistoryQuery
} = injectedRtkApi
