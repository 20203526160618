import { FC } from 'react'
import Heading from '../../components/Heading'
import Paragraph from '../../components/Paragraph'
import { Button } from '../../components/Button'
import { Form, Formik } from 'formik'
import { userFeedbackItemSchema } from './userFeedbackItem.validationSchema'
import { useModal } from '../modal/useModal'
import InputField from '../../components/InputField'
import TextareaField from '../../components/TextareaField'
import CheckboxField from '../../components/CheckboxField'
import SelectField from '../../components/SelectField'
import FormHeaderSection from '../../components/FormHeaderSection'
import FileField from '../files/FileField'
import { useSubmitUserFeedbackItem } from './useSubmitUserFeedbackItem'
import { useIsAdminOnAdminPage } from '../../hooks/admin'
import {
  CreateUserFeedbackItemDto,
  useUserFeedbackControllerFindAllPublicUserFeedbackItemsQuery
} from '../api/apiMirrorSlice.generated'

type userFeedbackType = CreateUserFeedbackItemDto['userFeedbackType']
interface CreateUserFeedbackItemValues {
  name: string
  description: string
  public?: boolean
  userFeedbackType: userFeedbackType
}

const CreateUserFeedbackItemForm: FC = () => {
  const { addUserFeedbackItem, isLoading } = useSubmitUserFeedbackItem()
  const { refetch } =
    useUserFeedbackControllerFindAllPublicUserFeedbackItemsQuery()
  const { dismissModal } = useModal()
  const isAdminOnAdminPage = useIsAdminOnAdminPage()

  const typeOptions: {
    value: userFeedbackType
    label: string
  }[] = [
    { value: 'FEATURE_REQUEST', label: 'Feature Request' },
    {
      value: 'BUG',
      label: 'Bug'
    }
  ]

  const initialValues: CreateUserFeedbackItemValues = {
    name: '',
    description: '',
    userFeedbackType: 'FEATURE_REQUEST',
    public: undefined
  }

  const onSubmit = async (values) => {
    await addUserFeedbackItem({
      name: values.name,
      description: values.description,
      userFeedbackType: values.userFeedbackType.value,
      public: true
    })
    dismissModal()
    refetch()
  }

  return (
    <div className="max-w-md">
      <FormHeaderSection>
        <Heading size="2">Submit Feature Request</Heading>
        <Paragraph>What would you like to see in The Mirror?</Paragraph>
      </FormHeaderSection>

      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={userFeedbackItemSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="grid grid-cols-1 gap-y-3">
            <InputField
              type="text"
              name="name"
              label="Name"
              disabled={isLoading}
            />
            <SelectField
              label="Type"
              name="userFeedbackType"
              disabled={isLoading}
              options={typeOptions}
            />
            <TextareaField
              name="description"
              label="Description"
              disabled={isLoading}
            />

            {/* Admin-Only Fields */}
            {isAdminOnAdminPage && (
              <CheckboxField
                name="mirrorPublicLibrary"
                label="Whether you want this feature request to be public"
                disabled={isLoading}
              />
            )}

            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
              Submit Feature Request
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default CreateUserFeedbackItemForm
