var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"276d3abde819651b6028fe462d856bc951e19d84"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const envFromFirebase = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
let env = 'dev'
if (envFromFirebase.includes('prod')) {
  env = 'prod'
} else if (envFromFirebase.includes('staging')) {
  env = 'staging'
} else if (envFromFirebase.includes('dev')) {
  env = 'dev'
}

//override localhost
if (
  process.env.NEXT_PUBLIC_MIRROR_APP_BASEURL &&
  process.env.NEXT_PUBLIC_MIRROR_APP_BASEURL.includes('localhost')
) {
  env = 'localhost'
}

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://2138ac3c81ce45b9ae143b2047bdb798@o4504755616415744.ingest.sentry.io/4504755625590784',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: env // just use the firebase project ID for ease
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
