import { useAppDispatch } from '../../store/store'
import { hideModal, showModal } from './modalSlice'
import { ModalContent } from './modalContent.enum'

interface ModalComponent {
  name: ModalContent | null
  props?: any
}

/** Hook for showing and hiding the global modal instance. */
export const useModal = () => {
  const dispatch = useAppDispatch()

  /** Dynamically load a component into global modal instance with or without props. */
  const showModalWith = (component: ModalComponent) => {
    dispatch(showModal({ component }))
  }

  const dismissModal = () => {
    dispatch(hideModal())
  }

  return { showModalWith, dismissModal }
}
