import { FC } from 'react'
import cn from 'classnames'

type ImageProps = {
  url: string | undefined
  fallbackUrl?: string
  className?: string
  imageClassName?: string
  fallbackImageClassName?: string
  alt: string
}

const urlTestImage = process.env.NEXT_PUBLIC_IMAGE_URL_TEST

export const Image: FC<ImageProps> = ({
  url,
  fallbackUrl = urlTestImage,
  className,
  imageClassName = '',
  fallbackImageClassName = '',
  alt
}) => {
  return (
    <object
      data={url ? url : fallbackUrl}
      type="image/png"
      className={cn(className, imageClassName)}
    >
      <img
        className={cn(className, fallbackImageClassName)}
        src={fallbackUrl}
        alt={alt}
      />
    </object>
  )
}
