import { FC, ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAppSelector } from '../store/store'
import { selectModal } from '../features/modal/modalSlice'

type Props = {
  children: ReactNode
  isOpen: boolean
  setIsOpenSidebar: any
}

/**
 * Navbar/Drawer/Sidebar refactor recommended
 * Must handle drawer close logic manually with current implementation
 */
const DrawerSide: FC<Props> = ({ children, isOpen, setIsOpenSidebar }) => {
  const router = useRouter()
  const modalTriggered = useAppSelector(selectModal).show

  /** Handles locking scroll when drawer is opened */
  useEffect(() => {
    isOpen && (document.body.style.overflow = 'hidden')
    !isOpen && (document.body.style.overflow = '')
  }, [isOpen])

  /** Handles force closing the drawer on lg:screen resize to prevent page scroll locked */
  useEffect(() => {
    const resizeWindow = (): void =>
      window.innerWidth > 1023 && setIsOpenSidebar(false)
    window.addEventListener('resize', resizeWindow)
    return () => window.removeEventListener('resize', resizeWindow)
  }, [setIsOpenSidebar])

  /** Handles closing drawer on route change/modal open */
  useEffect(() => {
    setIsOpenSidebar(false)
  }, [router.asPath, setIsOpenSidebar, modalTriggered])

  return (
    <div
      className={
        'fixed top-[3.75rem] left-0 z-10 ease-in-out w-full duration-150 h-[100%]' +
        (isOpen
          ? ' transition-opacity opacity-100 -translate-x-0 -translate-y-0 '
          : ' transition-all opacity-0 -translate-x-full ')
      }
    >
      <div
        className={
          'ease-in-out duration-150 transition-all transform rounded-br-xl w-full h-full' +
          (isOpen ? ' translate-x-0' : ' -translate-x-full ')
        }
      >
        {children}
      </div>
    </div>
  )
}

export default DrawerSide
