import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import {
  apiMirrorSlice,
  useAssetControllerAddAssetPurchaseOptionMutation,
  useAssetControllerCopyFreeAssetMutation,
  useAssetControllerDeleteAssetPurchaseOptionMutation,
  useAssetControllerGetPaginatedMyAssetsV2Query
} from 'features/api/apiMirrorSlice.generated'
import { selectMyUser } from 'features/users/usersSlice'
import { categoryOptions, sortOptionsAsset } from './constants'
import { useRouter } from 'next/router'
import { parseSearchInput } from 'utils/parseFileInput'
import { NotificationType, useNotification } from 'features/notification'

type QueryOptions = {
  field: string
  search: string
  sortKey: string
  sortDirection: 1 | -1
  page: number
  perPage: number
  type?: string
  assetType?: string
  assetTypes?: string[]
  containAnyTagsV2?: string[]
  tagType?: string
  tag?: string[]
}
interface SelectedTypes {
  _id?: string
  name?: string
}
interface SortTypes {
  _id: string
  name: string
  sortKey: string
  sortDirection: number
}

const defaultFilters: QueryOptions = {
  field: '',
  search: '',
  sortKey: 'createdAt',
  sortDirection: -1,
  page: 0,
  perPage: 12,
  type: '',
  assetType: '',
  assetTypes: []
}

const useAssets = () => {
  const dispatch = useAppDispatch()
  const { data: user } = useAppSelector(selectMyUser)
  const [assets, setAssets] = useState([])
  const [filters, setFilters] = useState<QueryOptions>(defaultFilters)
  const [filterType, setFilterType] = useState<SelectedTypes>(
    categoryOptions[0]
  )
  const [sortType, setSortType] = useState<SortTypes>(sortOptionsAsset[2])

  const router = useRouter()

  const { notify } = useNotification()

  const queryOptions: QueryOptions = useMemo(() => {
    const { name, tags } = parseSearchInput(filters.search)

    const options: QueryOptions = {
      ...filters,
      search: name,
      page: router.query.assetPage
        ? Number(router.query.assetPage)
        : filters.page + 1,
      perPage: 12
    }

    if (
      filters?.containAnyTagsV2?.length &&
      filters?.containAnyTagsV2?.length > 0
    ) {
      options.containAnyTagsV2 = filters.containAnyTagsV2
    }

    if (tags.length > 0) {
      options.tagType = 'search'
      options.tag = tags
    }

    if (filters.assetTypes?.length && filters.assetTypes.length > 0) {
      options.type = filters.type
      options.assetType = filters.assetType
      options.assetTypes = filters.assetTypes
    } else {
      delete options.type
      delete options.assetType
      delete options.assetTypes
    }

    return options
  }, [filters])

  const {
    data: allAssetsData,
    isLoading,
    isFetching,
    refetch: getAssets
  } = useAssetControllerGetPaginatedMyAssetsV2Query(queryOptions as any, {
    skip: !user
  })

  const [
    updatePurchaseOption,
    { isLoading: updatingPurchaseOption, data: updatedPurchaseOption }
  ] = useAssetControllerAddAssetPurchaseOptionMutation()

  const [
    deleteAssetPurchaseOption,
    { isLoading: deleteLoading, data: deletedPurchaseOption }
  ] = useAssetControllerDeleteAssetPurchaseOptionMutation()

  const [copyAsset, { data: copiedAssetData, isLoading: copyLoading }] =
    useAssetControllerCopyFreeAssetMutation()

  useEffect(() => {
    if (router.query.assetPage) {
      const page = Math.max(
        parseInt(router.query.assetPage as string, 10) - 1,
        0
      )
      setFilters((prevFilters) => ({ ...prevFilters, page: page }))
    }
  }, [router.query.assetPage])

  const handleCopy = async (assetId) => {
    try {
      await copyAsset({
        assetId: assetId
      }).unwrap()
      notify('Asset saved successfully', NotificationType.SUCCESS)
      dispatch(apiMirrorSlice.util.invalidateTags(['Assets']))
    } catch (error: any) {
      if (error?.data) {
        notify(error?.data?.message, NotificationType.ERROR)
      } else {
        notify('Something went wrong!', NotificationType.ERROR)
      }
    }
  }

  const handlePageChange = useCallback(
    (selectedPage: number) => {
      setFilters((prevFilters) => ({ ...prevFilters, page: selectedPage }))
      router.push({
        pathname: '/assets',
        query: { ...router.query, assetPage: selectedPage + 1 }
      })
    },
    [router, setFilters]
  )

  const handleSearchInputChange = (val: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: val,
      field: 'name',
      page: 0
    }))
  }

  useEffect(() => {
    if (allAssetsData?.data) {
      setAssets(allAssetsData.data as any)
    }
  }, [allAssetsData])

  useEffect(() => {
    if (router.query.id) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: allAssetsData ? allAssetsData?.totalPage - 1 : filters.page + 1
      }))
    }
  }, [router, allAssetsData])

  useEffect(() => {
    if (filterType?._id) {
      if (filterType._id !== 'ALL') {
        const type = filterType?._id
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: router.query.assetPage ? Number(router.query.assetPage) : 0,
          type: type,
          assetType: type,
          assetTypes: [type]
        }))
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: router.query.assetPage ? Number(router.query.assetPage) : 0,
          assetTypes: []
        }))
      }
    }
  }, [filterType])

  useEffect(() => {
    if (sortType?._id) {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        page: router.query.assetPage ? Number(router.query.assetPage) : 0,
        sortDirection: sortType.sortDirection,
        sortKey: sortType.sortKey
      }))
    }
  }, [sortType])

  useEffect(() => {
    dispatch(
      apiMirrorSlice.util.updateQueryData(
        'assetControllerGetPaginatedMyAssetsV2',
        queryOptions as any,
        (assetData: any) => assetData
      )
    )
  }, [queryOptions, updatedPurchaseOption, deletedPurchaseOption])

  return {
    isLoading,
    isFetching,
    allAssetsData,
    assets,
    getAssets,
    setAssets,
    filters,
    setFilters,
    handlePageChange,
    handleSearchInputChange,
    filterType,
    setFilterType,
    sortType,
    setSortType,
    queryOptions,
    updatePurchaseOption,
    deleteAssetPurchaseOption,
    handleCopy,
    copiedAssetData,
    copyLoading
  }
}

export default useAssets
