import { ErrorMessage, useField } from 'formik'
import React, { FC, HTMLProps, ReactNode } from 'react'

interface Props extends HTMLProps<HTMLInputElement> {
  name: string
  children?: ReactNode
  label?: string
}
const CheckboxField: FC<Props> = ({ children, label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <>
      <legend className="sr-only">Checkbox</legend>
      <div className="relative flex-wrap items-start">
        <div className="flex items-center h-5">
          <input
            type="checkbox"
            id={props.name}
            className={`h-4 w-4 border rounded bg-transparent checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain mr-2 cursor-pointer ${
              meta.touched && meta.error
                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                : 'focus:border-ringBlue'
            } ${field.checked ? 'border-gray-300' : 'border-inactive'}`}
            {...props}
            {...field}
          />

          <label
            htmlFor={props.name}
            className={`font-primary text-sm ${
              field.checked ? 'text-white' : 'text-inactive'
            }`}
          >
            {children ? children : label}
          </label>
        </div>

        <ErrorMessage
          component="div"
          name={props.name}
          className="text-left text-red-400"
        />
      </div>
    </>
  )
}

export default CheckboxField
