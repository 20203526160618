import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getAuth } from 'firebase/auth'

export interface RouteGuardProps {
  isProtected: boolean
  isDisabled?: boolean
}

/** Enforces protected and disabled routes (getStaticProps for accessed page) */
export function useRouteGuard({ isDisabled, isProtected }: RouteGuardProps) {
  const router = useRouter()

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      const redirect = isDisabled || (isProtected && !user)

      if (redirect) {
        router.replace('/')
      }
    })

    return () => unsubscribe()
  }, [isDisabled, isProtected, router])
}
