import posthog from 'posthog-js'
import mp from 'mixpanel-browser'
import { ANALYTICS_EVENT } from './event.enum'

export let postHogHasBeenInitialized = false
export const initPostHog = () => {
  if (typeof window !== 'undefined' && !postHogHasBeenInitialized) {
    mp.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage'
    })
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string, {
      api_host: 'https://app.posthog.com'
    })
    postHogHasBeenInitialized = true
  }

  return posthog
}

export function captureEvent(event: ANALYTICS_EVENT, properties?: any) {
  mp.track(event, properties)
  posthog.capture(event, properties)
}

/**
 * @description Used to ID a user to Mixpanel and (Posthog with an email)
 * @date 2022-08-09 17:37
 */
export function identifyUserUidEmailPostHog(uid: string, email: string) {
  mp.identify(uid)
  mp.people.set({ $email: email })
  posthog.identify(uid, { email, identifySource: 'the-mirror-website' })
}

/**
 * @description Used to ID the user to Mixpanel and (PostHog without an email)
 * @date 2022-08-09 17:37
 */
export function identifyUserUidOnlyPostHog(uid: string) {
  mp.identify(uid)
  posthog.identify(uid, { identifySource: 'the-mirror-website' })
}
