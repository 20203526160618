import {
  CreateUserFeedbackItemDto,
  useUserFeedbackControllerCreateMutation
} from '../api/apiMirrorSlice.generated'

type FormValues = {
  name: string
  description: string
  userFeedbackType: string
  public: boolean
}

type SubmitUserFeedbackItem = {
  addUserFeedbackItem: (values: FormValues) => void
  isLoading: boolean
}

export const useSubmitUserFeedbackItem = (): SubmitUserFeedbackItem => {
  const [
    createUserFeedbackItem,
    { isLoading: isCreateUserFeedbackItemLoading }
  ] = useUserFeedbackControllerCreateMutation()

  const addUserFeedbackItem = async (values: FormValues) => {
    // CREATE THE UserFeedbackItem
    const createUserFeedbackItemDto = {
      name: values.name,
      description: values.description,
      userFeedbackType: values.userFeedbackType,
      public: values.public
    } as CreateUserFeedbackItemDto
    const response = await createUserFeedbackItem({
      createUserFeedbackItemDto
    }).unwrap()
  }

  return {
    addUserFeedbackItem,
    isLoading: isCreateUserFeedbackItemLoading
  }
}
