import * as Yup from 'yup'
import { ASSET_ERROR } from './assetError.enum'

export const assetSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, ASSET_ERROR.NAME_MIN)
    .max(50, ASSET_ERROR.NAME_MAX)
    .required(ASSET_ERROR.NAME_REQUIRED),
  fileUpload: Yup.mixed().required(`${ASSET_ERROR.REQUEST_FILE}`)
})
