import { useEffect, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import {
  apiMirrorSlice,
  useSpaceControllerFindAllForMeWhereOwnerPaginatedV2Query
} from 'features/api/apiMirrorSlice.generated'
import { selectMyUser } from 'features/users/usersSlice'
import { parseSearchInput } from 'utils/parseFileInput'
import { useRouter } from 'next/router'

type QueryOptions = {
  field: string
  search: string
  sortKey: string
  sortDirection: 1 | -1
  page: number
  perPage: number
  tagType?: string
  tag?: string[]
}

const defaultFilters: QueryOptions = {
  field: '',
  search: '',
  sortKey: 'createdAt',
  sortDirection: -1,
  page: 0,
  perPage: 12
}

const useSpaces = () => {
  const dispatch = useAppDispatch()
  const [spaces, setSpaces] = useState([])
  const { data: user } = useAppSelector(selectMyUser)
  const router = useRouter()

  const [filters, setFilters] = useState<QueryOptions>(defaultFilters)

  const queryOptions: QueryOptions = useMemo(() => {
    const { name, tags } = parseSearchInput(filters.search)
    const options: QueryOptions = {
      ...filters,
      search: name,
      page: filters.page + 1,
      perPage: 12
    }

    if (tags.length > 0) {
      options.tagType = 'search'
      options.tag = tags
    }

    return options
  }, [filters])

  const {
    data: spacesData,
    isLoading,
    isError,
    isFetching,
    refetch: getSpaces
  } = useSpaceControllerFindAllForMeWhereOwnerPaginatedV2Query(
    queryOptions as any,
    {
      skip: !user
    }
  )

  const handleSearchInputChange = (val: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: val,
      field: 'name',
      page: router.query.spacePage
        ? parseInt(router.query.spacePage as string) - 1
        : 0
    }))
    router.push({ pathname: '/spaces' })
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      setSpaces(spacesData?.data as any)
    }
  }, [spacesData])

  useEffect(() => {
    if (router.query.spacePage) {
      const page = Math.max(
        parseInt(router.query.spacePage as string, 10) - 1,
        0
      )
      setFilters((prev: any) => ({ ...prev, page }))
      router.push({ pathname: '/spaces', query: { spacePage: page + 1 } })
    }
  }, [router.query.spacePage])

  useEffect(() => {
    dispatch(
      apiMirrorSlice.util.updateQueryData(
        'spaceControllerFindAllForMeWhereOwnerPaginatedV2',
        queryOptions as any,
        (spaces: any) => spaces
      )
    )
  }, [queryOptions])

  const handlePageChange = (selectedPage: number) => {
    const newPage = Math.max(selectedPage, 0)
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }))
    router.push({ pathname: '/spaces', query: { spacePage: newPage + 1 } })
  }

  return {
    isLoading,
    isFetching,
    filters,
    setFilters,
    spaces,
    getSpaces,
    handlePageChange,
    handleSearchInputChange,
    queryOptions
  }
}

export default useSpaces
