import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Spaces'>({
  endpoints: {
    spaceControllerFindOne: {
      providesTags: (result, error, arg) => [{ type: 'Spaces', id: arg.id }]
    },
    spaceControllerFindAllForUser: {
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: 'Spaces' as const,
                id: _id
              }))
            ]
          : ['Spaces']
    },
    spaceControllerGetAllPublicForUser: {
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: 'Spaces' as const,
                id: _id
              }))
            ]
          : ['Spaces']
    },
    spaceControllerCreate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Spaces', id: result?._id }
      ]
    },
    spaceControllerUpdate: {
      invalidatesTags: (result, error, arg) => [{ type: 'Spaces', id: arg.id }]
    },
    spaceControllerRemove: {
      invalidatesTags: (result, error, arg) => [{ type: 'Spaces', id: arg.id }]
    }
  }
})

export const selectMySpaces =
  apiMirrorSlice.endpoints.spaceControllerFindAllForUser.select()

export const selectMySpacesPreview = (state) =>
  selectMySpaces(state).data?.slice(0, 3)
