// This may be removed when we move to full RTK codegen. Either way, it will also be updated to string types, e.g. PANEL = 'PANEL'. See https://github.com/the-mirror-megaverse/mirror-server/pull/111/files
export enum ASSET_TYPE {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  MAP = 'MAP',
  PANEL = 'PANEL',
  BOX = 'BOX',
  SPHERE = 'SPHERE',
  MESH = 'MESH',
  MATERIAL = 'MATERIAL',
  SCRIPT = 'SCRIPT',
  // fallback
  UNKNOWN = 'UNKNOWN'
}

export enum AssetNames {
  AUDIO = 'Audio',
  IMAGE = 'Image',
  MAP = 'Map',
  MESH = 'Mesh',
  SPHERE = 'Sphere',
  PANEL = 'Panel',
  BOX = 'Box',
  MATERIAL = 'Material',
  SCRIPT = 'Script'
}
