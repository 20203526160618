import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'
import * as Sentry from '@sentry/nextjs'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Discover'>({
  endpoints: {
    spaceControllerFindDiscoverSpacesForUserPaginatedV3: {
      providesTags: (result, error, arg) => [{ type: 'Discover' }]
    }
  }
})

export const selectMyDiscoverList = (options: any) =>
  apiMirrorSlice.endpoints.spaceControllerFindDiscoverSpacesForUserPaginatedV3.select(
    options as any
  )
