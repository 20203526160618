import React, { FC, Fragment, useEffect, useState } from 'react'
import { Image } from 'components/Image'
import { Transition, Combobox } from '@headlessui/react'
import { useRouter } from 'next/router'

export interface SearchOption {
  id: number
  term?: string
  route: string
  alt: string
  imageUrl: string
  label: string
}

type SearchBarProps = {
  searchOptions: SearchOption[]
}

const SearchBar: FC<SearchBarProps> = ({ searchOptions = [] }) => {
  const router = useRouter()
  const [selected, setSelected] = useState(searchOptions[1])
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    if (router?.query?.query) {
      const queryValue = router.query.query as string
      setSearchTerm(queryValue)
      searchOptions.forEach((option) => {
        if (option.route.includes(router?.route)) {
          setSelected({ ...option, term: queryValue })
        }
      })
    }

    return () => {
      setSearchTerm('')
      setSelected(searchOptions[1])
    }
  }, [router?.query?.query])

  const onSearchSelected = (option: SearchOption) => {
    if (!searchTerm) {
      return
    }
    if (option.route) {
      setSearchTerm(searchTerm)
      setSelected({ ...option, term: searchTerm })
      const term = encodeURIComponent(searchTerm)
      router.push(`${option.route}query=${term}`)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    setSelected((prev) => ({ ...prev, term: e.target.value }))
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      const term = encodeURIComponent(searchTerm)
      if (router?.query?.query) {
        router.push(`${selected.route}query=${term}`)
      } else {
        router.push(`${selected.route}query=${term}`)
        setSelected((prev) => ({ ...prev, term: searchTerm }))
      }
    }
  }

  return (
    <div className="mobile:ml-0 mobile:mt-3 md:ml-0 lg:flex items-center w-[15rem] mobile:w-full mobile:px-3">
      <Combobox value={selected} onChange={onSearchSelected}>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-bluenav text-left border-none">
            <Combobox.Input
              as="input"
              displayValue={(option: SearchOption) => option?.term as string}
              className="block border-none pl-10 pr-3 text-white text-base placeholder-gray-500 rounded-2xl bg-initialGradient placeholder:text-base font-primary h-[2.5rem] w-full focus:ring-1 focus:ring-inset "
              placeholder={'Search In The Mirror'}
              onChange={handleChange}
              onKeyUp={handleKeyPress}
            />
            <Combobox.Button className="absolute inset-y-0 left-0 flex items-center px-2">
              <Image
                url={`/Icons/navigation/icon/inactive/discover.svg`}
                className={'w-[1.18rem] h-[1.18rem] text-gray-400'}
                alt="Discover Icon"
                fallbackUrl={'images/placeholder_image.png'}
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchTerm(searchTerm)}
          >
            <Combobox.Options className="absolute w-full overflow-auto rounded-md bg-bluedarkbg shadow-md shadow-disabledMirror py-1 text-base z-10">
              {searchOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-6 pr-4 border-l-2
                      border-l-transparent ${
                        active
                          ? 'bg-gray-900 text-white border-l-2 border-l-blueMirror'
                          : 'text-gray-900'
                      }`
                  }
                  value={option}
                >
                  <div className="flex">
                    <Image
                      url={option.imageUrl}
                      className={
                        'w-[1.18rem] h-[1.18rem] text-gray-400 mr-[1rem]'
                      }
                      alt={option.alt}
                      fallbackUrl={'images/placeholder_image.png'}
                    />
                    <span className="text-grayMirror">
                      {!searchTerm ? 'Search ' : ''}
                      <span className=" font-bold text-white mr-1">
                        {searchTerm}
                      </span>
                      {option.label}
                    </span>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default React.memo(SearchBar)
