import { useEffect, useState, useMemo } from 'react'
import { useAppDispatch } from 'store/store'
import {
  apiMirrorSlice,
  useSpaceControllerFindDiscoverSpacesForUserPaginatedV3Query
} from 'features/api/apiMirrorSlice.generated'
import { sortOptionsSpaces } from 'features/assets/constants'
import { parseSearchInput } from 'utils/parseFileInput'
import { useRouter } from 'next/router'

type QueryOptions = {
  field: string
  search: string
  sortKey: string
  sortDirection: 1 | -1
  page: number
  perPage: number
  tagType?: string
  tag?: string[]
}

interface SortTypes {
  _id: string
  name: string
  sortKey: string
  sortDirection: number
}

const defaultFilters: QueryOptions = {
  field: '',
  search: '',
  sortKey: 'createdAt',
  sortDirection: -1,
  page: 0,
  perPage: 12
}

const useDiscover = () => {
  const dispatch = useAppDispatch()
  const [spaces, setSpaces] = useState([])
  const [sortType, setSortType] = useState<SortTypes>(sortOptionsSpaces[3])
  const router = useRouter()

  const [filters, setFilters] = useState<QueryOptions>(defaultFilters)

  const queryOptions: QueryOptions = useMemo(() => {
    const { name, tags } = parseSearchInput(filters.search)
    const options: QueryOptions = {
      ...filters,
      search: name,
      page: filters.page + 1,
      perPage: 12
    }

    if (tags.length > 0) {
      options.tagType = 'search'
      options.tag = tags
    }
    return options
  }, [filters])

  const {
    data: spacesData,
    isLoading,
    isError,
    isFetching,
    refetch: getSpaces
  } = useSpaceControllerFindDiscoverSpacesForUserPaginatedV3Query(
    queryOptions as any,
    {
      // skip: !user,
    }
  )

  const handlePageChange = (selectedPage: number) => {
    const newPage = Math.max(selectedPage, 0)
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }))
    router.push({
      pathname: '/discover',
      query: { page: newPage + 1 }
    })
  }

  const handleSearchInputChange = (val: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: val,
      field: 'name',
      page: router.query.page ? parseInt(router.query.page as string) - 1 : 0
    }))
    router.push({ pathname: '/discover' })
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      setSpaces(spacesData?.data as any)
    }
  }, [spacesData])

  useEffect(() => {
    if (router.query.page) {
      const page = Math.max(parseInt(router.query.page as string, 10) - 1, 0)
      setFilters((prev: any) => ({ ...prev, page }))
      router.push({ pathname: '/discover', query: { page: page + 1 } })
    }
  }, [router.query.page])

  useEffect(() => {
    if (sortType?._id) {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        page: router.query.page ? parseInt(router.query.page as string) - 1 : 0,
        sortDirection: sortType.sortDirection,
        sortKey: sortType.sortKey
      }))
    }
  }, [sortType])

  useEffect(() => {
    dispatch(
      apiMirrorSlice.util.updateQueryData(
        'spaceControllerFindDiscoverSpacesForUserPaginatedV3',
        queryOptions as any,
        (spaces: any) => spaces
      )
    )
  }, [queryOptions])

  return {
    isLoading,
    isFetching,
    filters,
    setFilters,
    spaces,
    getSpaces,
    sortType,
    setSortType,
    handlePageChange,
    handleSearchInputChange,
    queryOptions
  }
}

export default useDiscover
