import * as Yup from 'yup'

export enum USER_ACCESS_KEY_ERROR {
  NAME_MIN = 'Please enter a longer name',
  NAME_MAX = 'Please enter a shorter name ',
  NAME_REQUIRED = 'Please enter a name',
  DESCRIPTION_REQUIRED = 'Please enter a description'
}

export const userAccessKeySchema = Yup.object().shape({
  key: Yup.string()
    .min(2, USER_ACCESS_KEY_ERROR.NAME_MIN)
    .max(250, USER_ACCESS_KEY_ERROR.NAME_MAX)
    .required(USER_ACCESS_KEY_ERROR.NAME_REQUIRED)
})
