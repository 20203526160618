import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
import classNames from 'classnames'

const headings = {
  '2': (children) => (
    <h2 className="text-break font-primary text-white font-bold lg:text-[1.8rem] lg:leading-[2.25rem] xl:leading-[3rem] leading-tight xl:text-[2.5rem] md:text-[2rem] mobile:text-[2rem]">
      {children}
    </h2>
  ),
  '3': (children) => (
    <h3 className="text-break text-2xl font-bold tracking-wide text-white sm:text-3xl">
      {children}
    </h3>
  )
}

type Props = {
  children: ReactNode
  size: string
  backButton?: string
}
const Heading: FC<Props> = ({ children, size, backButton }) => {
  const router = useRouter()

  if (backButton) {
    return (
      <div className="flex flex-col gap-1 -mt-3.5 lt-lg:mt-0">
        <a
          className={classNames(
            'font-primary font-semibold uppercase cursor-pointer tracking-wider',
            'text-xs text-blueMirror hover:brightness-125 hover:ease-in duration-100'
          )}
          onClick={router.back}
        >
          {'< '}
          {backButton}
        </a>
        {headings[size](children)}
      </div>
    )
  }

  return headings[size](children)
}

export default Heading
