import { createSelector, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from './store'

export type Asset = {
  _id: string
  public: boolean
  type: string // Replace with enum,
  createdDate: string
  modifiedDate: string
  owner: string
  initScaleZ: number
  initScaleY: number
  initScaleX: number
  initRotationZ: number
  initRotationY: number
  initRotationX: number
  description: string
  assetType: number
  name: string
  __v: number
  files: string[]
}

export const assetsSlice = createSlice({
  name: 'assets',

  initialState: {
    pending: false,
    error: false,
    data: [],
    currentImage: '',
    currentlySelectedAsset: null,
    errorMessage: '',
    errorStatus: ''
  } as any,

  reducers: {
    setEnt(state, action) {
      return action.payload
    },
    setCreateImage(state, action) {
      state.currentImage = action.payload
    },
    hasUploadImage(state) {
      return typeof state.currentImage != 'undefined'
    },
    getUploadImage(state) {
      return state.currentImage
    },

    loadingAssets(state): void {
      state.pending = true
      state.error = false
    },
    assetsLoadComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = action.payload
    },
    loadingAssetsError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload?.error?.data?.message[0]
      state.errorStatus = action.payload?.error?.status
    },
    creatingAsset(state): void {
      state.pending = true
      state.error = false
    },
    assetCreationCompleted(state, action): void {
      state.pending = false
      state.error = false
      state.data.push(action.payload)
    },
    creatingAssetError(state, action): void {
      state.pending = true
      state.error = false
    },
    uploadingAsset(state): void {
      state.pending = true
      state.error = false
    },
    assetUploadCompleted(state, action): void {
      state.pending = false
      state.error = false
    },
    assetUploadError(state, action): void {
      state.pending = true
      state.error = false
    },

    loadingAssetById(state): void {
      state.pending = true
      state.error = false
    },
    assetLoadByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAsset = action.payload
    },
    loadingAssetByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.error.data.message[0]
      state.errorStatus = action.payload.error.status
    },
    updateAssetById(state): void {
      state.pending = true
      state.error = false
    },
    assetUpdateByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.currentlySelectedAsset = null
      state.data = state.data.filter((asset) => asset._id != action.payload._id)
      state.data.push(action.payload)
    },
    updateAssetByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.error.data.message[0]
      state.errorStatus = action.payload.error.status
    },

    deleteAssetById(state): void {
      state.pending = true
      state.error = false
    },
    assetDeleteByIdComplete(state, action): void {
      state.pending = false
      state.error = false
      state.data = state.data.filter((asset) => asset._id != action.payload._id)
    },
    deleteAssetByIdError(state, action): void {
      state.pending = false
      state.error = true
      state.errorMessage = action.payload.error.data.message[0]
      state.errorStatus = action.payload.error.status
    },

    searchAssets(state): void {
      state.pending = true
      state.error = false
      state.searchResults = null
    },
    searchAssetsComplete(state, action): void {
      state.pending = false
      state.error = false
      state.searchResults = action.payload
    },
    searchAssetsError(state, action): void {
      state.pending = false
      state.error = true
      state.searchResults = null
      state.errorMessage = action.payload.error.data.message[0]
      state.errorStatus = action.payload.error.status
    },

    addAssetToStore(state, action): void {
      state.data.push(action.payload)
    },
    getMyAssetsFromStore(state, action) {
      return { ...state, ...action }
    },
    setSelectedAsset(state, action): void {
      state.currentlySelectedAsset = action.payload
    },
    deleteAsset(state, action): void {
      state.data = state.data.filter((asset) => asset._id != action.payload)
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.assets
      }
    }
  }
})

export const selectAssets = (state: AppState) => state.assets
export const selectAssetsPreview = createSelector(selectAssets, (assets) =>
  assets.data.slice(0, 5)
)
