export const type = {
  ONE_TIME: 'ONE_TIME',
  ONE_TIME_OPTIONAL_DONATION: 'ONE_TIME_OPTIONAL_DONATION',
  MIRROR_REV_SHARE: 'MIRROR_REV_SHARE',
  SUBSCRIPTION_MONTHLY: 'SUBSCRIPTION_MONTHLY'
}

export const licenseType = {
  MIRROR_REV_SHARE: 'MIRROR_REV_SHARE',
  TRY: 'TRY',
  CC0: 'CC0',
  CC_BY: 'CC_BY',
  CC_BY_SA: 'CC_BY_SA',
  ALL_RIGHTS: 'ALL_RIGHTS',
  EXTERNAL_ONE_TIME_PURCHASE: 'EXTERNAL_ONE_TIME_PURCHASE',
  EXTERNAL_SUBSCRIPTION: 'EXTERNAL_SUBSCRIPTION',
  STANDARD_ONE_TIME_PURCHASE: 'STANDARD_ONE_TIME_PURCHASE',
  SUBSCRIPTION_MONTHLY: 'SUBSCRIPTION_MONTHLY',
  STANDARD_MIRROR_LICENSE: 'STANDARD_MIRROR_LICENSE'
}

export const assetSaleType = {
  FREE: 'FREE',
  DONATION: 'DONATION',
  PAID: 'PAID'
}

export const ROLE = {
  OWNER: 1000,
  // can create/read/update/delete, but not edit role permissions
  MANAGER: 700,
  // can create/read, but not update/delete
  CONTRIBUTOR: 400,
  // the user is a doctor/provider of healthcare (GOOD THOUGHTS)
  PROVIDER: 150,
  // Entity, e.g. a Space, can be entered/observed
  OBSERVER: 100,
  // Entity will appear in search results, but that's it
  DISCOVER: 50,
  // Entity will not appear in search results. Returns a 404 when someone with NO_ROLE attempts to access
  NO_ROLE: 0,
  // Intentionally blocked; this is different from NO_ROLE. Negative numbers override all positive roles (e.g. a block on a user overrides any other ROLE they have, unless they are an owner)
  BLOCK: -100
}

export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
