import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'UserFeedbackComments'>({
  endpoints: {
    // userFeedbackControllerFindComments: {
    //   providesTags: (result, error, arg) =>
    //     result
    //       ? [
    //           ...result?.map(({ _id }) => ({
    //             type: 'UserFeedbackComments' as const,
    //             id: _id
    //           }))
    //         ]
    //       : ['UserFeedbackComments', 'UserFeedbackItems']
    // },
    // userFeedbackControllerCreateComment: {
    //   invalidatesTags: (result, error, arg) => [
    //     { type: 'UserFeedbackComments', id: result?._id }
    //   ]
    // },
    userFeedbackControllerFindComments: {
      providesTags: ['UserFeedbackComments']
    },
    userFeedbackControllerCreateComment: {
      invalidatesTags: () => {
        return ['UserFeedbackComments']
      }
    },
    userFeedbackControllerRemoveComment: {
      invalidatesTags: ['UserFeedbackComments']
    }
  }
})

export const selectUserFeedbackItems = (id) =>
  apiMirrorSlice.endpoints.userFeedbackControllerFindComments.select(id)
