import { FC } from 'react'
import Heading from './Heading'
import Paragraph from './Paragraph'
import { Button } from './Button'
import { Formik, Field, Form, FormikProps } from 'formik'
import TextArea from './TextArea'
import { useCreateMutation } from 'services/assetListings'
import { CreateListing, InitialListing } from '../interfaces/CreateListing'
import { listingSchema } from './listing/listing.validationSchema'
import { ButtonTransparent } from './ButtonTransparent'
import { NotificationType, useNotification } from '../features/notification'
import { useAppSelector } from '../store/store'
import { selectMyAssets } from '../features/assets/assetsSlice'
import InputField from './InputField'
import { useModal } from '../features/modal/useModal'
import useAssets from 'features/assets/useAssets'

/**
 * TODO
 *  AssetListing mutation using old mutation hook - update to codegen endpoint
 */
const CreateListingForm: FC = () => {
  const { queryOptions } = useAssets()
  const [createListing, { isLoading }] = useCreateMutation()
  const { data: assetsData } = useAppSelector(selectMyAssets(queryOptions))
  const { notify } = useNotification()
  const { dismissModal } = useModal()

  const assets = assetsData?.data || []

  const onSubmit = (values) => {
    const formData: CreateListing = {
      titleLowerCase: values.titleLowerCase,
      priceEth: values.priceEth,
      image: values.image,
      asset: values.asset,
      description: values.description,
      endDate: new Date(),
      tags: '',
      creator: assets?.[0].owner as unknown as string // TODO fix types of owner, should be id string
    }

    createListing(formData)

    notify('Your listing has been created!', NotificationType.SUCCESS)
    dismissModal()
  }

  const initialValues: InitialListing = {
    titleLowerCase: '',
    description: '',
    priceEth: 0,
    image: '',
    asset: '',
    creator: ''
  }

  return (
    <>
      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={listingSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, resetForm }: FormikProps<any>) => (
          <Form>
            <div className="grid justify-items-center mb-[1.75rem]">
              <Heading size="2">Create your listing</Heading>
              <Paragraph>
                Please enter the information about your new NFT listing!
              </Paragraph>
            </div>
            <div className="grid grid-cols-1 gap-y-4  max-w-[27.375rem] ">
              <InputField type="text" label="Title" name="titleLowerCase" />

              <Field name="description">
                {({ field }) => <TextArea label="Description" {...field} />}
              </Field>

              <InputField
                type="number"
                label="Price in Ethereum"
                name="priceEth"
              />

              <InputField type="text" label="Image URL" name="image" />

              <div className="relative">
                <label
                  htmlFor="asset"
                  className="absolute left-1 top-1 text-textInput text-xs peer-placeholder-shown:text-sm peer-focus:text-xs"
                >
                  Asset
                </label>
                <Field
                  className="block bg-bluenav text-white pt-6 w-full sm:text-sm:block border-gray-300  focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded"
                  as="select"
                  id="asset"
                  name="asset"
                >
                  {assets?.map((asset) => (
                    <option
                      className="py-10 bg-bluenav"
                      key={asset._id}
                      value={asset._id}
                    >
                      {asset.name}
                    </option>
                  ))}
                </Field>
                {touched.asset && errors.asset && (
                  <div className="grid text-red-400 sm:col-span-6 justify-items-start">
                    {errors.asset}
                  </div>
                )}
              </div>

              <div className="w-full">
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Create Listing
                </Button>
              </div>

              <div className=" grid justify-items-center">
                <ButtonTransparent
                  onClick={dismissModal}
                  className="underline underline-offset-1"
                >
                  Cancel
                </ButtonTransparent>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateListingForm
