import { VenlyConnect } from '@venly/connect'

/**
 * @description Should be called once on app load
 */
export let venlyConnect: VenlyConnect
export function initVenlyWidget() {
  let environment = 'staging'
  if (process.env.NEXT_PUBLIC_VENLY_ENVIRONMENT === 'local') {
    environment = 'local'
  }
  if (process.env.NEXT_PUBLIC_VENLY_ENVIRONMENT === 'prod') {
    environment = 'prod'
  }
  venlyConnect = new VenlyConnect(
    `${process.env.NEXT_PUBLIC_VENLY_CLIENT_ID_WIDGET}`,
    {
      environment
    }
  )
}
/**
 *
 */
export function getVenlyConnectInstance() {
  if (!venlyConnect) {
    throw new Error('Venly not initialized')
  }
  return venlyConnect
}
