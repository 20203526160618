import { Button } from 'components/Button'
import Paragraph from 'components/Paragraph'
import {
  UpdateAssetDto,
  useAssetControllerUpdateMutation
} from 'features/api/apiMirrorSlice.generated'
import useAssets from 'features/assets/useAssets'
import { useModal } from 'features/modal/useModal'
import { NotificationType, useNotification } from 'features/notification'
import { DELETE, UPDATE } from 'features/profile/constants'
import React from 'react'

const AssetPurchaseOption = ({
  actionType,
  asset,
  setAsset,
  priceOptions,
  assetPrice,
  setAssetPrice,
  isAssetWithDonation,
  addAssetPurchaseOptionDto
}) => {
  const { notify } = useNotification()
  const { dismissModal } = useModal()
  const { updatePurchaseOption, deleteAssetPurchaseOption } = useAssets()

  const [updateAsset] = useAssetControllerUpdateMutation()

  const handleFreeAssetRemoval = async () => {
    const updateAssetDto = { mirrorPublicLibrary: false }
    const res: any = await updateAsset({
      id: asset?._id,
      updateAssetDto
    }).unwrap()
    setAsset(res)
    notify('Your asset is removed for download', NotificationType.SUCCESS)
  }

  const handleDonationAssetRemoval = async () => {
    const updateAssetDto = { mirrorPublicLibrary: false }
    await updateAsset({ id: asset?._id, updateAssetDto }).unwrap()
    const res: any = await deleteAssetPurchaseOption({
      purchaseOptionId: isAssetWithDonation?._id,
      assetId: asset?._id,
      addAssetPurchaseOptionDto
    }).unwrap()
    setAsset(res)
    notify('Your asset is removed for download', NotificationType.SUCCESS)
  }

  const handleSaleAssetRemoval = async () => {
    const res: any = await deleteAssetPurchaseOption({
      purchaseOptionId: assetPrice?._id,
      assetId: asset?._id,
      addAssetPurchaseOptionDto
    }).unwrap()
    setAsset(res)
    setAssetPrice({})
    notify('Your asset is removed for sale', NotificationType.SUCCESS)
  }

  const handleFreeAssetUpdate = async () => {
    const updateAssetDto = { mirrorPublicLibrary: true }
    const res: any = await updateAsset({
      id: asset?._id,
      updateAssetDto
    }).unwrap()
    setAsset(res)
    notify('Your asset is now available for download', NotificationType.SUCCESS)
  }

  const handleDonationAssetUpdate = async () => {
    const updateAssetDto = { mirrorPublicLibrary: true }
    await updatePurchaseOption({
      assetId: asset?._id,
      addAssetPurchaseOptionDto
    }).unwrap()
    const res: any = await updateAsset({
      id: asset?._id,
      updateAssetDto
    }).unwrap()
    setAsset(res)
    notify(
      'Your asset is now available for download with an optional donation',
      NotificationType.SUCCESS
    )
  }

  const handleSaleAssetUpdate = async () => {
    const res: any = await updatePurchaseOption({
      assetId: asset?._id,
      addAssetPurchaseOptionDto
    }).unwrap()
    setAsset(res)
    notify('Your asset is now available for sale', NotificationType.SUCCESS)
  }

  const handleClick = async () => {
    try {
      if (actionType === DELETE) {
        if (priceOptions.free) {
          await handleFreeAssetRemoval()
        } else if (priceOptions.donation) {
          await handleDonationAssetRemoval()
        } else {
          await handleSaleAssetRemoval()
        }
      } else if (actionType === UPDATE) {
        if (priceOptions.free) {
          await handleFreeAssetUpdate()
        } else if (priceOptions.donation) {
          await handleDonationAssetUpdate()
        } else {
          await handleSaleAssetUpdate()
        }
      }
    } catch (error: any) {
      notify(error?.message || 'Something went wrong!', NotificationType.ERROR)
    } finally {
      dismissModal()
    }
  }

  const handleDismiss = () => {
    dismissModal()
  }

  const getConfirmationText = () => {
    if (actionType === DELETE) {
      if (priceOptions.paid) {
        return 'Are you sure you want to remove this asset from being for sale? Existing owners will still have access to files.'
      } else {
        return 'Are you sure you want to remove this asset from being available for download? It will no longer be downloadable by users. Existing owners will still have access to files.'
      }
    } else {
      if (priceOptions.donation) {
        return `Are you sure you want to make this asset available for free with a suggested donation of $${isAssetWithDonation?.price}?`
      } else if (priceOptions.free) {
        return 'Are you sure you want to make this asset available for free?'
      } else {
        return `Are you sure you want to set this asset for sale for $${assetPrice.price}?`
      }
    }
  }

  const getConfirmLabel = () => {
    return actionType === DELETE ? 'Confirm Removal' : 'Confirm'
  }

  return (
    <div>
      <div className="confirmation-popup flex justify-center flex-col text-center">
        <h2 className="font-primary text-xl font-semibold text-white">
          {actionType === DELETE
            ? priceOptions.paid
              ? 'Confirm Remove from Sale'
              : 'Confirm Removal'
            : 'Confirm'}
        </h2>
        <Paragraph className="my-4">{getConfirmationText()}</Paragraph>
        <div className="flex gap-3 items-center justify-center mt-3">
          <Button onClick={handleClick} className="w-full">
            {getConfirmLabel()}
          </Button>
          <Button
            onClick={handleDismiss}
            className="bg-grayMirror text-white w-full hover:bg-grayMirror"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AssetPurchaseOption
