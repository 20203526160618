export const categoryOptions = [
  { _id: 'ALL', name: 'All' },
  { _id: 'MESH', name: 'Mesh' },
  { _id: 'IMAGE', name: 'Image' },
  { _id: 'PANEL', name: 'Panel' },
  { _id: 'BOX', name: 'Box' },
  { _id: 'SPHERE', name: 'Sphere' },
  { _id: 'AUDIO', name: 'Audio' },
  { _id: 'MATERIAL', name: 'Material' },
  { _id: 'TEXTURE', name: 'Texture' },
  { _id: 'MAP', name: 'Map' },
  { _id: 'SCRIPT', name: 'Script' }
]

export const sortOptionsSpaces = [
  { _id: '1', name: 'A to Z', sortKey: 'name', sortDirection: 1 },
  { _id: '2', name: 'Z to A', sortKey: 'name', sortDirection: -1 },
  { _id: '3', name: 'Newest', sortKey: 'createdAt', sortDirection: -1 },
  {
    _id: '4',
    name: 'Recently Updated',
    sortKey: 'updatedAt',
    sortDirection: -1
  }
]

export const sortOptionsAsset = [
  { _id: '1', name: 'A to Z', sortKey: 'name', sortDirection: 1 },
  { _id: '2', name: 'Z to A', sortKey: 'name', sortDirection: -1 },
  { _id: '3', name: 'Newest', sortKey: 'createdAt', sortDirection: -1 },
  {
    _id: '4',
    name: 'Price: Low to High',
    sortKey: 'purchaseOptions.price',
    sortDirection: 1
  },
  {
    _id: '5',
    name: 'Price: High to Low',
    sortKey: 'purchaseOptions.price',
    sortDirection: -1
  }
]

export const priceOptions = [
  { _id: '0', name: 'Free' },
  { _id: '1', name: '$1 - $10' },
  { _id: '2', name: '$11 - $20' },
  { _id: '3', name: '$21 - $30' },
  { _id: '4', name: '$31+' }
]
