import { original } from '@reduxjs/toolkit'
import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiMirrorSlice.enhanceEndpoints<'Groups'>({
  endpoints: {
    userGroupControllerCreate: {
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          const { data: newGroup } = await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'userGroupControllerGetAllGroupsForMe',
              undefined,
              (currentGroups: any) => {
                currentGroups.push(newGroup)
              }
            )
          )
        } catch (error) {
          // TODO add error handling
          throw new Error()
        }
      }
    },
    userGroupControllerUpdate: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedGroup } = await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'userGroupControllerGetAllGroupsForMe',
              undefined,
              (currentGroups: any) => {
                const og = original(currentGroups)
                const index = og.findIndex((group) => group._id === id)
                currentGroups[index] = updatedGroup
              }
            )
          )
        } catch (error) {
          // TODO add error handling
          throw new Error()
        }
      }
    },
    userGroupControllerRemove: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled

          dispatch(
            apiMirrorSlice.util.updateQueryData(
              'userGroupControllerGetAllGroupsForMe',
              undefined,
              (currentGroups: any) => {
                const og = original(currentGroups)
                const index = og.findIndex((group) => group._id === id)
                currentGroups.splice(index, 1)
              }
            )
          )
        } catch (error) {
          // TODO add error handling
          throw new Error()
        }
      }
    }
  }
})

export const selectMyGroups =
  apiMirrorSlice.endpoints.userGroupControllerGetAllGroupsForMe.select()
