import { apiMirrorSlice } from '../api/apiMirrorSlice.generated'

apiMirrorSlice.enhanceEndpoints<'Cart'>({
  endpoints: {
    userControllerGetUserCart: {
      providesTags: (result, error, arg) => [{ type: 'Cart' }]
    },
    userControllerAddUserCartItemToUserCart: {
      invalidatesTags: (result, error, arg) => [{ type: 'Cart' }]
    },
    userControllerRemoveUserCartItemFromUserCart: {
      invalidatesTags: (result, error, arg) => [{ type: 'Cart' }]
    },
    userControllerRemoveAllUserItemsFromCart: {
      invalidatesTags: (result, error, arg) => [{ type: 'Cart' }]
    }
  }
})

export const selectMyCart =
  apiMirrorSlice.endpoints.userControllerGetUserCart.select()
